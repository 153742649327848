import axios from 'axios'
import { getWebpHead } from '../utils/helpers'

const prefix = '/screen'

export const getScreen = async (slug, params = { page: 1, per_page: 20 }) => {
  return await axios.get(`${prefix}/${slug}`, {
    params,
    headers: getWebpHead(),
  })
}

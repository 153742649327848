<template>
  <div class="checkout-magic">
    <div class="checkout-magic__title" v-html="title"></div>
    <div class="checkout-magic__texts">
      <div
        v-for="(item, index) in items"
        :key="index"
        v-html="item.text"
        class="checkout-magic__text"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutMagic',
  data() {
    return {
      title: 'How Does <b>The "MAGIC"</b> Actually Happen',
      items: [
        {
          text: 'This is going to be dramatically different from anything you’ve ever read because this is not going to be based on fancy theory.',
        },
        {
          text: 'You’re going to see the actual meditations, self-hypnosis, self-affirmations, cognitive behavioral therapy sessions and much more.',
        },
        {
          text: 'There’s no fluff or fillers. It goes straight into showing you exactly what to do, how to do it and why.',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.checkout-magic {
  text-align: center;
  padding-bottom: 144px;

  &__title {
    font-weight: 400;
    font-size: 48px;
    line-height: 66px;
    padding-bottom: 47px;
    color: var(--color-red);

    b {
      font-weight: 800;
    }
  }

  &__text {
    font-weight: 700;
    font-size: 36px;
    line-height: 49px;

    &:not(:last-child) {
      margin-bottom: 36px;
    }
  }
}
</style>

<template>
  <div class="checkout-mood">
    <ul class="checkout-mood__items">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="checkout-mood__item"
      >
        <div
          class="checkout-mood__item-image-wrapper"
          :style="{
            backgroundColor: item.color,
          }"
        >
          <img src="@/static/checkout/icons/star.svg" alt="star" />
        </div>
        <div class="checkout-mood__item-title">
          {{ item.title }}
        </div>
        <div class="checkout-mood__item-text">
          {{ item.text }}
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CheckoutMood',
  data() {
    return {
      items: [
        {
          title: 'Depression',
          text: 'Most of the time depression involves a lot of dark thoughts. You might feel hopeless, worthless, or angry at life (or even yourself). This can make meditation and CBT seem somewhat counterintuitive, since it involves increasing awareness around thoughts and experiences. But meditation teaches you to pay attention to thoughts and feelings without passing judgment or criticizing yourself.',
          color: '#FFA502',
        },
        {
          title: 'Stress',
          text: 'Stress can make you feel anxious, tense, worried. Meditation may clear away the information overload that builds up every day and contributes to your increased stress levels. Spending a few minutes meditating might help restore your calm and inner peace.',
          color: '#FF4757',
        },
        {
          title: 'Anxiety',
          text: 'Anxiety is a feeling of worry, nervousness, or fear about an event or situation. It could be disabling, if it interferes with your daily life. To combat anxiety you must understand and deal with the deeply-rooted mental elements and barriers responsible for its build up.',
          color: '#57606F',
        },
        {
          title: 'Panic Attacks',
          text: 'Panic attacks are sudden, unreasonable feelings of fear and anxiety that cause physical symptoms like a racing heart, fast breathing and sweating. Many people with panic disorder spend time worrying about the future or stressing about the past. Fortunately, relaxation techniques can help counteract these symptoms.',
          color: '#FF6B81',
        },
        {
          title: 'Fear',
          text: 'Everyone experiences fear, whether it’s the fear of something happening, or an event, situation, or person you fear encountering. Fear might lead to a diminution of enjoyment of life. It might also escalate into a phobia. When we meditate, we embark on a journey to a calmer, less reactive, and less fearful mind. In calming the mind, we not only quell our fears, but we increase the chances of conquering them.',
          color: '#5352ED',
        },
        {
          title: 'Sleep Disorders',
          text: 'Increased levels of anxiety and stress might lead to developing sleep problems or enhancing existing sleep disorders. Meditation might help you sleep better as it is a relaxation technique, which can quiet the mind and body while enhancing inner peace. It also improves control of the autonomic nervous system, which reduces how easy you can wake up.',
          color: '#FF6348',
        },
        {
          title: 'procratination',
          text: 'Procrastination is the act of delaying or postponing important tasks. It is one of the main reasons that make it harder for you to get out of bed or to do something that you shouldn’t delay doing. Procrastination can often be mistaken for laziness, but they are very different. It is an active process – you decide to do something else rather than the task that you know you should be doing. In contrast, laziness suggests apathy, inactivity, and unwillingness to act.',
          color: '#1E90FF',
        },
        {
          title: 'Breakup trauma',
          text: "Breakups are terrible no matter what causes them or if you desire to end a relationship. Sadness, guilt, and fear might result from starting a split when the other person doesn't want to. Feelings of hurt and rejection might arise after a breakup. Even when there is mutual consent to end things, it's normal to experience uncomfortable emotions like rage or depression for a while.",
          color: '#ECCC68',
        },
        {
          title: 'poor mindset',
          text: 'If you feel like you don’t have enough - be it money, time or personal connections - you are probably suffering from a common problem known as poor mindset or also scarcity mindset. Having this kind of mindset can put invisible barriers on your path and become a self-fulfilling prophecy which makes it hard for you to reach success milestones or financial independence.',
          color: '#2ED573',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.checkout-mood {
  padding-bottom: 144px;

  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 100px;
    max-width: 1280px;
    margin-inline: auto;
  }

  &__item {
    width: 280px;
  }

  &__item-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    margin-bottom: 36px;
    border-radius: 50%;
    background-color: var(--color-white-01);
  }

  &__item-title {
    font-family: var(--run);
    font-size: 36px;
    line-height: 42px;
    padding-bottom: 12px;
    text-transform: lowercase;

    &:after {
      content: ':';
    }
  }

  &__item-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>

<template>
  <section class="seen-on">
    <div class="container container--content">
      <h3 class="seen-on__title">AS SEEN ON</h3>
      <ul class="seen-on__list">
        <li v-for="(item, index) in items" :key="index" class="seen-on__item">
          <img :src="require(`@/static${item.image}`)" :alt="`${item.title}`" />
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: 'Time',
          image: '/logos/time.svg',
        },
        {
          title: 'Nbc',
          image: '/logos/nbc.svg',
        },
        {
          title: 'Cinet',
          image: '/logos/cinet.svg',
        },
        {
          title: 'Tech Crunch',
          image: '/logos/techcrunch.svg',
        },
        {
          title: 'Forbes',
          image: '/logos/forbes.svg',
        },

        {
          title: 'Yahoo',
          image: '/logos/yahoo.svg',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.seen-on {
  padding-top: 92px;
  padding-bottom: 144px;

  @media (max-width: 767px) {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    color: var(--color-white);
    text-align: center;
    margin-top: 0;
    margin-bottom: 48px;

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 991px) {
      flex-wrap: wrap;
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    margin: 0 15px;

    @media (max-width: 991px) {
      width: calc(33.33% - 30px);
      margin-bottom: 40px;
    }

    @media (max-width: 576px) {
      width: calc(50% - 30px);
    }
  }
}
</style>
>

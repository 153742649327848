<template>
  <div class="page-wrapper">
    <Header :user="user" />
    <div class="video-player">
      <div class="video-player__container container container--video-player">
        <div class="video-player__wrapper">
          <div class="video-player__left-col" ref="height">
            <div
              v-show="!isLoading"
              class="video-player__wrapper-video"
              :class="{
                'is-audio': (checkAudioSet && !checkVideoSet) || isAudio,
              }"
            >
              <video
                ref="videoPlayer"
                class="video-js"
                controls
                playsinline
              ></video>
            </div>
            <div v-show="isLoading" class="video-player__loader"></div>
            <div class="video-wrapper__controls">
              <div class="video-wrapper__controls-left">
                <div class="video-wrapper__controls-title">
                  {{ currentData.name }}
                </div>
              </div>
              <div class="video-wrapper__controls-center">
                <a-tooltip
                  v-if="!dataSetting.male.isAudio && !dataSetting.male.isVideo"
                >
                  <template #title>No url exists to switch</template>
                  <a-button class="btn-control male" disabled>
                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11.607 8.024l6.912-6.91M7.406 18.149a5.926 5.926 0 100-11.852 5.926 5.926 0 000 11.852zM18.517 6.298V1.113h-5.185" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/></svg>
                  </a-button>
                </a-tooltip>
                <button
                  v-else
                  class="btn-control male"
                  :class="{ active: male }"
                  @click="onMaleChange(true)"
                ></button>
                <a-tooltip
                  v-if="
                    !dataSetting.female.isAudio && !dataSetting.female.isVideo
                  "
                >
                  <template #title>No url exists to switch</template>
                  <a-button class="btn-control female" disabled>
                    <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6 11.484a5.185 5.185 0 100-10.37 5.185 5.185 0 000 10.37zM6 11.484v6.667M3.778 15.926h4.445" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/></svg>
                  </a-button>
                </a-tooltip>
                <button
                  v-else
                  class="btn-control female"
                  :class="{ active: !male }"
                  @click="onMaleChange(false)"
                ></button>
              </div>
              <div class="video-wrapper__controls-right">
                <div class="flex">
                  <a-tooltip v-if="!checkAudioSet">
                    <template #title>Audio url does not exist</template>
                    <a-button
                      class="btn-control music-playlist"
                      @click="onAudioChange(true)"
                      :disabled="!checkAudioSet"
                      :class="{ active: isAudio }"
                    >
                      <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_4754_2647)" stroke="#fff" stroke-linejoin="round"><path d="M4.979 15.317c0 1.15-1.027 2.298-2.298 2.298a2.295 2.295 0 01-2.298-2.298A2.295 2.295 0 012.68 13.02h2.298v2.297zM13.404 12.255c0 1.149-1.026 2.298-2.297 2.298a2.295 2.295 0 01-2.298-2.298 2.295 2.295 0 012.298-2.298h2.297v2.298z"/><path d="M4.979 13.021V3.83L13.404.383v9.574M4.979 6.51l8.425-3.447"/></g><defs><clipPath id="clip0_4754_2647"><path fill="#fff" d="M0 0h13.787v18H0z"/></clipPath></defs></svg>
                    </a-button>
                  </a-tooltip>
                  <a-button
                    v-else
                    class="btn-control music-playlist"
                    @click="onAudioChange(true)"
                    :class="{ active: isAudio }"
                  >
                    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_4754_2647)" stroke="#fff" stroke-linejoin="round"><path d="M4.979 15.317c0 1.15-1.027 2.298-2.298 2.298a2.295 2.295 0 01-2.298-2.298A2.295 2.295 0 012.68 13.02h2.298v2.297zM13.404 12.255c0 1.149-1.026 2.298-2.297 2.298a2.295 2.295 0 01-2.298-2.298 2.295 2.295 0 012.298-2.298h2.297v2.298z"/><path d="M4.979 13.021V3.83L13.404.383v9.574M4.979 6.51l8.425-3.447"/></g><defs><clipPath id="clip0_4754_2647"><path fill="#fff" d="M0 0h13.787v18H0z"/></clipPath></defs></svg>
                  </a-button>
                  <a-tooltip v-if="!checkVideoSet">
                    <template #title>Video url does not exist</template>
                    <a-button
                      class="btn-control video-playlist"
                      :disabled="!checkVideoSet"
                      :class="{ active: !isAudio }"
                    >
                      <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_4754_2657)" stroke="#fff" stroke-linejoin="round"><path d="M10.875.375h-9.75a.75.75 0 00-.75.75v8.25c0 .414.336.75.75.75h9.75a.75.75 0 00.75-.75v-8.25a.75.75 0 00-.75-.75zM17.625 9.375l-4.5-2.25v-3.75l4.5-2.25v8.25z"/></g><defs><clipPath id="clip0_4754_2657"><path fill="#fff" d="M0 0h18v10.5H0z"/></clipPath></defs></svg>
                    </a-button>
                  </a-tooltip>
                  <a-button
                    v-else
                    class="btn-control video-playlist"
                    @click="onAudioChange(false)"
                    :class="{ active: !isAudio }"
                  >
                    <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_4754_2657)" stroke="#fff" stroke-linejoin="round"><path d="M10.875.375h-9.75a.75.75 0 00-.75.75v8.25c0 .414.336.75.75.75h9.75a.75.75 0 00.75-.75v-8.25a.75.75 0 00-.75-.75zM17.625 9.375l-4.5-2.25v-3.75l4.5-2.25v8.25z"/></g><defs><clipPath id="clip0_4754_2657"><path fill="#fff" d="M0 0h18v10.5H0z"/></clipPath></defs></svg>
                  </a-button>
                </div>
                <div class="flex">
                  <button
                    class="btn-control favorite"
                    :class="{ active: currentData.favorite }"
                    :disabled="isFavoriteLoading"
                    @click="onSetFavorite(currentData)"
                  ></button>
                  <button
                    class="btn-control share"
                    @click="copyContent"
                  ></button>
                </div>
              </div>
            </div>
          </div>
          <div class="video-player__right-col">
            <div class="rec" v-if="isPlaylist">
              <div class="rec-head">
                <h3 class="rec-title">{{ data.name }}</h3>
                <button
                  class="rec-btn"
                  :class="{ active: data.favorite }"
                  :disabled="isFavoriteLoading"
                  @click="
                    onSetFavorite({ ...data, id: $route.params.id }, true)
                  "
                >
                  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_4751_2535)"><path d="M11 22.39s10.5-8.12 10.5-15.82c0-7.7-9.32-8.28-10.5-.59C9.82-1.71.5-1.12.5 7.17S11 22.39 11 22.39z" stroke="#fff" stroke-linejoin="round"/></g><defs><clipPath id="clip0_4751_2535"><path fill="#fff" d="M0 0h22v22.89H0z"/></clipPath></defs></svg>
                </button>
              </div>
              <p class="rec-description">
                {{ data.description }}
              </p>
              <button class="rec-play-btn btn btn--white" @click="playPlayer">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 23.5c6.351 0 11.5-5.149 11.5-11.5S18.351.5 12 .5.5 5.649.5 12 5.649 23.5 12 23.5z" stroke="#134E5E" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.5 16.5v-9l9 4.5-9 4.5z" stroke="#134E5E" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <span>Play</span>
              </button>
            </div>
            <swiper
              :spaceBetween="8"
              :slidesPerView="'auto'"
              :loop="false"
              :lazy="true"
              navigation
              class="mySwiper tags"
              v-if="tags && tags.length && !isPlaylist"
            >
              <swiper-slide>
                <button
                  @click="onTagChange('All')"
                  class="tag"
                  :class="{ active: tagSelected === 'All' }"
                  :key="tag"
                >
                  All
                </button>
              </swiper-slide>
              <swiper-slide v-for="tag in tags" :key="tag">
                <button
                  @click="onTagChange(tag)"
                  class="tag"
                  :class="{ active: tagSelected === tag }"
                >
                  {{ tag }}
                </button>
              </swiper-slide>
            </swiper>
            <div class="video-player__playlist playlist" ref="playlist">
              <ol class="vjs-playlist-item-list">
                <li
                  v-for="(m, i) in meditations"
                  :key="i"
                  class="vjs-playlist-item vjs-up-next"
                  :class="{
                    'vjs-selected': currentData.id === m.id,
                    fav: m.favorite,
                    lock: !m.free,
                  }"
                  tabindex="0"
                >
                  <div
                    @click="
                      () => (m.free ? onChangePlaylistItem(m) : onUnlock())
                    "
                    class="vjs-playlist-thumbnail vjs-playlist-thumbnail-placeholder"
                  >
                    <span class="vjs-playlist-item--counter"
                      >{{ i + 1 }}.
                    </span>
                    <div class="vjs-playlist-title-container">
                      <cite class="vjs-playlist-name" :title="m.name">{{
                        m.name
                      }}</cite>
                    </div>
                  </div>
                  <time class="vjs-playlist-duration" datetime="PT0H0M5S"
                    >{{ m.duration }} min</time
                  ><button
                    v-if="m.free"
                    @click="onLikePlaylistItem(m)"
                    class="btn-like"
                  ></button>
                  <button v-else class="btn-lock" @click="onUnlock"></button>
                </li>
              </ol>
            </div>
            <div
              class="video-player__recently"
              v-if="!isPlaylist"
              :style="{ 'max-height': heightPlayer + 'px' }"
            >
              <template v-if="items.length">
                <SectionCard
                  v-for="item in items"
                  :item="item"
                  :key="item.id"
                  :url="`/${section}/${item.id}`"
                />
              </template>
              <p class="video-player__no-items" v-else>No items</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <subscription-modal @done="onDone" />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer.vue'
import SubscriptionModal from '@/components/subscribe/SubscriptionModal.vue'
import videojs from 'video.js'
import 'videojs-playlist'
import 'videojs-playlist-ui'
import { mapActions, mapMutations, mapState } from 'vuex'
import {
  userSetFavorite,
  userCloudfrontCookies,
  userDeleteFavorite,
  userCreateTrackingSession,
} from '@/services/user.service'
import { models } from '../data/constants'
import SectionCard from '../components/cards/SectionCard.vue'
import Error from '../components/Error.vue'
import { setCookie } from '../utils/helpers'
import { notification } from 'ant-design-vue'
import { checkUrl, getUrl } from '@/utils/checkVideoAudioUrl.js'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation } from 'swiper'
import { loadFacebookPixel, initFacebook } from '@/utils/facebookConfig'
import { metaEventCustomActions } from '@/data/constants'
import { onBeforeRouteLeave } from 'vue-router'

SwiperCore.use([Navigation])

export default {
  props: ['user'],
  name: 'VideoPlayer',
  components: {
    Header,
    Footer,
    SectionCard,
    Error,
    Swiper,
    SwiperSlide,
    SubscriptionModal,
  },

  data() {
    return {
      data: {},
      player: null,
      screenAndCollection: {},
      title: '',
      tags: [],
      male: true,
      isAudio: false,
      section: '',
      id: '',
      cookies: {},
      isLoading: false,
      isFavoriteLoading: false,
      isNoLink: false,
      currentData: {},
      meditations: [],
      tagSelected: 'All',
      dataSetting: {
        male: {},
        female: {},
      },
      heightPlayer: '',
      sound: null,
      startTime: null,
      endTime: null,
    }
  },
  computed: {
    ...mapState('recommendation', {
      items(state) {
        return state.items.filter((i) => i.id !== +this.$route.params.id)
      },
    }),
    isSoundscape() {
      return this.section === models.MUSIC_SOUNDSCAPE
    },
    isMeditationSleep() {
      return this.section === models.MEDITATION_SLEEP
    },
    isPlaylist() {
      return (
        this.section === models.MEDITATION_SLEEP_COURSE ||
        this.section === models.MUSIC_SOUNDSCAPE_PLAYLIST ||
        this.section === models.CBT_COURSE
      )
    },
    checkAudioSet() {
      return this.male
        ? this.dataSetting.male.isAudio
        : this.dataSetting.female.isAudio
    },
    checkVideoSet() {
      return this.male
        ? this.dataSetting.male.isVideo
        : this.dataSetting.female.isVideo
    },
  },
  // setup() {
  //   const
  //   onBeforeRouteLeave((to, from, next) => {
  //     console.log('12313')
  //     // this.endTime = new Date().toISOString().slice(0, 19) + 'Z'
  //     // this.onEndedVideo(this.startTime, this.endTime)
  //     next()
  //   })

  //   return { onEndedVideo }
  // },
  async mounted() {
    this.loadFacebookPixel()
    fbq('track', 'ViewContent')
    try {
      this.isLoading = true
      this.data = await this.fetchData()
      if (this.data.soundscape) {
        this.sound = new Audio(this.data.soundscape.main_format)
        this.sound.loop = true
      }
      videojs.Vhs.xhr.beforeRequest = (options) => {
        options.withCredentials = true
        return options
      }
      var overrideNative = false
      const options = {
        autoplay: true,
        fluid: true,
        errorDisplay: false,
        controls: true,
        preload: 'auto',
        poster: this.data.cover,
        audioPosterMode: this.data.cover,
        preferFullWindow: true,
        autoSetup: true,
        responsive: true,
        suppressNotSupportedError: true,
        html5: {
          vhs: {
            overrideNative: overrideNative,
          },
          nativeVideoTracks: !overrideNative,
          nativeAudioTracks: !overrideNative,
          nativeTextTracks: !overrideNative,
        },
      }
      const that = this
      this.player = videojs(
        this.$refs.videoPlayer,
        options,
        async function onPlayerReady() {
          videojs.log('Your player is ready!')
          window.player = this
          const currentUrl = getUrl(that.data)
          that.dataSetting = that.checkUrl(that.data, that.male, that.isAudio)
          await that.initPlayer(currentUrl)
          if (
            that.section === models.MEDITATION_SLEEP ||
            that.section === models.CBT ||
            that.section === models.MUSIC_SOUNDSCAPE
          ) {
            that.fetchRecommendation({
              section: that.section,
              params: { tags: that.tags.join(','), is_tags_inclusive: true },
            })
          }
          this.on('playlistchange', () => {
            console.log('playlist changed')
            this.playlist.currentItem(0)
            this.playlistMenu.items.forEach((i) => {
              if (i.item.favorite) {
                const el = i.el()
                el.classList.add('fav')
              }
            })
          })
          this.on('error', function () {
            console.log(player.error())
          })
          this.on('playing', () => {
            if (!this.startTime) {
              that.startTime = new Date().toISOString().slice(0, 19) + 'Z'
            }
            console.log('playing')
            that.playSound()
          })
          this.on('seeking', () => {
            that.pauseSound()
          })
          this.on('waiting', () => {
            that.pauseSound()
          })
          this.on('volumechange', () => {
            that.setVolume(this.volume())
          })
          this.on('pause', () => {
            console.log('pause')
            that.setTime(this.currentTime())
            that.pauseSound()
          })
          this.on('playlistitem', () => {
            that.currentData =
              that.meditations[window.player.playlist.currentItem()]
            if (that.currentData) that.setPlaylistItem(that.currentData)
          })
          this.on('ended', function () {
            that.endTime = new Date().toISOString().slice(0, 19) + 'Z'
            that.onEndedVideo(that.startTime, that.endTime)

            videojs.log('Awww...over so soon?!')
          })
        }
      )

      this.createCustomButton()
    } catch (err) {
      console.log(err)
    } finally {
      this.isLoading = false
    }
    await initFacebook()
    window.fbAsyncInit()
    FB.AppEvents.logEvent('ViewContent')
  },
  beforeUnmount() {
    this.endTime = new Date().toISOString().slice(0, 19) + 'Z'
    this.onEndedVideo(this.startTime, this.endTime)

    if (this.player) {
      this.player.dispose()
    }
    if (this.sound) {
      this.sound.currentTime = 0
      this.sound.pause()
    }
  },
  methods: {
    loadFacebookPixel,
    ...mapActions('player', [
      'fetchMeditationSleep',
      'fetchMeditationSleepCourse',
      'fetchMusicSoundscape',
      'fetchMusicSoundscapePlaylist',
      'fetchCbt',
      'fetchCbtCourse',
    ]),
    ...mapActions('recommendation', ['fetchRecommendation']),
    ...mapMutations('subscription', ['SET_OPEN']),
    setCookie,
    checkUrl,
    onUnlock() {
      this.SET_OPEN({
        isOpen: true,
        action: metaEventCustomActions.ON_LOCKED_CLICKED,
      })
    },
    async onEndedVideo(startTime, endTime) {
      await userCreateTrackingSession({
        start_session: startTime,
        end_session: endTime,
      })
    },
    onDone() {
      this.SET_OPEN({ isOpen: false })
      window.location.reload()
    },
    onChangePlaylistItem(m) {
      this.currentData = m
      this.setSource(
        [
          {
            src: m.sources[0] && m.sources[0].src,
            type: 'application/x-mpegURL',
            withCredentials: true,
          },
        ],
        m.poster
      )
      if (this.currentData.soundscape) {
        this.pauseSound()
        this.sound = new Audio(this.currentData.soundscape.main_format)
        this.sound.loop = true
      }
    },
    onLikePlaylistItem(m) {
      this.onSetFavorite(m, false)
    },
    playSound() {
      if (this.sound) this.sound.play()
    },
    pauseSound() {
      if (this.sound) this.sound.pause()
    },
    resetSound() {
      if (this.sound) this.sound.currentTime = 0
    },
    setVolume(v) {
      if (this.sound) this.sound.volume = +v.toFixed(2)
    },
    setTime(time) {
      if (this.sound) this.sound.currentTime = time
    },
    async fetchData() {
      this.cookies = await userCloudfrontCookies()
      this.setCookie('CloudFront-Policy', this.cookies['CloudFront-Policy'])
      this.setCookie(
        'CloudFront-Signature',
        this.cookies['CloudFront-Signature']
      )
      this.setCookie(
        'CloudFront-Key-Pair-Id',
        this.cookies['CloudFront-Key-Pair-Id']
      )

      this.section = this.$route.params.section
      this.id = this.$route.params.id
      switch (this.section) {
        case models.MEDITATION_SLEEP:
          return this.fetchMeditationSleep(this.id)
        case models.MEDITATION_SLEEP_COURSE:
          return this.fetchMeditationSleepCourse(this.id)
        case models.MUSIC_SOUNDSCAPE:
          return this.fetchMusicSoundscape(this.id)
        case models.MUSIC_SOUNDSCAPE_PLAYLIST:
          return this.fetchMusicSoundscapePlaylist(this.id)
        case models.CBT:
          return this.fetchCbt(this.id)
        case models.CBT_COURSE:
          return this.fetchCbtCourse(this.id)
        default:
          this.$router.push('/home')
      }
    },
    async initPlayer(currentUrl) {
      switch (this.data.model) {
        case models.MEDITATION_SLEEP_COURSE:
        case models.CBT_COURSE:
        case models.MUSIC_SOUNDSCAPE_PLAYLIST:
          this.meditations = this.initPlaylist(this.data)
          if (!this.meditations || !this.meditations.length) return
          const freeContents = this.meditations.filter((i) => i.free)
          const first = freeContents[0]
          this.currentData = first
          this.setSource(
            [
              {
                src: first.sources[0] && first.sources[0].src,
                type: 'application/x-mpegURL',
                withCredentials: true,
              },
            ],
            first.poster
          )

          if (first.soundscape) {
            this.sound = new Audio(first.soundscape.main_format)
            this.sound.loop = true
            this.pauseSound()
          }
          break
        case models.MEDITATION_SLEEP:
        case models.CBT:
          if (currentUrl.url) {
            this.isAudio = currentUrl.isAudio
            this.male = currentUrl.isMale
            this.setSource({
              src: currentUrl.url,
              type: 'application/x-mpegURL',
              withCredentials: true,
            })
          } else {
            notification.error({
              message: 'Url does not exist',
              placement: 'bottomRight',
            })
          }
          this.currentData = { ...this.data, id: this.$route.params.id }
          this.tags = this.data.tags || []
          this.tags = [...this.tags]
          break
        case models.MUSIC_SOUNDSCAPE:
          this.isAudio = !!this.data.audio
          this.tags = this.data.tags || []
          this.tags = [...this.tags]
          this.currentData = this.data
          if (!this.data.audio && !this.data.video) {
            notification.error({
              message: 'Url does not exist',
              placement: 'bottomRight',
            })
            return
          }
          const url = this.data.audio
            ? this.data.audio.hls.playlist
            : this.data.video.hls.playlist
          this.setSource([
            {
              src: url,
              type: 'application/x-mpegURL',
              withCredentials: true,
            },
          ])
          break
      }
    },
    setPlaylistItem(item) {
      const itemData = getUrl(item)
      this.setSource(
        [
          {
            src: itemData.url,
            type: 'application/x-mpegURL',
            withCredentials: true,
          },
        ],
        item.background.web
      )
    },
    skipS3MV(skipBy) {
      this.player.currentTime(this.player.currentTime() + skipBy)
    },
    onAudioChange(value) {
      if (value === this.isAudio) return
      this.isAudio = value
      this.dataSetting = this.checkUrl(this.data, this.male, this.isAudio)
      this.initPlayer({
        url: this.dataSetting.url,
        isAudio: this.isAudio,
        isMale: this.male,
      })
    },
    onMaleChange(value) {
      if (value === this.male) return
      this.male = value
      this.dataSetting = this.checkUrl(this.data, this.male, this.isAudio)
      this.initPlayer({
        url: this.dataSetting.url,
        isAudio: this.isAudio,
        isMale: this.male,
      })
    },
    onTagChange(tag) {
      this.tagSelected = tag
      if (tag === 'All') {
        this.fetchRecommendation({
          section: this.section,
          params: { tags: this.tags.join(','), is_tags_inclusive: true },
        })
      } else
        this.fetchRecommendation({
          params: { tags: tag },
          section: this.section,
        })
    },
    setSource(src, poster) {
      player.src(src)
      player.poster(poster ? poster : this.data.background.web)
      this.resetSound()
    },
    initPlaylist(option) {
      if (
        option.model === 'music-soundscape' ||
        option.model === 'music-soundscape-playlist'
      ) {
        return this.initMusicPlaylist(option)
      }
      if (option.tags != null) {
        this.tags = option.tags
        this.tags = [...this.tags]
      }

      if (option.meditation) {
        return option.meditation.map((item) => {
          const encodedImage = encodeURI(item.background.web)

          const itemData = getUrl(item)

          const obj = {
            poster: encodedImage,
            name: item.name,
            sources: [
              {
                src: itemData.url,
                type: 'application/x-mpegURL',
                withCredentials: true,
              },
            ],
            overrideNative: true,
            id: item.id,
            favorite: item.favorite,
            duration: item.duration,
            model: item.model,
            free: item.free,
            ...item,
          }

          return obj
        })
      } else {
        const speaker = this.male ? option.video_male : option.video_female
        return [
          {
            poster: option.background.web,
            name: option.name,
            src: speaker.hls.playlist,
            overrideNative: true,
            model: item.model,
            free: item.free,
            ...item,
          },
        ]
      }
    },
    initMusicPlaylist(option) {
      return option.audios.map((item) => {
        const encodedImage = encodeURI(item.background.web)

        const obj = {
          poster: encodedImage,
          name: item.name,
          sources: [
            {
              src: item.audio.hls.playlist,
              withCredentials: true,
              type: 'application/x-mpegURL',
            },
          ],
          overrideNative: true,
          id: item.id,
          favorite: item.favorite,
          duration: item.duration,
          model: item.model,
          free: item.free,
          ...item,
        }

        return obj
      })
    },
    async onSetFavorite(data, isSection = false) {
      try {
        this.isFavoriteLoading = true
        const body = { id: data.id, model: data.model }
        if (data.favorite) {
          await userDeleteFavorite(body)
        } else await userSetFavorite(body)
        if (isSection) {
          this.data.favorite = !this.data.favorite
        } else {
          if (this.currentData.id === data.id)
            this.currentData.favorite = !this.currentData.favorite
          else
            this.meditations = this.meditations.map((i) =>
              i.id === data.id ? { ...i, favorite: !data.favorite } : i
            )
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.isFavoriteLoading = false
      }
    },

    createCustomButton() {
      const skipBehindButton = this.player.controlBar.addChild('button')
      const skipBehindButtonDom = skipBehindButton.el()
      skipBehindButtonDom.innerHTML = ''
      skipBehindButton.addClass('skip-behind-button')

      skipBehindButtonDom.addEventListener(
        'click',
        () => {
          this.skipS3MV(-10)
        },
        false
      )

      const skipAheadButton = this.player.controlBar.addChild('button')
      const skipAheadButtonDom = skipAheadButton.el()
      skipAheadButtonDom.innerHTML = ''
      skipAheadButton.addClass('skip-ahead-button')

      skipAheadButtonDom.addEventListener(
        'click',
        () => {
          this.skipS3MV(10)
        },
        false
      )

      const stopButton = this.player.controlBar.addChild('button')
      const stopButtonDom = stopButton.el()
      stopButtonDom.innerHTML = ''
      stopButton.addClass('stop')

      stopButtonDom.addEventListener(
        'click',
        () => {
          this.stopPlayer()
        },
        false
      )
    },
    skipS3MV(skipBy) {
      this.player.currentTime(this.player.currentTime() + skipBy)
      this.setTime(this.sound.currentTime + skipBy)
    },
    stopPlayer() {
      this.player.pause()
      this.player.currentTime(0)
    },
    playPlayer() {
      console.log('play: ')
      player.play()
    },
    resizeHandler(e) {
      const heightItem = 143

      if (this.items.length > 5) {
        this.heightPlayer = heightItem * 5
      }

      if (this.$refs.height.clientHeight < this.heightPlayer) {
        this.heightPlayer = this.heightPlayer - heightItem
      }
    },
    async copyContent() {
      try {
        await navigator.clipboard.writeText(window.location.href)
        notification.success({
          message: 'Url successfully copied',
          placement: 'topRight',
        })
      } catch (err) {
        console.error('Failed to copy: ', err)
        notification.error({
          message: 'Failed to copy: ' + err,
          placement: 'topRight',
        })
      }
    },
  },
  created() {
    window.addEventListener('load', this.resizeHandler)
    window.addEventListener('resize', this.resizeHandler)
  },
}
</script>

<style lang="scss">
@import '~video.js/dist/video-js.css';

.rec {
  border-bottom: 1px solid rgba(#ffffff, 0.1);
  margin-bottom: 1rem;
}

.rec-title {
  color: var(--color-white);
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  margin-top: 0;
}

.rec-head {
  display: flex;
  justify-content: space-between;
}

.rec-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-white);
  font-weight: 400;
  margin-bottom: 20px;
}

.rec-btn {
  background-color: inherit;
  border: none;

  &.active {
    svg {
      fill: var(--color-white);
    }
  }
}

.rec-play-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding: 15px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 16px;

  & > span {
    margin-left: 9px;
  }

  /* &:hover > svg > path {
    stroke: var(--color-white);
  } */
}

.is-audio .vjs-poster {
  display: inline-block !important;
}

.video-player {
  padding-top: 36px;

  @media (max-width: 768px) {
    padding-top: 16px;
  }

  &__loader {
    height: 30vh;
    width: 100%;
    background-color: #000;

    @media (max-width: 991px) {
      min-height: 200px;
    }
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;

    @media (max-width: 991px) {
      flex-wrap: wrap;
    }
  }

  &__wrapper-video {
    @media (max-width: 991px) {
      margin-right: -24px;
      margin-left: -24px;
    }
  }

  &__left-col {
    flex: 1 1 100%;
    max-width: 100%;
  }

  &__right-col {
    flex: 1 0 410px;
    max-width: 410px;
    padding-left: 40px;

    @media (max-width: 991px) {
      padding-left: 0;
      padding: 40px 0px 0;
      max-width: 100%;
    }
  }

  &__playlist {
    > * + * {
      margin-top: 15px;
    }

    .vjs-playlist-thumbnail {
      position: relative;
      cursor: pointer;
      padding-left: 32px;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        width: 24px;
        height: 24px;
        background-image: url('~@/static/videoplayer/play-off.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    .vjs-playlist-item {
      position: relative;
      display: flex;
      align-items: center;
      position: relative;

      &.vjs-selected {
        .vjs-playlist-thumbnail:after {
          background-image: url('~@/static/videoplayer/play-on.svg');
        }
      }

      &.fav {
        .btn-like {
          background-image: url('~@/assets/icons/heart-white.svg');
        }
      }

      &.lock {
        .vjs-playlist-duration,
        .vjs-playlist-name,
        .vjs-playlist-thumbnail,
        .vjs-playlist-item--counter {
          font-weight: 500;
          color: rgba(#fff, 0.8);
        }
      }

      .btn-like {
        width: 22px;
        height: 22px;
        margin-left: 16px;
        background-image: url('~@/static/videoplayer/like.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: inherit;
        border: none;
      }

      .btn-lock {
        width: 22px;
        height: 22px;
        margin-left: 16px;
        background-image: url('~@/assets/icons/lock-17-22.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: inherit;
        border: none;
      }
    }

    .vjs-playlist-duration {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      color: var(--color-white);
      margin-left: auto;
    }
  }

  &__recently {
    display: grid;
    row-gap: 10px;
    overflow: auto;
    max-height: calc(143px * 4);
  }

  &__no-items {
    color: rgba(#fff, 0.6);
  }

  .vjs-error .vjs-control-bar {
    display: flex !important;
  }

  .video-js {
    .vjs-current-time {
      display: block;
    }

    .vjs-control {
      height: 40px;
    }

    .vjs-icon-placeholder {
      position: relative;
      height: 100%;
      width: 100%;
    }

    .vjs-time-control {
      flex: 0 0 80px;
    }

    .vjs-volume-panel {
      width: 23px !important;
      height: 20px;

      .vjs-volume-control {
        display: flex;
        align-items: center;
        height: 100% !important;
      }

      .vjs-volume-bar {
        margin: 0 0 0 5px;
      }

      .vjs-mute-control {
        height: 100%;
        width: 100%;

        .vjs-icon-placeholder {
          &:before {
            content: '' !important;
            display: block;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 23px;
            height: 20px;
          }
        }

        &.vjs-vol-0 {
          .vjs-icon-placeholder {
            &:before {
              background-image: url('~@/static/videoplayer/no-volume.svg');
            }
          }
        }

        &.vjs-vol-3,
        &.vjs-vol-1,
        &.vjs-vol-2 {
          .vjs-icon-placeholder {
            &:before {
              background-image: url('~@/static/videoplayer/high-volume.svg');
            }
          }
        }
      }
    }

    .skip-behind-button,
    .skip-ahead-button {
      width: 25px;
      height: 22px;
    }

    .stop {
      width: 16px;
      height: 16px !important;
      cursor: pointer;
    }

    .vjs-play-control {
      width: 36px;
      height: 32px;

      .vjs-icon-placeholder {
        position: relative;
        &:before {
          content: '' !important;
          display: block;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 100%;
          height: 100%;
        }
      }

      &.vjs-playing {
        .vjs-icon-placeholder {
          &:before {
            background-image: url('~@/static/videoplayer/pause.svg');
          }
        }
      }

      &.vjs-paused {
        .vjs-icon-placeholder {
          &:before {
            background-image: url('~@/static/videoplayer/play-big.svg');
          }
        }
      }
    }

    .vjs-progress-control {
      .vjs-progress-holder {
        margin: 0 24px;
      }
    }

    .vjs-big-play-button {
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 70px;

      .vjs-icon-placeholder {
        display: flex;
        align-items: center;
      }
    }
  }

  .vjs-picture-in-picture-control {
    display: none;
  }

  .vjs-control-bar {
    height: auto;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: rgba(#000, 0.6);

    @media (max-width: 991px) {
      top: 0;
    }
  }

  .vjs-progress-control {
    order: -7;
    flex: 1 1 100%;
    max-width: 100%;
  }

  .vjs-current-time {
    order: -6;
  }

  .vjs-volume-panel {
    order: -5;
  }

  .skip-behind-button {
    order: -4;

    @media (max-width: 991px) {
      order: -9;
    }
  }

  .vjs-play-control {
    order: -3;

    @media (max-width: 991px) {
      order: -8;
    }
  }

  .skip-ahead-button {
    order: -2;

    @media (max-width: 991px) {
      order: -8;
    }
  }

  .vjs-remaining-time {
    order: 1;
  }

  .vjs-remaining-time-display,
  .vjs-current-time-display {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  .vjs-playlist-item-list {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: item;

    .vjs-playlist-item {
      &--counter {
        margin-right: 2px;
        font-weight: 700;
        font-size: 14px;
        color: var(--color-white);
      }
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  .vjs-playlist-thumbnail {
    display: flex;
    align-items: flex-start;
    align-items: center;

    img {
      flex: 0 0 201px;
      max-width: 201px;
      height: 133px;
      object-fit: cover;
      border-radius: 12px;
    }

    .vjs-playlist-now-playing-text,
    .vjs-up-next-text {
      display: none;
    }
  }

  .vjs-playlist-title-container {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .vjs-playlist-name {
    display: block;
    font-weight: 700;
    font-size: 14px;
    font-style: normal;
    line-height: 19px;
    color: var(--color-white);
  }

  .skip-behind-button,
  .skip-ahead-button {
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .skip-behind-button {
    &:before {
      background-image: url('~@/static/videoplayer/sec-behind.svg');
    }
  }

  .skip-ahead-button {
    &:before {
      background-image: url('~@/static/videoplayer/sec-ahead.svg');
    }
  }

  .vjs-fullscreen-control {
    flex: 1 1 100%;
    order: -8;

    @media (max-width: 991px) {
      order: -10;
      margin-top: 10px;
    }

    .vjs-icon-placeholder {
      position: relative;
      height: 40px;
      width: 40px;
      margin-left: auto;
      margin-right: 24px;
      background-color: rgba(#fff, 0.2);
      border-radius: 50%;

      &:before {
        content: '' !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        background-image: url('~@/static/videoplayer/full-screen.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 17px;
        height: 17px;
      }
    }
  }

  &__container {
    @media (max-width: 991px) {
      padding: 0 24px !important;
    }
  }
}

.video-wrapper {
  &__controls {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    padding-top: 24px;
    row-gap: 1rem;

    @media (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
      padding: 24px 0 0;
    }

    @media (max-width: 768px) {
      padding: 16px 0 0;
    }

    .flex {
      display: flex;

      & > * + * {
        margin-left: 20px;
      }
    }
  }

  &__controls-center,
  &__controls-right {
    display: flex;
    align-items: center;

    & > div + div {
      margin-left: 20px;
    }
  }

  &__controls-center {
    justify-content: center;

    @media (max-width: 991px) {
      justify-content: flex-end;
    }
  }

  &__controls-right {
    justify-content: flex-end;

    @media (max-width: 991px) {
      justify-content: space-between;
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }

  &__controls-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-white);
  }

  &__controls-desc {
    font-weight: 400;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    color: var(--color-white);
  }
}

.btn-control,
.btn-control.male,
.btn-control.female,
.ant-btn.btn-control {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  &.active {
    background-color: rgba(#fff, 0.4);
  }
}

.ant-btn.btn-control:disabled {
  background: rgba(255, 255, 255, 0.1);
  opacity: 0.5;
}

.favorite {
  background-color: rgba(#fff, 0.1);
  border-radius: 50%;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    background-image: url('~@/static/videoplayer/like.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 18px;
    height: 18px;
  }
}

.stop {
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    background-image: url('~@/static/videoplayer/stop.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 16px;
    height: 16px;
  }
}

.female {
  background-color: rgba(#fff, 0.1);
  border-radius: 50%;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    background-image: url('~@/static/videoplayer/female.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: unset;
    width: 11px;
    height: 18px;
  }
}

.male,
.btn-control.male {
  background-color: rgba(#fff, 0.1);
  border-radius: 50%;
  margin-right: 16px;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    background-image: url('~@/static/videoplayer/male.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: unset;
    width: 18px;
    height: 18px;
  }
}

.music-playlist {
  background-color: rgba(#fff, 0.1);
  border-radius: 50%;
}

.video-playlist {
  background-color: rgba(#fff, 0.1);
  border-radius: 50%;
}

.share {
  background-color: rgba(#fff, 0.1);
  border-radius: 50%;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    background-image: url('~@/static/videoplayer/share.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 18px;
    height: 18px;
  }
}

.tags {
  // display: flex;
  // align-items: center;
  // justify-content: flex-start;
  // overflow: auto;
  margin-bottom: 24px;

  .tag {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    font-weight: 500;
    text-align: center;
    padding: 9px 20px;
    border-radius: 18px;
    background-color: rgba(#fff, 0.1);
    color: var(--color-white);
    white-space: nowrap;
    border: none;

    // &:not(:last-child) {
    //   margin-right: 8px;
    // }

    &.active {
      background-color: var(--color-green);
    }
  }

  .swiper-slide {
    &:last-child {
      margin-right: 20px !important;
    }
  }

  .swiper-button-prev {
    transform: rotate(180deg);
  }
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-container {
  position: relative;
  overflow: hidden;
  // margin-right: -16px;
}

.swiper-wrapper {
  display: flex;
}
.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  background-size: 9px 16px;
  background-position: center;
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  top: 0;
  margin-top: 0;
  color: var(--color-white);
  transition: 0.4s ease;
  z-index: 1;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  &.swiper-button-disabled {
    opacity: 0;
    visibility: hidden;
    user-select: none;
  }

  &:after {
    font-size: 0;
  }
}

.swiper-button-next {
  right: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  background-image: url(~@/static/icons/next-arrow--small.svg);
}

.swiper-button-prev {
  left: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  background-image: url(~@/static/icons/next-arrow--small.svg);
}
</style>

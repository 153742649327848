<template>
  <div class="checkout-curtains">
    <div class="checkout-curtains__container container-checkout">
      <div class="checkout-curtains__wrapper">
        <ul class="checkout-curtains__items">
          <li
            v-for="(item, index) in items"
            :key="index"
            class="checkout-curtains__item"
          >
            <div class="checkout-curtains__item-wrapper-icon">
              <img
                :src="require(`@/static/checkout/icons/${item.icon}`)"
                :alt="item.title"
              />
            </div>
            <div class="checkout-curtains__item-title">
              {{ item.title }}
            </div>
            <div class="checkout-curtains__item-text">
              {{ item.text }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutCurtains',
  data() {
    return {
      items: [
        {
          icon: 'pause.svg',
          title: 'PAUSE',
          text: 'Meditation allows you to take a step back, recenter yourself and slow things down',
        },
        {
          icon: 'space.svg',
          title: 'SPACE',
          text: 'Self-hypnosis diminishes the flow of thoughts and distractions',
        },
        {
          icon: 'relaxation.svg',
          title: 'RELAXATION',
          text: 'Cognitive behavioral therapy teaches you how to let go of stress, tension and worries',
        },
        {
          icon: 'calm-women.svg',
          title: 'CALM',
          text: "Meditation helps you find peace within, no matter what's happening outside",
        },
        {
          icon: 'control.svg',
          title: 'CONTROL',
          text: 'Cognitive behavioral therapy empowers you to break negative thought patterns and focus on something else',
        },
        {
          icon: 'happiness.svg',
          title: 'CONTENTMENT',
          text: 'Self-affirmations lift your mood by naturally boosting serotonin and endorphins',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.checkout-curtains {
  padding: 72px 0 100px;

  &__wrapper {
    position: relative;
    padding: 144px 24px;
    border-radius: 12px;
    background-image: url('~@/static/checkout/curtains.png');

    &:after {
      content: '';
      position: absolute;
      inset: 0;
      background-color: rgba(#041114, 0.6);
      border-radius: 12px;
    }
  }

  &__items {
    position: relative;
    max-width: 640px;
    margin-inline: auto;
    z-index: 1;
  }

  &__item {
    position: relative;
    padding-left: 124px;
    min-height: 100px;

    &:not(:last-child) {
      margin-bottom: 52px;
    }
  }

  &__item-wrapper-icon {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid var(--color-white);
  }

  &__item-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 4px;
  }

  &__item-text {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
  }
}
</style>

<template>
  <div class="checkout-not-so-secret">
    <div class="checkout-not-so-secret__container container-checkout">
      <div class="checkout-not-so-secret__title">
        Our "Not So Secret" Method To <br />
        <b>Rewire Your Mindset</b>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutWhiteNotSoSecret',
}
</script>

<style lang="scss" scoped>
.checkout-not-so-secret {
  &__title {
    padding: 100px 40px;
    text-align: center;
    font-weight: 400;
    font-size: 56px;
    line-height: 1;
    background-color: var(--color-white-01);
    border-radius: 12px;

    b {
      font-weight: 800;
    }
  }
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './axios'
import 'ant-design-vue/lib/modal/style/css'
import 'ant-design-vue/lib/spin/style/css'
import 'ant-design-vue/lib/message/style/css'
import 'ant-design-vue/lib/progress/style/css'
import 'ant-design-vue/lib/notification/style/css'
import 'ant-design-vue/lib/tooltip/style/css'
import 'ant-design-vue/lib/button/style/css'
import 'ant-design-vue/lib/slider/style/css'
import 'ant-design-vue/lib/carousel/style/css'
import 'ant-design-vue/lib/skeleton/style/css'
import 'ant-design-vue/lib/radio/style/css'
import 'ant-design-vue/lib/steps/style/css'
import 'ant-design-vue/lib/popover/style/css'
import 'ant-design-vue/lib/collapse/style/css'
import './assets/_common.scss'
import {
  Modal,
  Spin,
  Progress,
  Tooltip,
  Button,
  Slider,
  Carousel,
  Skeleton,
  Radio,
  Steps,
  Step,
  Popover,
  Collapse,
  CollapsePanel,
} from 'ant-design-vue'
import vue3GoogleLogin from 'vue3-google-login'
import './utils/firebase'
import Vuex from 'vuex'
createApp(App)
  .use(AOS.init())
  .use(vue3GoogleLogin, {
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  })
  .use(Modal)
  .use(Spin)
  .use(Step)
  .use(Collapse)
  .use(CollapsePanel)
  .use(Steps)
  .use(Popover)
  .use(Progress)
  .use(Tooltip)
  .use(Skeleton)
  .use(Button)
  .use(Radio)
  .use(Slider)
  .use(Carousel)
  .use(store)
  .use(router)
  // .use(Vuex)
  .mount('#app')

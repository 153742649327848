import { getNote, getNotes } from '../../services/note.service'

const state = () => ({
  notes: [],
  note: {},
})

const actions = {
  async fetchNotes({ commit }) {
    return getNotes().then((res) => {
      commit('SET_DATA', res.data)
    })
  },
  async fetchNote({ commit }, id) {
    return getNote(id).then((res) => {
      commit('SET_NOTE', res)
    })
  },
}

const mutations = {
  SET_DATA(state, data) {
    state.notes = data
  },
  SET_NOTE(state, data) {
    state.note = data
  },
}

export const note = {
  namespaced: true,
  state,
  actions,
  mutations,
}

import { userGetCheckout } from '@/services/user.service'
import { getSingleCheckout } from '@/services/checkout.service'

const state = () => ({
  checkout: {},
  isLoading: false,
  checkoutType: null,
})

const actions = {
  async fetchCheckoutById({ commit, dispatch }, id) {
    commit('SET_LOADING', true)
    return userGetCheckout(id)
      .then((res) => {
        commit('SET_CHECKOUT', res)
        dispatch('packageModule/setPackages', res.packages, { root: true })
        return res
      })
      .finally(() => commit('SET_LOADING', false))
  },

  async fetchCheckout({ commit, dispatch }, id) {
    commit('SET_LOADING', true)
    return getSingleCheckout(id)
      .then((res) => {
        commit('SET_CHECKOUT', res)
        dispatch('packageModule/setPackages', res.packages, { root: true })
        return res
      })
      .finally(() => commit('SET_LOADING', false))
  },
}

const mutations = {
  SET_CHECKOUT(state, data) {
    state.checkout = data
  },
  SET_LOADING(state, data) {
    state.isLoading = data
  },
  SET_CHECKOUT_TYPE(state, data) {
    state.checkoutType = data
  },
}

export const checkout = {
  namespaced: true,
  state,
  actions,
  mutations,
}

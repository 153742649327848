<template>
  <div class="page-wrapper theme-green-forest">
    <Header :user="user" />
    <TherapyCollection />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import TherapyCollection from '@/layouts/TherapyCollection.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Therapy',
  components: { Header, TherapyCollection, Footer },
  props: ['user'],
}
</script>
<style lang="scss" scoped>
.title-container {
  padding: 0 36px;
}
</style>

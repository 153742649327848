<template>
  <div class="checkout-white-video">
    <div class="checkout-white-video__title" v-html="title"></div>
    <div class="checkout-white-video__wrapper">
      <div class="checkout-white-video__top-text">
        <div class="checkout-white-video__wrapper-icon">
          <img
            src="@/static/checkout-white/icons/people.svg"
            alt="people"
            class="checkout-white-video__icon"
          />
        </div>
        <div class="checkout-white-video__text">
          Total participants so far: <b>1,249 People</b>
        </div>
      </div>
      <div class="checkout-white-video__bottom-video">
        <video
          class="checkout-white-video__bottom-video__video-tag"
          width="327"
          height="184"
          poster="@/assets/img/MeditationThumbnail.png"
          ref="quizResultVideo"
          :controls="true"
        >
          <source :src="videoUrl" type="video/mp4" />
        </video>
        <button
          v-if="!isHidePlayBtn"
          class="checkout-white-video__bottom-video__play btn-reset"
          @click="onPlay"
        >
          <img
            src="@/static/checkout-white/icons/play.svg"
            alt="people"
            class="checkout-white-video__icon-play"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { videoUrl } from '@/utils/helpers'
export default {
  name: 'CheckoutWhiteVideo',
  data() {
    return {
      videoUrl,
      isHidePlayBtn: false,
    }
  },
  computed: {
    ...mapState(['user']),
    title() {
      return `${
        this.user && this.user.name ? `${this.user.name} , this` : 'This'
      } is your personalized game-changing program. This offer is limited and customized specially for you`
    },
  },
  mounted() {
    this.$refs.quizResultVideo.onpause = () => {
      this.isHidePlayBtn = false
    }
    this.$refs.quizResultVideo.onplay = () => {
      this.isHidePlayBtn = true
    }
  },
  methods: {
    onPlay() {
      this.$refs.quizResultVideo.play()
    },
  },
}
</script>

<style lang="scss">
.checkout-white-video {
  padding-bottom: 44px;

  &__wrapper {
    position: relative;
    &:after,
    &:before {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 339px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &:after {
      left: -170px;
      background-image: url('~@/static/checkout-white/flower-left.svg');
    }

    &:before {
      right: -170px;
      background-image: url('~@/static/checkout-white/flower-right.svg');
    }
  }

  &__title {
    font-weight: 700;
    font-size: 56px;
    line-height: 64px;
    text-align: center;
    padding-bottom: 36px;
  }

  &__top-text {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    background-color: var(--color-red);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    z-index: 1;
  }

  &__wrapper-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--color-white);
    margin-right: 13px;
  }

  &__text {
    font-family: var(--manrople-regular);
    font-size: 18px;
    line-height: 24px;
    color: var(--color-white);
  }

  &__bottom-video {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 614px;
    background-color: var(--color-dark);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 1;

    &__video-tag {
      width: 100%;
      height: 100%;
    }

    &__play {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>

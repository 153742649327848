<template>
  <template v-if="user">
    <div class="page-wrapper theme-green-forest">
      <Header :user="user" />
      <HomeCollection />
      <Footer />
    </div>
    <subscription-modal @done="onDone" />
  </template>
</template>

<script>
import Header from '@/components/Header.vue'
import HomeCollection from '@/layouts/HomeCollection.vue'
import Footer from '@/components/Footer.vue'
import SubscriptionModal from '@/components/subscribe/SubscriptionModal.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import { notification } from 'ant-design-vue'
import { getPackageByID } from '@/services/package.service'

export default {
  name: 'Home',
  components: {
    Header,
    HomeCollection,
    Footer,
    SubscriptionModal,
  },
  computed: {
    ...mapState(['user', 'isUserStatusLoading', 'userStatus']),
  },
  async created() {
    this.fetchUser()
    this.isLoading = true
    this.getUserStatus()
    const justRegistered = localStorage.getItem('justRegistered')
    if (justRegistered && this.user?.email) {
      notification.info({
        message: `Subscribed to Mental Future! Check (${this.user.email}) for access details and to update your temporary password. Enjoy your journey!`,
        duration: 5,
      })
      localStorage.removeItem('justRegistered')
    }
    if (this.$route.query.message) {
      notification.info({ message: this.$route.query.message })
    }
    if (this.$route.query.event === 'purchase') {
      await getPackageByID(this.$route.query.package_id).then((res) => {
        fbq('track', 'Purchase', {
          currency: res.currency,
          value: res.amount,
        })
      })
    }
  },
  methods: {
    ...mapMutations('subscription', ['SET_OPEN']),
    ...mapActions(['getUserStatus']),
    ...mapActions(['fetchUser']),
    onDone() {
      this.SET_OPEN({ isOpen: false })
      window.location.reload()
    },
  },
}
</script>
<style lang="scss" scoped></style>

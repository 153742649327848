<template>
  <div class="checkout-warning">
    <div class="checkout-warning__container container-checkout">
      <div class="checkout-warning__wrapper">
        <img
          :src="require(`@/static${img_src}`)"
          alt="Warning icon"
          class="checkout-warning__icon"
        />
        <div class="checkout-warning__title">
          <b>WARNING: </b> What You're About Learn Can Change Your Life Once And
          For All
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutWarning',
  data() {
    return {
      img_src: '/checkout/icons/warning.svg',
    }
  },
}
</script>

<style lang="scss">
.checkout-warning {
  padding-bottom: 52px;

  &__wrapper {
    display: flex;
    align-content: center;
    padding: 20px 38px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 4px dashed var(--color-white);
    border-radius: 16px;
  }

  &__title {
    display: flex;
    align-items: center;
    padding-left: 8px;
    font-weight: 400;
    font-size: 36px;
    line-height: 48px;
    color: var(--color-white);

    b {
      padding-right: 16px;
    }
  }
}
</style>

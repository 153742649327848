<template>
  <div id="nav">
    <router-view />
  </div>
</template>
<script>
import { notification } from 'ant-design-vue'
import { mapActions, mapState } from 'vuex'
import { ping } from './services/other.service'

export default {
  name: 'App',
  data() {
    return {
      interval: null,
    }
  },
  computed: {
    ...mapState(['token']),
    ...mapState('error', ['hasError', 'errorMessage']),
  },
  watch: {
    hasError(value) {
      if (value) {
        notification.error({ message: this.errorMessage, duration: 3 })
        this.setError({ message: '', hasError: false })
      }
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.token) ping()
    }, 300000)
  },
  beforeUnmount() {
    clearInterval(this.interval)
  },
  methods: {
    ...mapActions(['fetchUser']),
    ...mapActions('error', ['setError']),
  },
}
</script>

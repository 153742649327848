<template>
  <template v-if="therapyCollection">
    <collection-section :data="therapyCollection.section" />
    <SubscriptionModal @done="onDone" />
  </template>
</template>

<script>
import axios from 'axios'
import SubscriptionModal from '@/components/subscribe/SubscriptionModal.vue'
import CollectionSection from '@/components/collection/CollectionSection.vue'

export default {
  name: 'TherapyCollection',
  components: { SubscriptionModal, CollectionSection },
  data() {
    return {
      therapyCollection: {},
      tags: [],
    }
  },
  created() {
    axios.get(`screen/therapy`).then((response) => {
      this.therapyCollection = response
    })

    axios.get(`tags`).then((response) => {
      this.tags = response.tags
    })
  },
  methods: {
    onDone() {
      window.location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
.section {
  &__item {
    flex: 0 1 200px;
    max-width: 200px;
  }

  &__items {
    padding-left: 36px;
    column-gap: 20px;
  }
}
</style>

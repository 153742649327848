<template>
  <div class="checkout-white-benefits">
    <p class="checkout-white-benefits__text">
      If you want mental health care to be a bigger part of your life, so you
      can really enjoy all its benefits, then join our step-by-step
      revolutionary program
    </p>
    <p class="checkout-white-benefits__text-big">
      Feel the benefits of our techniques with only 5 minutes a day!
    </p>
    <checkout-white-get-started-now />
  </div>
</template>

<script>
import CheckoutWhiteGetStartedNow from './CheckoutWhiteGetStartedNow.vue'

export default {
  name: 'CheckoutWhiteBenefits',
  components: {
    CheckoutWhiteGetStartedNow,
  },
}
</script>

<style lang="scss">
.checkout-white-benefits {
  text-align: center;

  &__text {
    font-weight: 500;
    font-size: 36px;
    line-height: 49px;
    color: var(--color-dark);
    margin-bottom: 60px;
  }

  &__text-big {
    font-weight: 800;
    font-size: 48px;
    line-height: 65px;
    color: var(--color-red);
    margin-bottom: 48px;
  }
}
</style>

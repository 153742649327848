<template>
  <section class="no-stress">
    <div class="container container--fluid">
      <h2 class="no-stress__title welcome-title">
        Your Greatest Weapon Against Stress & Negative Thoughts
      </h2>
      <p class="no-stress__description welcome-description">
        From meditations and sleepcasts to CBT and various other therapies - we
        have everything you need to make a positive change in your life.
      </p>
      <swiper
        :spaceBetween="20"
        :slides-per-view="auto"
        :slidesPerColumn="1"
        :loop="true"
        :loopedSlides="15"
        :loop-fill-group-with-blank="true"
        :slides-per-group-auto="true"
        :speed="3500"
        :lazy="true"
        :width="224"
        :simulate-touch="false"
        :centeredSlides="true"
        :autoplay="{
          enabled: true,
          delay: 0,
          disableOnInteraction: false,
        }"
        class="mySwiper no-stress__list"
      >
        <swiper-slide v-for="item in info.top" :key="item">
          <a href="#" class="no-stress__list-item">
            <h5 class="no-stress__list-type">
              {{ item.type }}
            </h5>
            <h4 class="no-stress__list-title">
              {{ item.title }}
            </h4>
            <img
              :src="require(`@/static${item.image_url}`)"
              :alt="`${item.title}`"
              class="no-stress__list-item-img"
            />
          </a>
        </swiper-slide>
      </swiper>
      <swiper
        :spaceBetween="20"
        :slides-per-view="auto"
        :slidesPerColumn="1"
        :loop="true"
        :loopedSlides="15"
        :loop-fill-group-with-blank="true"
        :slides-per-group-auto="true"
        :speed="3500"
        :lazy="true"
        :width="224"
        :simulate-touch="false"
        :centeredSlides="true"
        :autoplay="{
          enabled: true,
          delay: 0,
          disableOnInteraction: false,
        }"
        class="mySwiper no-stress__list"
      >
        <swiper-slide v-for="item in info.bottom" :key="item">
          <a href="#" class="no-stress__list-item">
            <h5 class="no-stress__list-type">
              {{ item.type }}
            </h5>
            <h4 class="no-stress__list-title">
              {{ item.title }}
            </h4>
            <img
              :src="require(`@/static${item.image_url}`)"
              :alt="`${item.title}`"
              class="no-stress__list-item-img"
            />
          </a>
        </swiper-slide>
      </swiper>
      <router-link to="/quiz" class="no-stress__link start-quiz-link">
        <span class="start-quiz-link__text">Start my quiz</span>
      </router-link>
    </div>
  </section>
</template>

<script>
import json from '@/data/nostress.json'
import { Swiper, SwiperSlide } from 'swiper/vue'
// import 'swiper/swiper-bundle.css'
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper'
SwiperCore.use([Autoplay, Pagination, Navigation])

export default {
  name: 'Types',
  components: { Swiper, SwiperSlide },
  data() {
    return {
      info: json,
    }
  },
}
</script>

<style lang="scss" scoped>
.no-stress {
  padding-bottom: 144px;

  @media (max-width: 767px) {
    padding-bottom: 72px;
  }

  &__title,
  &__description {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }

  &__title {
    max-width: 705px;
  }

  &__description {
    max-width: 615px;
  }

  &__list {
    display: flex;
    margin-top: 24px;
  }

  &__list-item {
    display: block;
    position: relative;
    height: 216px;
    padding: 16px 12px;
    border-radius: 12px;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(#041114, 0.4);
      border-radius: 12px;
      overflow: hidden;
    }
  }

  .swiper-slide {
    border-radius: 12px;
    overflow: hidden;

    &:last-child {
      position: absolute;
      left: 0;
      width: 100% !important;
      max-width: 224px !important;
    }
  }

  &__list-type {
    position: relative;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-white);
    margin-top: 0;
    margin-bottom: 8px;
    z-index: 1;
  }

  &__list-title {
    position: relative;
    font-weight: 700;
    font-size: 16px;
    color: var(--color-white);
    line-height: 26px;
    z-index: 1;
    margin: 0;
  }

  &__list-item-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }

  &__link {
    margin-top: 72px;
    margin-right: auto;
    margin-left: auto;
  }
}
</style>

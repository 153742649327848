<template>
  <div class="checkout-total">
    <div class="checkout-total__container container-checkout">
      <div class="checkout-total__wrapper">
        <div class="checkout-total__wrapper-icon">
          <img src="@/static/checkout/icons/people.svg" alt="" class="checkout-total__icon">
        </div>
        <div class="checkout-total__text">
          Total participants so far: <b>1,249 People</b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutTotal'
}
</script>

<style lang="scss">
.checkout-total {
  padding-bottom: 32px;

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    background-color: rgba(#fff, 0.1);
    width: 100%;
    border-radius: 12px;
  }

  &__wrapper-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--color-white);
    margin-right: 13px;
  }

  &__text {
    font-family: var(--manrople-regular);
    font-size: 18px;
    line-height: 24px;
  }
}

</style>
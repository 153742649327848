<template>
  <div class="page-wrapper">
    <Header :user="user" />
    <div class="settings">
      <router-link to="/settings" class="settings__back-link">
        <img
          src="@/static/settings/arrow.svg"
          alt="Arrow back"
          title="Back to profile settings"
        />
      </router-link>
      <div class="settings__container container container--big">
        <div class="settings__top">
          <div class="settings__top-title">Account</div>
        </div>
      </div>
      <div class="settings__account">
        <div class="settings__account-wrapper">
          <div class="settings__account-title">Update your account details</div>
          <div class="settings__account-form-wrapper">
            <form
              class="settings__account-form"
              @submit.prevent="accountUpdateInfo"
            >
              <div class="settings__account-form-input-wrapper">
                <div class="settings__account-form-input-icon">
                  <img src="@/static/settings/account.svg" alt="icon" />
                </div>
                <input
                  type="text"
                  class="settings__account-form-input"
                  v-model="user.name"
                  required
                />
              </div>
              <div class="settings__account-form-input-wrapper">
                <div class="settings__account-form-input-icon">
                  <img src="@/static/settings/email.svg" alt="icon" />
                </div>
                <input
                  type="email"
                  class="settings__account-form-input"
                  v-model="user.email"
                  required
                />
              </div>
              <button
                type="submit"
                class="settings__account-form-button btn btn--white"
              >
                Update
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer.vue'
import axios from 'axios'
import { notification } from 'ant-design-vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Account',
  components: { Header, Footer },
  computed: {
    ...mapState(['user']),
  },
  created() {
    this.fetchUser()
  },
  methods: {
    ...mapActions(['fetchUser']),
    async accountUpdateInfo() {
      try {
        await axios.put(`user`, {
          name: this.user.name,
          email: this.user.email,
        })
        notification.success({
          message: 'Successfully updated!',
          placement: 'bottomRight',
        })
      } catch (err) {
        // notification.error({
        //   message: err.message,
        //   placement: 'bottomRight',
        // })
      }
    },
  },
}
</script>

<style lang="scss">
.settings {
  position: relative;

  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 25px;
  }

  &__top-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
    padding-bottom: 72px;
  }

  &__account-title {
    font-family: var(--run);
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: var(--color-white);
    margin-bottom: 70px;
  }

  &__account-form-wrapper,
  &__password-change-wrapper {
    max-width: 327px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  &__account-form-input-wrapper,
  &__password-change-form-input-wrapper {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__account-form-input-icon,
  &__password-change-form-input-icon {
    position: absolute;
    left: 25px;
    top: 16px;
  }

  &__account-form-input,
  &__password-change-form-input {
    font-weight: 500;
    font-size: 14px;
    display: block;
    width: 100%;
    background: transparent;
    text-align: center;
    color: #fff;
    padding: 14px 55px 14px 55px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.1);
    border-radius: 58px;

    &:focus {
      outline: 0;
      border-color: rgba(255, 255, 255, 0.8);

      &::placeholder {
        opacity: 0.3;
      }
    }
    &::placeholder {
      font-weight: 500;
      font-size: 14px;
      color: #fff;
      text-align: center;
    }
  }

  &__account-form-button,
  &__pasword-change-form-button {
    height: 52px;
    margin-top: 152px;
  }
}
</style>

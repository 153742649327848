<template>
  <div class="checkout-change-their-life">
    <div class="checkout-change-their-life__container container-checkout">
      <div class="checkout-change-their-life__wrapper">
        <div class="checkout-change-their-life__title">
          {{ title }}
        </div>
        <checkout-get-started-now />
        <checkout-timer />
        <div
          class="checkout-change-their-life__subtitle"
          v-html="subtitle"
        ></div>
        <div class="checkout-change-their-life__arrow-2">
          <img
            src="@/static/checkout/icons/arrow.svg"
            alt="arrow"
            class="checkout-change-their-life__arrow-icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckoutGetStartedNow from './CheckoutGetStartedNow.vue'
import CheckoutTimer from './CheckoutTimer.vue'

export default {
  name: 'CheckoutChangeTheirLife',
  components: {
    CheckoutGetStartedNow,
    CheckoutTimer,
  },
  data() {
    return {
      title: 'Are You Ready To Start Your Journey?',
      subtitle: 'Some Of The People We Have Helped <b>Change Their Life</b>',
    }
  },
}
</script>

<style lang="scss" scoped>
.checkout-change-their-life {
  padding-bottom: 194px;

  .checkout-get-started-now {
    max-width: 580px;
    margin-inline: auto;
  }

  &__wrapper {
    position: relative;
    padding: 72px 15px 98px;
    background-color: var(--color-white-01);
    border-radius: 12px;
  }

  &__title,
  &__subtitle {
    text-align: center;
  }

  &__title {
    font-weight: 800;
    font-size: 48px;
    line-height: 56px;
    padding-bottom: 52px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    max-width: 865px;
    margin-inline: auto;

    b {
      font-weight: 800;
    }
  }

  &__arrow-2 {
    position: absolute;
    bottom: -48px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background-color: var(--color-white);
  }
}
</style>

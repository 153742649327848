import { getPackage } from '@/services/package.service'
import { packagePlaces, purchaseTypes } from '@/data/constants'

const state = () => ({
  packages: [],
  isPackageLoading: false,
  activePackage: '',
})

const actions = {
  async fetchPackage({ commit }, query) {
    commit('SET_PACKAGE_LOADING', true)
    return getPackage(query)
      .then((res) => {
        commit('SET_DATA', res.data)
      })
      .finally(() => {
        commit('SET_PACKAGE_LOADING', false)
      })
  },

  setPackages({ commit }, data) {
    commit('SET_DATA', data)
  },

  setActivePackage({ commit }, payload) {
    commit('SET_ACTIVE_PACKAGE', payload)
  },

  async getPackageByType({ state, dispatch }, { type, provider, currency }) {
    if (!state.packages || (state.packages && !state.packages.length)) {
      await dispatch('fetchPackage', {
        place: packagePlaces.AFTER_LOCKED_CONTENT,
      })
    }
    let p = {}
    switch (type) {
      case purchaseTypes.MONTHLY:
        p = state.packages.find(
          (i) =>
            i.months === 1 && provider === i.provider && i.currency === currency
        )
        break
      case purchaseTypes.YEARLY:
        p = state.packages.find(
          (i) =>
            i.months === 12 &&
            provider === i.provider &&
            i.currency === currency
        )
        break
      case purchaseTypes.LIFETIME:
        p = state.packages.find(
          (i) =>
            i.is_lifetime && provider === i.provider && i.currency === currency
        )
        break
    }
    return p
  },
}

const mutations = {
  SET_DATA(state, data) {
    state.packages = data
  },
  SET_PACKAGE_LOADING(state, data) {
    state.isPackageLoading = data
  },
  SET_ACTIVE_PACKAGE(state, data) {
    state.activePackage = data
  },
}

export const packageModule = {
  namespaced: true,
  state,
  actions,
  mutations,
}

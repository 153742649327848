const state = () => ({
  errorMessage: '',
  hasError: false,
})

const actions = {
  setError({ commit }, data) {
    commit('SET_ERROR', data)
  },
}

const mutations = {
  SET_ERROR(state, { message, hasError }) {
    state.errorMessage = message
    state.hasError = hasError
  },
}

export const error = {
  namespaced: true,
  state,
  actions,
  mutations,
}

<template>
  <div>
    <div
      class="container container--big"
      v-if="!recentlyPlayed.length && !recommendation.length && !favorite"
    >
      <div class="profile-null-items">You not have items.</div>
    </div>

    <template v-if="recentlyPlayed.length">
      <div class="wrapper-section">
        <section class="section">
          <h3 class="section__name">Recently Played</h3>
          <ul class="section__items">
            <div class="slider-mask">
              <swiper
                :spaceBetween="24"
                :slidesPerView="'auto'"
                :slidesPerGroup="2"
                :loop="false"
                :lazy="true"
                :simulateTouch="false"
                :navigation="{
                  nextEl: `.swiper-button-next-${section.id}`,
                  prevEl: `.swiper-button-prev-${section.id}`,
                }"
                class="mySwiper collection__list"
              >
                <swiper-slide v-for="(item, i) in recentlyPlayed" :key="i">
                  <li class="section__item" v-if="item.free == true">
                    <router-link :to="`/${item.model}/${item.id}`">
                      <div class="section__item-wrapper">
                        <div
                          class="section__item-image-wrapper"
                          :class="item.model"
                        >
                          <img
                            :src="`${item.cover}`"
                            alt="image"
                            loading="lazy"
                            class="section__item-image"
                          />
                        </div>
                        <div class="section__item-title">
                          {{ item.name }}
                        </div>
                        <div class="section__item-type-wrapper">
                          <span class="section__item-type">
                            {{ item.type }}
                          </span>
                          <span
                            class="section__item-duration"
                            v-if="item.duration"
                          >
                            {{ item.duration }}
                          </span>
                        </div>
                      </div>
                    </router-link>
                  </li>
                </swiper-slide>
              </swiper>
              <div
                :class="`swiper-button-prev-${section.id} swiper-button-prev`"
              ></div>
              <div
                :class="`swiper-button-next-${section.id} swiper-button-next`"
              ></div>
            </div>
          </ul>
        </section>
      </div>
    </template>

    <template v-if="recommendation.length">
      <div class="wrapper-section">
        <section class="section">
          <h3 class="section__name">Recommendation</h3>
          <ul class="section__items">
            <div class="slider-mask">
              <swiper
                :spaceBetween="24"
                :slidesPerView="'auto'"
                :slidesPerGroup="2"
                :loop="false"
                :lazy="true"
                :simulateTouch="false"
                :navigation="{
                  nextEl: `.swiper-button-next-${section.id}`,
                  prevEl: `.swiper-button-prev-${section.id}`,
                }"
                class="mySwiper collection__list"
              >
                <swiper-slide v-for="(item, i) in recommendation" :key="i">
                  <li class="section__item" v-if="item.free == true">
                    <router-link :to="`/${item.model}/${item.id}`">
                      <div class="section__item-wrapper">
                        <div
                          class="section__item-image-wrapper"
                          :class="item.model"
                        >
                          <img
                            :src="`${item.cover}`"
                            alt="image"
                            loading="lazy"
                            class="section__item-image"
                          />
                        </div>
                        <div class="section__item-title">
                          {{ item.name }}
                        </div>
                        <div class="section__item-type-wrapper">
                          <span class="section__item-type">
                            {{ item.type }}
                          </span>
                          <span
                            class="section__item-duration"
                            v-if="item.duration"
                          >
                            {{ item.duration }}
                          </span>
                        </div>
                      </div>
                    </router-link>
                  </li>
                </swiper-slide>
              </swiper>
              <div
                :class="`swiper-button-prev-${section.id} swiper-button-prev`"
              ></div>
              <div
                :class="`swiper-button-next-${section.id} swiper-button-next`"
              ></div>
            </div>
          </ul>
        </section>
      </div>
    </template>

    <template v-if="favorite">
      <div class="wrapper-section">
        <section class="section">
          <h3 class="section__name">Favorite</h3>
          <ul class="section__items">
            <div class="slider-mask">
              <swiper
                :spaceBetween="24"
                :slidesPerView="'auto'"
                :slidesPerGroup="2"
                :loop="false"
                :lazy="true"
                :simulateTouch="false"
                :navigation="{
                  nextEl: `.swiper-button-next-${section.id}`,
                  prevEl: `.swiper-button-prev-${section.id}`,
                }"
                class="mySwiper collection__list"
              >
                <swiper-slide v-for="(item, i) in favorite" :key="i">
                  <li class="section__item" v-if="item.free == true">
                    <router-link :to="`/${item.model}/${item.id}`">
                      <div class="section__item-wrapper">
                        <div
                          class="section__item-image-wrapper"
                          :class="item.model"
                        >
                          <img
                            :src="`${item.cover}`"
                            alt="image"
                            loading="lazy"
                            class="section__item-image"
                          />
                        </div>
                        <div class="section__item-title">
                          {{ item.name }}
                        </div>
                        <div class="section__item-type-wrapper">
                          <span class="section__item-type">
                            {{ item.type }}
                          </span>
                          <span
                            class="section__item-duration"
                            v-if="item.duration"
                          >
                            {{ item.duration }}
                          </span>
                        </div>
                      </div>
                    </router-link>
                  </li>
                </swiper-slide>
              </swiper>
              <div
                :class="`swiper-button-prev-${section.id} swiper-button-prev`"
              ></div>
              <div
                :class="`swiper-button-next-${section.id} swiper-button-next`"
              ></div>
            </div>
          </ul>
        </section>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import { Swiper, SwiperSlide } from 'swiper/vue'
// import 'swiper/swiper-bundle.css'
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper'
SwiperCore.use([Autoplay, Pagination, Navigation])

export default {
  name: 'Library',
  components: { Swiper, SwiperSlide },
  data() {
    return {
      recommendation: {},
      recentlyPlayed: {},
      favorite: null,
    }
  },
  created() {
    axios.get(`recently-played`).then((response) => {
      this.recentlyPlayed = response.data
    })

    axios.get(`recommendation`).then((response) => {
      this.recommendation = response
    })

    axios.get(`favorite`).then((response) => {
      this.favorite = response.data
    })
  },
}
</script>
<style lang="scss">
.profile-null-items {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-white);
  text-align: center;
}
.pl-36 {
  padding-left: 36px;
}
</style>

<template>
  <div class="container container--big flex-1">
    <div class="profile__notes notes">
      <div class="notes__title">
        <span>Notes & Reflections</span>
        <router-link
          to="/profile/notes/create"
          class="notes__add-new btn-reset"
        >
          <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_7811_1705)" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"><path d="M11.5 22.5c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11zM11.5 5.5v12M17.5 11.5h-12"/></g><defs><clipPath id="clip0_7811_1705"><path fill="#fff" d="M0 0h23v23H0z"/></clipPath></defs></svg>
        </router-link>
      </div>
      <ul class="notes__grid" v-if="notes && notes.length">
        <li v-for="note in notes" :key="note.id">
          <router-link
            :to="{ name: 'Note', params: { id: note.id } }"
            class="notes__grid-item"
          >
            <div class="notes__grid-item-name">{{ note.title }}</div>
            <div class="notes__grid-item-text">
              {{ note.content }}
            </div>
            <div class="notes__grid-item-date">{{ note.date }}</div>
            <!-- <router-link
            :to="{ name: 'Note', params: { id: note.id } }"
            class="notes__grid-item-edit btn-reset"
          >
            <img
              src="@/static/profile/notes/edit.svg"
              alt="Edit note"
              title="Edit note"
            />
          </router-link> -->
          </router-link>
        </li>
      </ul>
      <div v-else class="flex-center">
        <p class="notes__not-found">No notes found</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Notes',
  computed: {
    ...mapState('note', ['notes']),
  },
  created() {
    this.fetchNotes()
  },
  methods: {
    ...mapActions('note', ['fetchNotes']),
  },
}
</script>
<style lang="scss">
.notes {
  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    padding-bottom: 36px;
    color: var(--color-white);
    display: flex;
    align-items: center;
  }

  &__add-new {
    display: flex;
    margin-left: 16px !important;
  }

  &__not-found {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: rgba(#fff, 0.5);
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    li {
      max-width: 154px;
      width: 100%;
    }
  }

  &__grid-item {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 184px;
    padding: 12px;
    border-radius: 12px;
    background-color: rgba(#fff, 0.1);
    color: var(--color-white);
  }

  &__grid-item-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    padding-right: 24px;
    float: right;
  }

  &__grid-item-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
  }

  &__grid-item-date {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(#fff, 0.5);
    margin-top: auto;
    text-transform: uppercase;
  }

  &__grid-item-edit {
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid var(--color-white);

    img {
      width: 14px;
      height: 14px;
    }
  }
}
</style>

export function checkUrl(data, isMale, isAudio) {
  const male = {
    isVideo: !!data.video_male,
    isAudio: !!data.audio_male,
  }
  const female = {
    isVideo: !!data.video_female,
    isAudio: !!data.audio_female,
  }
  let url
  if (isMale) {
    if (male.isVideo && !isAudio) {
      url = data.video_male.hls.playlist
    } else if (male.isAudio) {
      url = data.audio_male.hls.playlist
    }
  } else {
    if (female.isVideo && !isAudio) {
      url = data.video_female.hls.playlist
    } else if (female.isAudio) {
      url = data.audio_female.hls.playlist
    }
  }
  return { male, female, url }
}

export function getUrl(data) {
  if (data.video_male && data.video_male.hls) {
    return { url: data.video_male.hls.playlist, isAudio: false, isMale: true }
  }
  if (data.audio_male && data.audio_male.hls) {
    return { url: data.audio_male.hls.playlist, isAudio: true, isMale: true }
  }
  if (data.video_female && data.video_female.hls) {
    return {
      url: data.video_female.hls.playlist,
      isAudio: false,
      isMale: false,
    }
  }
  if (data.audio_female && data.audio_female.hls) {
    return { url: data.audio_female.hls.playlist, isAudio: true, isMale: false }
  }

  return null
}

<template>
  <router-link
    :to="`/${data.model}/${data.id}`"
    v-if="isPremium || isFreeTrial"
    @click="onClick"
  >
    <div class="step-card">
      <div class="step-card__content">
        <span class="step-card__tag">{{ data.goal }}</span>
        <div>
          <h3 class="step-card__title">{{ data.name }}</h3>
          <span class="step-card__footer"
            ><span class="type">{{ data.type }}</span> •
            {{ data.duration }} min</span
          >
        </div>
      </div>
      <div
        class="step-card__image"
        :style="{
          'background-image': `url('${data.cover}')`,
        }"
      ></div>
    </div>
  </router-link>
  <div class="step-card locked" @click="$emit('onUnlock')" v-else>
    <div class="step-card__content">
      <span class="step-card__tag">{{ data.goal }}</span>
      <h3 class="step-card__title">{{ data.name }}</h3>
      <span class="step-card__footer"
        ><span class="type">{{ data.type }}</span> •
        {{ data.duration }} min</span
      >
    </div>
    <div
      class="step-card__image"
      :style="{
        'background-image': `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('${data.cover}')`,
      }"
    ></div>
  </div>
</template>

<script>
import { userHistory } from '@/services/user.service'
export default {
  name: 'StepCard',
  props: {
    data: { type: Object, default: {} },
    isPremium: { type: Boolean, required: true },
    isFreeTrial: { type: Boolean, required: false },
  },
  emits: ['onUnlock'],
  methods: {
    onClick() {
      userHistory({ id: this.data.id, model: this.data.model }).catch((err) =>
        console.log(err)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.step-card {
  display: grid;
  grid-template-columns: 1fr 96px;
  column-gap: 15px;
  width: 288px;
  background: rgba(#fff, 0.1);
  border-radius: 12px;
  padding: 16px;

  &.locked {
    cursor: pointer;
    .step-card__image {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 5px;
        right: 8px;
        width: 15px;
        height: 20px;
        background: url('../../assets/icons/lock-15-20.svg');
        background-repeat: no-repeat;
      }
    }
  }

  &__tag {
    font-size: 12px;
    line-height: 21px;
    color: var(--color-white);
    font-weight: 400;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    padding: 4px 8px;
  }

  &__title {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
    margin: 14px 0 8px;
    padding: 0;
  }

  &__footer {
    font-size: 12px;
    line-height: 16px;
    color: var(--color-white);
    font-weight: 400;

    .type {
      text-transform: capitalize;
    }
  }

  &__image {
    width: 96px;
    height: 104px;
    border-radius: 12px;
    background-position: center;
    background-size: cover;
  }
}
</style>

<template>
  <main class="checkout-white">
    <checkout-white-header />
    <div class="checkout-white__container">
      <checkout-white-video />
      <checkout-white-discover />
      <checkout-white-get-started-now />
      <checkout-white-logos />
      <checkout-white-timer :title="'Available for'" />
      <checkout-white-depression />
      <checkout-white-news />
      <checkout-white-triangle />
      <checkout-white-get-started-now />
      <checkout-white-comentar />
      <checkout-white-magic />
      <checkout-white-weapon-against />
      <checkout-white-mood />
      <checkout-white-behind-curtains />
      <checkout-white-curtains />
      <checkout-white-benefits />
      <checkout-white-timer :title="'Available only for'" />
      <checkout-white-testimonials />
      <checkout-white-get-started-now />
      <checkout-white-not-so-secret />
      <checkout-white-not-so-secret-items />
      <checkout-white-change-their-life />
      <checkout-white-reviews />
      <checkout-white-get-started-now />
      <!-- <checkout-white-be-crazy /> -->
      <checkout-white-what-you-get />
      <checkout-white-faq />
      <checkout-white-footer />
    </div>
    <subscription-modal @onDone="onDone" />
  </main>
</template>

<script>
import CheckoutWhiteHeader from './CheckoutWhiteHeader.vue'
import CheckoutWhiteVideo from './CheckoutWhiteVideo.vue'
import CheckoutWhiteDiscover from './CheckoutWhiteDiscover.vue'
import CheckoutWhiteGetStartedNow from './CheckoutWhiteGetStartedNow.vue'
import CheckoutWhiteLogos from './CheckoutWhiteLogos.vue'
import CheckoutWhiteTimer from './CheckoutWhiteTimer.vue'
import CheckoutWhiteDepression from './CheckoutWhiteDepression.vue'
import CheckoutWhiteNews from './CheckoutWhiteNews.vue'
import CheckoutWhiteTriangle from './CheckoutWhiteTriangle.vue'
import CheckoutWhiteComentar from './CheckoutWhiteComentar.vue'
import CheckoutWhiteMagic from './CheckoutWhiteMagic.vue'
import CheckoutWhiteWeaponAgainst from './CheckoutWhiteWeaponAgainst.vue'
import CheckoutWhiteMood from './CheckoutWhiteMood.vue'
import CheckoutWhiteBehindCurtains from './CheckoutWhiteBehindCurtains.vue'
import CheckoutWhiteCurtains from './CheckoutWhiteCurtains.vue'
import CheckoutWhiteBenefits from './CheckoutWhiteBenefits.vue'
import CheckoutWhiteTestimonials from './CheckoutWhiteTestimonials.vue'
import CheckoutWhiteNotSoSecret from './CheckoutWhiteNotSoSecret.vue'
import CheckoutWhiteNotSoSecretItems from './CheckoutWhiteNotSoSecretItems.vue'
import CheckoutWhiteChangeTheirLife from './CheckoutWhiteChangeTheirLife.vue'
import CheckoutWhiteReviews from './CheckoutWhiteReviews.vue'
import CheckoutWhiteBeCrazy from './CheckoutWhiteBeCrazy.vue'
import CheckoutWhiteWhatYouGet from './CheckoutWhiteWhatYouGet.vue'
import CheckoutWhiteFooter from './CheckoutWhiteFooter.vue'
import SubscriptionModal from '../subscribe/SubscriptionModal.vue'
import { mapActions, mapMutations } from 'vuex'
import CheckoutWhiteFaq from './CheckoutWhiteFaq.vue'

export default {
  name: 'CheckoutWhite',
  components: {
    CheckoutWhiteHeader,
    CheckoutWhiteVideo,
    CheckoutWhiteDiscover,
    CheckoutWhiteGetStartedNow,
    CheckoutWhiteLogos,
    CheckoutWhiteTimer,
    CheckoutWhiteDepression,
    CheckoutWhiteNews,
    CheckoutWhiteTriangle,
    CheckoutWhiteComentar,
    CheckoutWhiteMagic,
    CheckoutWhiteWeaponAgainst,
    CheckoutWhiteMood,
    CheckoutWhiteBehindCurtains,
    CheckoutWhiteCurtains,
    CheckoutWhiteBenefits,
    CheckoutWhiteTestimonials,
    CheckoutWhiteNotSoSecret,
    CheckoutWhiteNotSoSecretItems,
    CheckoutWhiteChangeTheirLife,
    CheckoutWhiteReviews,
    CheckoutWhiteBeCrazy,
    CheckoutWhiteWhatYouGet,
    CheckoutWhiteFooter,
    SubscriptionModal,
    CheckoutWhiteFaq,
  },
  created() {
    this.getFAQs()
  },
  methods: {
    ...mapMutations('subscription', ['SET_OPEN']),
    ...mapActions('faq', ['getFAQs']),
    onDone() {
      this.SET_OPEN({ isOpen: false })
      this.$router.push('/')
    },
  },
}
</script>

<style lang="scss">
.checkout-white {
  background-color: var(--color-white);
  color: var(--color-dark);
  overflow: hidden;

  &__container {
    max-width: 1190px;
    width: 100%;
    margin-inline: auto;
    padding-inline: 15px;
  }
}
</style>

<template>
  <div class="checkout-news">
    <div class="checkout-news__wrapper">
      <div class="checkout-news__title">
        {{ title }}
      </div>
      <div class="checkout-news__subtitles">
        <div
          v-for="(item, index) in subtitles"
          :key="index"
          class="checkout-news__subtitle"
          v-html="item.subtitle"
        ></div>
        <p class="checkout-news__p">
          Imagine what it would feel like to have greater control over your mind
          and life.To know how to always feel calm, centered, and focused no
          matter what. To be able to let go of things, overcome negative mental
          states, and master the inner chatter. If you want to experience stress
          relief, a creativity boost, a tranquil atmosphere in your home, or
          simply to enjoy deep relaxation, choose Mental Future and start your
          journey towards recovery.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutNews',
  data() {
    return {
      title: 'We have good news for you.',
      subtitles: [
        {
          subtitle: 'Discover the unique approach to reach your mental goals.',
        },
        {
          subtitle:
            'There is a reliable, and simple, path to go from one state to the other. It’s meditation and cognitive behavioral therapy!',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.checkout-news {
  text-align: center;
  padding-top: 115px;

  &__wrapper {
    position: relative;
    padding: 84px 24px 0;
    margin-bottom: 24px;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.1);
  }

  &__title {
    font-weight: 800;
    font-size: 56px;
    line-height: 64px;
    padding-bottom: 48px;
    color: var(--color-red);
  }

  &__subtitle {
    font-weight: 400;
    font-size: 36px;
    line-height: 49px;
    max-width: unset;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__p {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #2f3542;
    margin-top: 48px;
  }

  &__text {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    padding-top: 52px;
    max-width: 1044px;
    margin-inline: auto;
  }
}
</style>

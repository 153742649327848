<template>
  <div class="page-wrapper">
    <Header :user="user" />
    <div class="settings">
      <router-link to="/settings" class="settings__back-link">
        <img
          src="@/static/settings/arrow.svg"
          alt="Arrow back"
          title="Back to profile settings"
        />
      </router-link>
      <div class="settings__container container container--big">
        <div class="settings__top">
          <div class="settings__top-title">Subscription</div>
        </div>
      </div>
      <div class="settings__subscription">
        <div class="settings__subscription-wrapper">
          <div class="settings__subscription-items">
            <div class="settings__subscription-item">
              <div class="settings__subscription-item-title">
                Subscription Type
              </div>
              <div class="settings__subscription-item-value">
                <div v-if="subscriptionInfo.subscription_type !== null">
                  {{ subscriptionInfo.subscription_type }}
                </div>
                <div class="line" v-else></div>
              </div>
            </div>
            <div class="settings__subscription-item">
              <div class="settings__subscription-item-title">Member since</div>
              <div class="settings__subscription-item-value">
                {{ subscriptionInfo.member_since }}
              </div>
            </div>
            <div class="settings__subscription-item">
              <div class="settings__subscription-item-title">
                Subscription via
              </div>
              <div class="settings__subscription-item-value">
                <div v-if="subscriptionInfo.subscription_via !== null">
                  {{ subscriptionInfo.subscription_via }}
                </div>
                <div class="line" v-else></div>
              </div>
            </div>
          </div>
          <div class="settings__subscription-buttons">
            <button
              @click="onUpgrade(purchaseTypes.MONTHLY)"
              v-if="isFreeUser"
              class="settings__subscription-button btn btn--white"
            >
              Unlock Mental Future Premium
            </button>
            <button
              @click="onUpgrade(purchaseTypes.YEARLY)"
              v-else-if="!isAnnualPlan"
              class="settings__subscription-button btn btn--white"
            >
              Upgrade to annual subscription
            </button>
            <button
              v-if="!isFreeUser"
              @click="onCancel"
              class="settings__subscription-button btn btn--green"
            >
              Cancel my subscription
            </button>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <PaymentsModal
      :currentPackage="selectedPackage"
      :isOpen="isOpen"
      @close="isOpen = false"
      @onSuccess="onDone"
    />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer.vue'
import axios from 'axios'
import PaymentsModal from '../subscribe/PaymentsModal.vue'
import { purchaseTypes } from '@/data/constants'
import { deleteSubscription } from '@/services/subscription.service'
import { notification, Modal } from 'ant-design-vue'

export default {
  props: ['user'],
  name: 'Subscription',
  components: { Header, Footer, PaymentsModal },
  data() {
    return {
      subscriptionInfo: {},
      purchaseTypes,
      isOpen: false,
      selectedPackage: purchaseTypes.YEARLY,
    }
  },
  computed: {
    isAnnualPlan() {
      return (
        this.subscriptionInfo.subscription_type &&
        this.subscriptionInfo.subscription_type.includes('Annual')
      )
    },
    isFreeUser() {
      return (
        !this.subscriptionInfo ||
        (this.subscriptionInfo && !this.subscriptionInfo.subscription_via)
      )
    },
  },
  created() {
    if (localStorage.hasOwnProperty('token') != null) {
      axios.get(`user/account-status`).then((response) => {
        this.subscriptionInfo = response
      })
    }
  },
  methods: {
    onUpgrade(p) {
      this.selectedPackage = p
      this.isOpen = true
    },
    onDone() {
      this.isOpen = false
      window.location.reload()
    },
    onCancel() {
      const that = this
      Modal.confirm({
        title: 'Are you sure?',
        okText: 'Yes',
        onOk() {
          return deleteSubscription(
            'cancel',
            that.subscriptionInfo.subscription_via.toLowerCase()
          )
            .then(() => {
              notification.success({ message: 'Successfully canceled!' })
              window.location.reload()
            })
            .catch((err) => {
              // notification.error({ message: err.message })
            })
        },
        onCancel() {},
      })
    },
  },
}
</script>

<style lang="scss">
.settings {
  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 25px;
  }

  &__top-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
    padding-bottom: 72px;
  }

  &__subscription-wrapper {
    max-width: 327px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  &__subscription-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 152px;
  }

  &__subscription-item {
    color: var(--color-white);

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  &__subscription-item-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    padding-bottom: 12px;
  }

  &__subscription-item-value {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }

  &__subscription-button {
    font-weight: 500;
    font-size: 14px;
    height: 52px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &:hover {
      background: rgba(#fff, 0.8);
    }
  }
}
</style>

import { getScreen } from '@/services/screen.service'

const state = () => ({
  home: {},
})

const actions = {
  fetchHome({ commit }) {
    getScreen('home').then((res) => {
      commit('SET_DATA', res)
    })
  },
}

const mutations = {
  SET_DATA(state, data) {
    state.home = data
  },
}

export const screen = {
  namespaced: true,
  state,
  actions,
  mutations,
}

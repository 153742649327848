<template>
  <div class="page-wrapper">
    <Header :user="user" />
    <div class="settings">
      <router-link to="/settings" class="settings__back-link">
        <img
          src="@/static/settings/arrow.svg"
          alt="Arrow back"
          title="Back to profile settings"
        />
      </router-link>
      <div class="settings__container container container--big">
        <div class="settings__top">
          <div class="settings__top-title">Change password</div>
        </div>
        <div class="settings__password-change-wrapper">
          <form
            @submit.prevent="changePassword"
            class="settings__password-change-form"
          >
            <div class="settings__password-change-inputs">
              <div class="settings__password-change-form-input-wrapper">
                <input
                  type="password"
                  class="settings__password-change-form-input with-icon"
                  placeholder="Current password"
                  v-model="current_password"
                />
                <template v-if="status === true">
                  <p
                    class="settings__input-error error-message"
                    v-for="(error, index) in errors.errors.current_password"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </template>
              </div>
              <div class="settings__password-change-form-input-wrapper">
                <input
                  type="password"
                  class="settings__password-change-form-input with-icon"
                  placeholder="New password"
                  v-model="password"
                />
                <template v-if="status === true">
                  <p
                    class="settings__input-error error-message"
                    v-for="(error, index) in errors.errors.password"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </template>
              </div>
            </div>
            <button
              type="submit"
              class="settings__password-change-form-button btn btn--white"
            >
              Update
            </button>
          </form>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from 'axios'
import Header from '@/components/Header'
import Footer from '@/components/Footer.vue'

export default {
  props: ['user'],
  name: 'ChangePassword',
  components: { Header, Footer },
  data() {
    return {
      current_password: '',
      password: '',
      errors: {},
      status: false,
    }
  },
  methods: {
    changePassword() {
      const headers = {
        Accept: 'application/json',
      }

      axios
        .patch(
          `user/password`,
          {
            current_password: this.current_password,
            password: this.password,
          },
          { headers }
        )
        .then((response) => {
          alert('Success update password!')
        })
        .catch((errors) => {
          this.errors = errors
          this.status = true
        })
    },
  },
}
</script>

<style lang="scss">
.settings {
  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 25px;
  }

  &__top-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
    padding-bottom: 72px;
  }

  &__account-title {
    font-family: var(--run);
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: var(--color-white);
    margin-bottom: 70px;
  }

  &__password-change-wrapper {
    max-width: 327px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  &__password-change-form-input-wrapper {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__password-change-form-input {
    font-weight: 500;
    font-size: 14px;
    display: block;
    width: 100%;
    background: transparent;
    text-align: center;
    color: #fff;
    padding: 14px 25px 14px 55px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.1);
    border-radius: 58px;

    &:focus {
      outline: 0;
      border-color: rgba(255, 255, 255, 0.8);

      &::placeholder {
        opacity: 0.3;
      }
    }
    &::placeholder {
      font-weight: 500;
      font-size: 14px;
      color: #fff;
      text-align: center;
    }
  }

  &__password-change-form-button {
    height: 52px;
    margin-top: 152px;
  }
}
</style>

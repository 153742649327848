<template>
  <router-link :to="link">
    <div class="section__item-wrapper">
      <div
        class="section__item-image-wrapper"
        :class="[
          { complete: content.complete },
          { locked: !content.free },
          content.model,
        ]"
      >
        <img
          :src="`${content.cover}`"
          alt="image"
          loading="lazy"
          class="section__item-image"
        />
      </div>
      <div class="section__item-title">
        {{ content.name }}
      </div>
      <div class="section__item-type-wrapper">
        <span class="section__item-type">
          {{ content.type }}
        </span>
        <span class="section__item-duration" v-if="content.duration">
          {{ content.duration }}
        </span>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'CollectionWorkbookCard',
  props: {
    link: [Object, String],
    content: Object,
  },
}
</script>

<style></style>

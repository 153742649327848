<template>
  <div class="checkout-not-so-secret-items">
    <div class="checkout-not-so-secret-items__container container-checkout">
      <ul class="checkout-not-so-secret-items__list">
        <li
          v-for="(item, index) in items"
          :key="index"
          class="checkout-not-so-secret-items__item"
        >
          <div class="checkout-not-so-secret-items__item-left">
            <div
              class="checkout-not-so-secret-items__item-title"
              v-html="item.title"
            ></div>
            <div class="checkout-not-so-secret-items__item-description">
              {{ item.description }}
            </div>
          </div>
          <div class="checkout-not-so-secret-items__item-right">
            <img
              :src="require(`@/static/checkout/items/${item.image_src}`)"
              alt="image"
              class="checkout-not-so-secret-items__item-img"
            />
            <div class="checkout-not-so-secret-items__item-value">
              Total Value: <span>{{ item.value }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutNotSoSecretItems',
  data() {
    return {
      items: [
        {
          title:
            "Meditation <span style='font-weight: 400; font-weight: 400;'>(Beginner)</span>",
          description:
            'How do you learn to meditate? With Mental Future you will learn how to pay attention to the breath as it goes in and out, and notice when the mind wanders from this task. This practice of returning to the breath builds the muscles of attention and mindfulness.',
          image_src: '1.png',
          value: '$97.86',
        },
        {
          title:
            "Meditation <span style='font-weight: 400; font-weight: 400;'>(Intermediate)</span>",
          description:
            'Intermediate meditations will help you deepen your practice to reach an entirely new level of total relaxation and inner peace.',
          image_src: '2.png',
          value: '$97.86',
        },
        {
          title: 'Anxiety Management',
          description:
            'Meditation is an active training of the mind to increase awareness. Understanding anxiety is the first step in managing it. By obtaining a better sense of triggering situations and how your anxiety operates you can reprogram your mind and learn how to regulate your emotions.',
          image_src: '3.png',
          value: '$97.86',
        },
        {
          title: 'Stress Management',
          description:
            'Meditation for stress is a powerful tool , which allows you to increase your awareness levels and presence, and cultivate stronger emotional control and self-regulation. Stress begins in the mind. While meditating you bring your attention to the present moment and learn to control your negative thinking patterns.',
          image_src: '4.png',
          value: '$97.86',
        },
        {
          title: 'Deeper Sleep',
          description:
            'Healthy sleep is more connected to the quality of rest than quantity of hours. Sleep meditations and sleepcasts increase relaxation and help create the inner conditions needed for a truly restful night. Because when you settle the mind, you rest the body — and that restfulness is what makes it easier to wind down and drift off.',
          image_src: '5.png',
          value: '$97.86',
        },
        {
          title: 'Overcoming Fear<br/> Of Failure',
          description:
            'Мeditations for overcoming the fear of failure help you to get in touch with the energy of the fear and to clear all painful memories and emotions that keep the fear active in your system, thereby clearing your subconscious mind of thoughts that limit your personal experience of inner peace, love, joy and happiness in life.',
          image_src: '6.png',
          value: '$97.86',
        },
        {
          title: 'Guided Meditation',
          description:
            'Guided meditation is an intentional, focused meditation technique that is very useful for achieving a specific purpose, such as deep relaxation, behavior change, or releasing negative emotions and self-limiting beliefs. The primary difference between guided meditation and other forms of meditation is that, instead of working to clear the mind, guided meditation allows the subconscious mind to follow the words and visualizations provided.',
          image_src: '7.png',
          value: '$97.86',
        },
        {
          title: 'Meditation Library',
          description:
            'Explore the massive meditation library of Mental Future. Building upon the proven techniques from experts all around the world you will find various meditation techniques tackling a wide range of problems such as stress, sleeplessness, self-love, self-worth, motivation, depression and many more.',
          image_src: '8.png',
          value: '$97.86',
        },
        {
          title: 'Breathing Techniques',
          description:
            'Learn breathing exercises and patterns aiming to reduce anxiety and/or to help you sleep better. Deep breathing exercises can have a profound effect on your state of mind, as well as improve the quality of your meditation practice.',
          image_src: '9.png',
          value: '$97.86',
        },
        {
          title: 'Cognitive Behavioral Therapy (CBT)',
          description:
            'CBT is based on the concept that your thoughts, feelings, physical sensations and actions are interconnected, and that negative thoughts and feelings can trap you in a vicious cycle. CBT aims to help you deal with overwhelming problems in a more positive way by breaking them down into smaller parts.',
          image_src: '10.png',
          value: '$97.86',
        },
        {
          title: 'Master Of Letting Go',
          description:
            'Mental attachments can weigh you down. Through meditation you can let go of attachments and relax. Regular practice helps the mind accept the reality of the experience without the need to find comfort and happiness from external things.',
          image_src: '11.png',
          value: '$97.86',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.checkout-not-so-secret-items {
  padding-top: 72px;
  padding-bottom: 100px;

  &__container {
    max-width: 1268px;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex: 0 1 calc(50% - 16px);
    max-width: calc(50% - 16px);

    &:not(:last-child) {
      margin-bottom: 32px;
    }

    &:nth-child(even) {
      flex-direction: row-reverse;
      transform: translateY(140px);
    }
  }

  &__item-left,
  &__item-right {
    flex: 0 1 calc(50% - 16px);
    max-width: calc(50% - 16px);
  }

  &__item-title {
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 8px;
  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  &__item-right {
    border-radius: 12px;
    overflow: hidden;
  }

  &__item-img {
    width: 284px;
    height: 284px;
    object-fit: cover;
  }

  &__item-value {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    padding: 16px 5px;
    text-align: center;
    background-color: var(--color-white-01);

    span {
      font-weight: 800;
      text-decoration: line-through;
    }
  }
}
</style>

import { createStore } from 'vuex'
import { player } from './modules/player'
import { screen } from './modules/screen'
import { gist } from './modules/gist'
import { packageModule } from './modules/package'
import { note } from './modules/note'
import { error } from './modules/error'
import { recommendation } from './modules/recommendation'
import { quiz } from './modules/quiz'
import { subscription } from './modules/subscription'
import { workbook } from './modules/workbook'
import { checkout } from './modules/checkout'
import { userStatus } from '@/services/user.service'
import { faq } from './modules/faq'
import axios from 'axios'

const token = localStorage.getItem('t')

export default createStore({
  state: {
    token,
    user: {},
    isUserLoading: false,
    userStatus: {},
    isUserStatusLoading: false,
  },
  mutations: {
    SET_TOKEN(state, data) {
      state.token = data
      localStorage.setItem('t', data)
    },
    REMOVE_TOKEN(state) {
      state.token = ''
      localStorage.removeItem('t')
    },
    SET_USER_LOADING(state, data) {
      state.isUserLoading = data
    },
    SET_USER(state, data) {
      state.user = data
    },
    SET_USER_STATUS(state, data) {
      state.userStatus = data
    },
    SET_USER_STATUS_LOADING(state, data) {
      state.isUserStatusLoading = data
    },
  },
  actions: {
    fetchUser({ commit }) {
      commit('SET_USER_LOADING', true)
      return axios
        .get('user')
        .then((response) => {
          commit('SET_USER', response)
          return response
        })
        .catch((err) => console.log(err))
        .finally(() => {
          commit('SET_USER_LOADING', false)
        })
    },
    getUserStatus({ commit }) {
      commit('SET_USER_STATUS_LOADING', true)
      return userStatus()
        .then((res) => {
          commit('SET_USER_STATUS', res)
        })
        .catch((err) => console.log(err))
        .finally(() => commit('SET_USER_STATUS_LOADING', false))
    },
  },
  getters: {
    getUserGoal(state) {
      return state.user.goal
    },
  },
  modules: {
    player,
    screen,
    gist,
    packageModule,
    note,
    error,
    recommendation,
    quiz,
    subscription,
    workbook,
    checkout,
    faq,
  },
})

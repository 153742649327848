<template>
  <div class="page-wrapper theme-green-forest">
    <Header />
    <MusicCollection />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import MusicCollection from '@/layouts/MusicCollection.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Music',
  components: { Header, MusicCollection, Footer },
}
</script>
<style lang="scss" scoped>
.title-container {
  padding: 0 36px;
}
</style>

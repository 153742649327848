import { purchaseTypes } from '@/data/constants'

function roundDownIfInteger(number) {
  if (Number.isInteger(number) || number % 1 === 0) {
    return number - 0.01
  } else {
    return number
  }
}

export const cardCalculate = (card) => {
  const packagePlan = () => {
    if (card.is_lifetime) {
      return purchaseTypes.LIFETIME
    }
    if (card.months === 12) {
      return purchaseTypes.YEARLY
    }
    if (card.months === 1) {
      return purchaseTypes.MONTHLY
    }
  }

  const packageTitle = () => {
    if (card.is_lifetime) {
      return 'Lifetime'
    }
    if (card.is_free_trial) {
      return 'Free trial'
    }
    if (card.months === 12) {
      return '1-year plan'
    }
    if (card.months === 1) {
      return '1-Month Plan'
    }
    if (card.months > 1 && card.months < 12) {
      return `${card.months}-MONTHS PLAN`
    }
  }

  const packageDesc = () => {
    if (card.is_lifetime) {
      return 'One-time purchase'
    }
    if (card.months === 12) {
      return `Billed every 12 months`
    }
    if (card.months === 1) {
      return 'Billed every 1 month'
    }
    if (card.months > 1 && card.months < 12) {
      return `Billed every ${card.months} months`
    }
  }

  const packageOldPerDayPrice = () => {
    if (card.is_lifetime) {
      return ''
    }
    if (card.is_free_trial) {
      return ((card.amount * 2) / 360).toFixed(2)
    }
    if (card.months === 12) {
      return ((card.amount * 2) / 360).toFixed(2)
    }
    if (card.months === 1) {
      return ((card.amount * 2) / 30).toFixed(2)
    }
    if (card.months === 3) {
      return ((card.amount * 2) / 90).toFixed(2)
    }
    if (card.months === 6) {
      return ((card.amount * 2) / 180).toFixed(2)
    }
  }

  const packagPerDayPrice = () => {
    if (card.is_lifetime) {
      return card.amount
    }
    if (card.is_free_trial) {
      const amount = (card.amount / 360).toFixed(2)
      return roundDownIfInteger(amount)
    }
    if (card.months === 12) {
      const amount = (card.amount / 360).toFixed(2)
      return roundDownIfInteger(amount)
    }
    if (card.months === 1) {
      const amount = (card.amount / 30).toFixed(2)
      return roundDownIfInteger(amount)
    }
    if (card.months === 3) {
      const amount = (card.amount / 90).toFixed(2)
      return roundDownIfInteger(amount)
    }
    if (card.months === 6) {
      const amount = (card.amount / 180).toFixed(2)
      return roundDownIfInteger(amount)
    }
  }

  return {
    packagePlan: packagePlan(),
    title: packageTitle(),
    desc: packageDesc(),
    oldPricePerDay: packageOldPerDayPrice(),
    newPricePerDay: packagPerDayPrice(),
  }
}

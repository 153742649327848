const state = () => ({
  isOpen: false,
  action: '',
  isBraintreeCard: false,
})

const mutations = {
  SET_OPEN(state, { isOpen = false, action = '' }) {
    state.isOpen = isOpen
    state.action = action
  },
  SET_BRAINTREE_CARD(state, data) {
    state.isBraintreeCard = data
  },
}

export const subscription = {
  namespaced: true,
  state,
  mutations,
}

<template>
  <section
    class="coming-soon"
    :data-theme="`${comingSoonDataPage.data.color_theme}`"
  >
    <div class="coming-soon__container">
      <header class="coming-soon__header">
        <a href="/" class="coming-soon__logo">
          <img
            v-if="comingSoonDataPage.data.color_theme === 'white'"
            src="@/static/coming-soon/logo-dark.svg"
            alt="logo"
          />
          <img
            v-else-if="comingSoonDataPage.data.color_theme === 'green'"
            src="@/static/coming-soon/logo-white.svg"
            alt="logo"
          />
        </a>
      </header>
      <div class="coming-soon__wrapper">
        <div class="coming-soon__left">
          <h1 class="coming-soon__title">
            {{ comingSoonDataPage.data.funnel_title }}
          </h1>
          <div class="coming-soon__image">
            <img src="@/static/coming-soon/image.png" alt="image" />
          </div>
          <p class="coming-soon__description">
            {{ comingSoonDataPage.data.funnel_description }}
          </p>
          <div
            class="coming-soon__pre-order"
            v-bind:class="{
              disabled:
                comingSoonDataPage.data.app_store_icon === 'coming-soon',
            }"
            v-if="comingSoonDataPage.data.app_store_icon !== 'none'"
          >
            <a
              href="#"
              class="coming-soon__pre-order-item coming-soon__pre-order-item--apple"
            >
              <img
                src="@/static/coming-soon/apple.svg"
                alt="logo"
                title="App Store"
              />
            </a>
            <a
              href="#"
              class="coming-soon__pre-order-item coming-soon__pre-order-item--gplay"
            >
              <img
                src="@/static/coming-soon/gplay.svg"
                alt="logo"
                title="Google Play"
              />
            </a>
          </div>
          <div
            v-if="comingSoonDataPage.data.color_theme === 'white'"
            class="coming-soon__arrow"
          >
            ​<picture>
              <source
                srcset="@/static/coming-soon/arrow-small-dark.svg"
                media="(max-width: 1023px)"
              />
              <img
                src="@/static/coming-soon/arrow-big-dark.svg"
                alt="arrow"
                title="Arrow"
              />
            </picture>
          </div>
          <div
            v-else-if="comingSoonDataPage.data.color_theme === 'green'"
            class="coming-soon__arrow"
          >
            ​<picture>
              <source
                srcset="@/static/coming-soon/arrow-small-white.svg"
                media="(max-width: 1023px)"
              />
              <img
                src="@/static/coming-soon/arrow-big-white.svg"
                alt="arrow"
                title="Arrow"
              />
            </picture>
          </div>
        </div>
        <div class="coming-soon__right">
          <div class="coming-soon__form-wrapper">
            <div class="coming-soon__form-title">
              {{ comingSoonDataPage.data.funnel_material_title }}
            </div>
            <div class="coming-soon__form-image">
              <img
                :src="`${comingSoonDataPage.data.material_image}`"
                alt="image"
              />
            </div>
            <form
              @submit.prevent="comingSoonFormSubmit"
              class="coming-soon__form coming-soon-form"
            >
              <div class="coming-soon-form__group firstname">
                <input
                  type="text"
                  name="firstName"
                  id="first-name"
                  placeholder="First Name"
                  v-model="formFirstName"
                />
                <p class="coming-soon-form__group-error" v-if="status === true">
                  {{ errors.errors.first_name[0] }}
                </p>
              </div>
              <div class="coming-soon-form__group lastname">
                <input
                  type="text"
                  name="lastName"
                  id="last-name"
                  placeholder="Last Name"
                  v-model="formLastName"
                />
                <p class="coming-soon-form__group-error" v-if="status === true">
                  {{ errors.errors.last_name[0] }}
                </p>
              </div>
              <div class="coming-soon-form__group email">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email Adress"
                  v-model="formEmail"
                />
                <p class="coming-soon-form__group-error" v-if="status === true">
                  {{ errors.errors.email[0] }}
                </p>
              </div>
              <div
                class="coming-soon-form__group phone"
                v-if="comingSoonDataPage.data.opt_in_form === false"
              >
                <input
                  type="phone"
                  name="phone"
                  id="phone"
                  placeholder="Phone"
                />
              </div>
              <div class="coming-soon-form__group privacy-policy">
                <input
                  type="checkbox"
                  name="privacyPolicy"
                  id="privacy-policy"
                  required
                />
                <label for="privacy-policy">
                  {{ formPrivacyPolicy.text }}
                </label>
              </div>
              <div class="coming-soon-form__group sign-up">
                <button type="submit" class="coming-soon-form__btn btn-reset">
                  Sign up
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer class="coming-soon__footer coming-soon-footer">
        <ul class="coming-soon-footer__list">
          <li class="coming-soon-footer__list-item">
            <a href="#" class="coming-soon-footer__list-item-link">
              Terms Of Service
            </a>
          </li>
          <li class="coming-soon-footer__list-item">
            <a href="#" class="coming-soon-footer__list-item-link">
              Privacy Policy
            </a>
          </li>
        </ul>
        <p class="coming-soon-footer__copyright">
          2022 Mental Future. All Rights Reserved.
        </p>
      </footer>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ComingSoon',
  data() {
    return {
      comingSoonDataPage: {},
      formPrivacyPolicy: {
        text: 'I agree to Mental Future’s Terms of Service & Privacy Policy. I also agree to receive emails from Mental Future and understand that I may opt out of Mental Future’s subscription at any time.',
      },
      formFirstName: '',
      formLastName: '',
      formEmail: '',
      formUserId: '',
      errors: {},
      status: false,
    }
  },
  created() {
    const headers = {
      Accept: 'application/json',
    }
    const key =
      typeof this.$route.params.key !== 'undefined'
        ? this.$route.params.key
        : ''

    axios
      .get(`${process.env.VUE_APP_BASE_URL}coming-soon/` + key, {
        headers,
      })
      .then((response) => {
        const data = response.data

        localStorage.setItem('comingSoonDataPage', JSON.stringify(data))
        this.comingSoonDataPage = data
        this.formUserId = data.user_id
      })
      .catch((error) => {
        if (error.response.status === 429) {
          const dataStorage = localStorage.getItem('comingSoonDataPage')
          if (dataStorage) {
            this.comingSoonDataPage = JSON.parse(dataStorage)
          } else {
            // redirect 404
          }
        }
      })
  },
  methods: {
    comingSoonFormSubmit() {
      const headers = {
        Accept: 'application/json',
      }

      axios
        .post(
          `${process.env.VUE_APP_BASE_URL}coming-soon/lead/`,
          {
            first_name: this.formFirstName,
            last_name: this.formLastName,
            email: this.formEmail,
            user_id: this.formUserId,
          },
          { headers }
        )
        .then((response) => {})
        .catch((error) => {
          this.errors = error.response.data
          this.status = true
        })
    },
  },
}
</script>

<style lang="scss">
[data-theme='white'] {
  --box-shadow-form: 0px 6px 20px rgba(47, 53, 66, 0.12);

  &.coming-soon {
    background: var(--color-white);
  }
}

[data-theme='green'] {
  &.coming-soon {
    background: var(--theme-green-forest);
  }
}

.coming-soon {
  min-height: 100vh;

  &__logo {
    @media (max-width: 767px) {
      img {
        height: 24px;
      }
    }
  }

  &__container {
    max-width: 1440px;
    min-height: 680px;
    width: 100%;
    padding: 36px 35px;
    margin-right: auto;
    margin-left: auto;
  }

  &__wrapper {
    display: flex;

    @media (max-width: 1023px) {
      flex-wrap: wrap;
      margin-bottom: 32px;
    }

    @media (min-width: 1024px) {
      justify-content: space-between;
      margin-bottom: 65px;
    }
  }

  &__left {
    position: relative;

    [data-theme='green'] & {
      --main-color: var(--color-white);
    }

    [data-theme='white'] & {
      --main-color: var(--color-dark);
    }
  }

  &__right {
    [data-theme='green'] & {
      --main-color: var(--color-main);
    }

    [data-theme='white'] & {
      --main-color: var(--color-dark);
    }

    @media (max-width: 1023px) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__left,
  &__right {
    & * {
      color: var(--main-color);
    }

    @media (min-width: 1024px) {
      width: calc(50% - 45px);
    }
  }

  &__title {
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    max-width: 600px;
    margin: 0;
    margin-top: 40px;
    margin-bottom: 40px;

    @media (max-width: 1023px) {
      text-align: center;
      margin-right: auto;
      margin-left: auto;
    }

    @media (min-width: 768px) {
      font-size: 52px;
      line-height: 60px;
    }
  }

  &__image {
    margin-bottom: 20px;

    @media (max-width: 1023px) {
      display: flex;
      justify-content: center;
    }
  }

  &__description {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 40px;

    @media (max-width: 1023px) {
      text-align: center;
    }

    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  &__pre-order {
    display: flex;

    @media (max-width: 1023px) {
      justify-content: center;
      margin-bottom: 24px;
    }
  }

  &__pre-order-item {
    &:not(:last-child) {
      margin-right: 42px;
    }
  }

  &__form-wrapper {
    background-color: var(--color-white);
    padding: 32px;
    max-width: 641px;
    border-radius: 12px;
    box-shadow: var(--box-shadow-form);

    @media (max-width: 1023px) {
      padding: 40px 20px;
    }
  }

  &__form-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    max-width: 475px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 28px;

    @media (min-width: 768px) {
      font-size: 36px;
      line-height: 48px;
      margin-bottom: 32px;
    }
  }

  &__form-image {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
  }

  &__arrow {
    @media (max-width: 1023px) {
      display: flex;
      justify-content: center;
      margin-bottom: 36px;
    }

    @media (min-width: 1024px) {
      position: absolute;
      right: -55px;
      top: 40px;
    }
  }
}
.coming-soon-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__group {
    &.firstname,
    &.lastname {
      width: 100%;
      margin-bottom: 20px;

      @media (min-width: 768px) {
        width: calc(50% - 10px);
      }
    }

    &.email {
      margin-bottom: 20px;
    }

    &.email,
    &.phone {
      width: 100%;
    }

    &.privacy-policy {
      display: flex;
      margin-top: 40px;
      margin-bottom: 40px;

      input {
        margin: 0;
        margin-top: 5px;
      }

      label {
        font-weight: 500;
        margin-left: 12px;
      }
    }

    &.sign-up {
      width: 100%;
    }

    input:not([type='checkbox']) {
      font-weight: 500;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      border: 1px solid rgba(19, 78, 94, 0.2);
      padding: 13px 24px;
      border-radius: 58px;
      width: 100%;

      @media (min-width: 768px) {
        padding: 17px 24px;
      }

      &::placeholder {
        color: currentColor;
        font-size: inherit;
        font-weight: inherit;
      }

      &:focus {
        outline-color: var(--color-main);
      }
    }
  }

  &__group-error {
    padding-left: 5px;
    padding-top: 5px;
  }

  &__btn {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 52px;
    border-radius: 58px;
    cursor: pointer;

    @media (min-width: 768px) {
      height: 60px;
    }

    [data-theme='green'] & {
      background-color: var(--color-green);
    }

    [data-theme='white'] & {
      background-color: var(--color-red);
    }
  }
}
.coming-soon-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 1023px) {
    flex-wrap: wrap;
  }

  [data-theme='green'] & {
    color: var(--color-white);
  }

  [data-theme='white'] & {
    color: var(--color-dark);
  }

  &__list {
    display: flex;

    @media (max-width: 1023px) {
      justify-content: center;
      width: 100%;
      margin-bottom: 32px;
    }
  }

  &__list-item {
    &:not(:last-child) {
      margin-right: 56px;
    }
  }

  &__copyright {
    @media (max-width: 1023px) {
      width: 100%;
      text-align: center;
    }
  }
}
</style>

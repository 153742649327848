import axios from 'axios'
import { getWebpHead } from '../utils/helpers'

export const getRecentlyPlayed = async () => {
  return await axios.get(`recently-played`, { headers: getWebpHead() })
}

export const getRecommendation = async () => {
  return await axios.get(`recommendation`, { headers: getWebpHead() })
}

export const getPersonalPlan = async (query) => {
  return await axios.get(`personal-plan`, { params: query })
}

export const getPersonalPlanByDay = async (query) => {
  return await axios.get(`personal-plan-by-day`, { params: query })
}

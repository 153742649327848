<template>
  <div class="checkout-weapong-against">
    <div class="checkout-weapong-against__wrapper">
      <div class="checkout-weapong-against__title" v-html="title"></div>
      <checkout-white-get-started-now />
      <checkout-white-timer :title="'Available only for'" />
      <div class="checkout-weapong-against__subtitle" v-html="subtitle"></div>
    </div>
  </div>
</template>

<script>
import CheckoutWhiteGetStartedNow from './CheckoutWhiteGetStartedNow.vue'
import CheckoutWhiteTimer from './CheckoutWhiteTimer.vue'

export default {
  name: 'CheckoutWeaponAgainst',
  components: {
    CheckoutWhiteGetStartedNow,
    CheckoutWhiteTimer,
  },
  data() {
    return {
      title: 'Are You Ready <b>To Start</b> Your Journey?',
      subtitle:
        'These Techniques Might Be <b>Your Greatest Weapon Against...</b>',
    }
  },
}
</script>

<style lang="scss" scoped>
.checkout-weapong-against {
  padding-bottom: 100px;

  &__wrapper {
    position: relative;
    padding: 72px 15px 0;
  }

  &__title,
  &__subtitle {
    text-align: center;
  }

  &__title {
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
    padding-bottom: 52px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 56px;
    line-height: 56px;
    max-width: 865px;
    margin-inline: auto;

    b {
      font-weight: 800;
    }
  }

  &__arrow {
    position: absolute;
    bottom: -48px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background-color: var(--color-white);
  }
}
</style>

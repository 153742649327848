<template>
  <div class="checkout-comentar">
    <div class="checkout-comentar__wrapper">
      <img
        src="@/assets/img/carlos-perez.png"
        alt="Avatar"
        class="checkout-comentar__avatar"
      />
      <div class="checkout-comentar__right">
        <div class="checkout-comentar__text">{{ text }},</div>
        <div class="checkout-comentar__bottom">
          <div class="checkout-comentar__name">{{ name }},</div>
          <div class="checkout-comentar__profesion">
            {{ profesion }}
          </div>
        </div>
      </div>
      <div class="checkout-comentar__tilda">
        <img
          src="@/static/checkout-white/icons/tilda-white.svg"
          alt="arrow"
          class="checkout-comentar__tilda-icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutComentar',
  data() {
    return {
      text: 'I really want to thank you from the bottom of my heart for helping me out with this situation in my life. Your techniques are heaven sent!!! It is truly a miracle that you promised.',
      name: 'Carlos Perez',
      profesion: 'Designer',
    }
  },
}
</script>

<style lang="scss" scoped>
.checkout-comentar {
  padding-top: 144px;
  padding-bottom: 144px;

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding: 60px 52px;
    background-color: var(--color-dark);
    border-radius: 12px;
    color: var(--color-white);

    &:after {
      content: '';
      position: absolute;
      top: 50px;
      right: 40px;
      background-image: url('~@/static/checkout-white/icons/tilda-white-big.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 112px;
      height: 75px;
    }
  }

  &__avatar {
    max-width: 400px;
    margin-right: 51px;
  }

  &__text {
    font-weight: 300;
    font-size: 24px;
    line-height: 33px;
    padding-bottom: 48px;
    max-width: 606px;

    &:after,
    &:before {
      content: '"';
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  &__name {
    padding-right: 4px;
  }

  &__tilda {
    position: absolute;
    top: -48px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background-color: var(--color-yellow);
  }
}
</style>

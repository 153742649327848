import axios from 'axios'
import { getWebpHead } from '../utils/helpers'

const prefix = '/music-soundscape'

export const getAllMusicSoundscape = async (params) => {
  return await axios.get(`${prefix}`, { params, headers: getWebpHead() })
}

export const getMusicSoundscape = async (id) => {
  return await axios.get(`${prefix}/${id}`, { headers: getWebpHead() })
}

export const getMusicSoundscapePlaylist = async (id) => {
  return await axios.get(`${prefix}-playlist/${id}`, { headers: getWebpHead() })
}

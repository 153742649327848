<template>
  <div
    class="page-wrapper">
    <Header :user="user" />
    <section class="collection">
      <ul class="collection__items">
        <template v-for="(content, index) in collection.content" :key="index">
          <li class="collection__item" v-if="content.free == true">
            <router-link :to="`/${content.model}/${content.id}`">
              <div class="collection__item-wrapper">
                <div
                  class="collection__item-image-wrapper"
                  :class="content.model"
                >
                  <img
                    :src="`${content.cover}`"
                    alt="image"
                    loading="lazy"
                    class="collection__item-image"
                  />
                </div>
                <div class="collection__item-title">
                  {{ content.name }}
                </div>
              </div>
            </router-link>
          </li>
        </template>
      </ul>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios'

export default {
  name: 'Collection',
  components: { Header, Footer },
  props: ['user'],
  data() {
    return {
      collection: {},
    }
  },
  created() {
    const thisId = this.$route.params.id
    axios.get('collection/' + thisId).then((response) => {
      this.collection = response
    })
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/collection';
</style>

export async function initFacebook() {
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
      cookie: true,
      version: 'v13.0',
    })
  }
}
export async function loadFacebookSDK(d, s, id) {
  var js,
    fjs = d.getElementsByTagName(s)[0]
  if (d.getElementById(id)) {
    return
  }
  js = d.createElement(s)
  js.id = id
  js.src = 'https://connect.facebook.net/en_US/sdk.js'
  fjs.parentNode.insertBefore(js, fjs)
}

export async function loadAppleJs() {
  const id = 'apple'
  let fjs = document.getElementsByTagName('script')[0]
  if (document.getElementById('apple')) {
    return
  }

  let js = document.createElement('script')
  js.id = id
  js.src =
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
  fjs.parentNode.insertBefore(js, fjs)
}

export function loadFacebookPixel(event) {
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }
    if (!f._fbq) f._fbq = n
    n.push = n
    n.loaded = !0
    n.version = '2.0'
    n.queue = []
    t = b.createElement(e)
    t.async = !0
    t.src = v
    s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  )
  fbq('init', '1313411942792165')
  fbq('init', '474469394789696')
  fbq('set', 'autoConfig', false, '1313411942792165')
  fbq('set', 'autoConfig', false, '474469394789696')
  if (event) {
    fbq('trackCustom', event)
  }
}

import axios from 'axios'

const prefix = '/user/notes'

export const getNotes = async ({ page = 1, per_page = 20 } = {}) => {
  return await axios.get(`${prefix}?page=${page}&per_page=${per_page}`)
}

export const getNote = async (id) => {
  return await axios.get(`${prefix}/${id}`)
}

export const createNote = async (data) => {
  return await axios.post(`${prefix}`, data)
}

export const deleteNote = async (id) => {
  return await axios.delete(`${prefix}/${id}`)
}

<template>
  <div class="page-wrapper">
    <Header :user="user" />
    <hero />
    <types />
    <seen />
    <how-it-works />
    <no-stress />
    <unique />
    <benefits />
    <reviews />
    <start />
    <footer-global />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Hero from '@/components/views/Welcome/Hero'
import Types from '@/components/views/Welcome/Types.vue'
import Seen from '@/components/views/Welcome/Seen.vue'
import HowItWorks from '@/components/views/Welcome/HowItWorks.vue'
import NoStress from '@/components/views/Welcome/NoStress.vue'
import Unique from '@/components/views/Welcome/Unique.vue'
import Benefits from '@/components/views/Welcome/Benefits.vue'
import Reviews from '@/components/views/Welcome/Reviews.vue'
import Start from '@/components/views/Welcome/Start.vue'
import FooterGlobal from '@/components/FooterGlobal.vue'
import { message, notification } from 'ant-design-vue'

export default {
  props: ['user'],
  name: 'Welcome',
  components: {
    Header,
    Hero,
    Types,
    Seen,
    HowItWorks,
    NoStress,
    Unique,
    Benefits,
    Reviews,
    Start,
    Unique,
    FooterGlobal,
  },
  created() {
    if (this.$route.query.message)
      notification.info({ message: this.$route.query.message })
  },
}
</script>

<style lang="scss">
.page-wrapper {
  background-size: cover;
  background-repeat: no-repeat;
}
</style>

<template>
  <template v-if="settings">
    <div class="page-wrapper">
      <Header />
      <div class="settings">
        <button @click="$router.back()" class="settings__back-link btn-reset">
          <img
            src="@/static/settings/arrow.svg"
            alt="Arrow back"
            title="Back to profile settings"
          />
        </button>
        <div class="settings__container container container--big">
          <div class="settings__top">
            <div class="settings__top-title">Settings</div>
          </div>

          <div class="settings__links">
            <div
              v-for="item in settings.settings_links"
              :key="item"
              class="settings__link-wrapper"
            >
              <router-link :to="`${item.path}`" class="settings__link">
                <img
                  :src="require(`@/static${item.icon}`)"
                  class="settings__link-icon icon-link"
                  alt="icon"
                />
                <span class="settings__link-title icon-link">
                  {{ item.title }}
                </span>
              </router-link>
            </div>
            <div class="settings__link-wrapper">
              <button
                class="settings__link settings__link--logout"
                @click="logOut()"
              >
                <img
                  src="@/static/settings/log-out.svg"
                  class="settings__link-icon icon-link"
                  alt="icon"
                />
                <span class="settings__link-title icon-link"> Log Out </span>
              </button>
            </div>
          </div>
          <div class="settings__bottom">
            <div class="settings__web-version">Version 1.00 (2345)</div>
            <div class="settings__login-email">
              Logged in as {{ user.email }}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </template>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer.vue'
import json from '@/data/settings.json'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'Welcome',
  components: { Header, Footer },
  data() {
    return {
      settings: json,
      // userInfo: user.data
    }
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    ...mapMutations(['REMOVE_TOKEN']),
    logOut() {
      this.REMOVE_TOKEN()
      localStorage.clear()
      window.location.href = '/'
    },
  },
}
</script>

<style lang="scss">
.settings {
  flex: 1 0 auto;

  & + .footer {
    @media (max-width: 768px) {
      display: none;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 25px;
  }

  &__back-link {
    position: absolute;
    top: 25px;
    left: 36px;
    z-index: 1;

    @media (max-width: 991px) {
      left: 24px;
    }
  }

  &__top-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
    padding-bottom: 72px;
  }

  &__links {
    max-width: 170px;

    @media (min-width: 769px) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__link-wrapper {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 170px;

    &--logout {
      background: transparent;
      border: 0;
      padding: 0;
      cursor: pointer;
    }
  }

  &__link-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: left;
    color: var(--color-white);
    flex: 1 1 calc(100% - 40px);
    max-width: calc(100% - 40px);
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 36px;
    padding-bottom: 24px;

    @media (min-width: 769px) {
      padding-top: 72px;
      padding-bottom: 32px;
    }
  }

  &__web-version,
  &__login-email {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-white);
  }

  &__theme-wrapper {
    display: flex;
    justify-content: space-between;
    row-gap: 15px;
    flex-wrap: wrap;
    flex: 1 1 327px;
    max-width: 327px;
    margin-right: auto;
    margin-left: auto;
  }

  &__theme-item {
    position: relative;
    flex: 1 1 calc(50% - 7.5px);
    max-width: calc(50% - 7.5px);
    height: 293px;
    padding: 12px 12px 12px 12px;
  }

  &__theme-item-image {
    position: absolute;
    inset: 0;
  }

  &__theme-item-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    cursor: pointer;

    &::before {
      order: 1;
      margin-top: 3px;
    }

    &::after {
      left: unset;
      right: 3px;
    }
  }

  &__terms-conditions-wrapper,
  &__privacy-policy-wrapper {
    max-width: 726px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  &__terms-conditions-text,
  &__privacy-policy-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
</style>

import axios from 'axios'

const prefix = '/braintree'
const paypal = '/paypal'

export const getSetup = async () => {
  return await axios.get(`${prefix}/setup-intent`)
}

export const subscription = async (data) => {
  return await axios.put(`${prefix}/subscription`, data)
}

export const getPaypal = async ({
  package_id,
  timezone = 'Europe/Kiev',
  checkout_id,
  promo_checkout_id,
}) => {
  return await axios.get(`${paypal}/subscription`, {
    params: { package_id, timezone, checkout_id, promo_checkout_id },
  })
}

export const successTransaction = async (data) => {
  return await axios.post(`${paypal}/success-transaction`, data)
}

export const cancelTransaction = async () => {
  return await axios.get(`${paypal}/cancel-transaction`)
}

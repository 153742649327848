<template>
  <div class="checkout-news">
    <div class="checkout-news__container container-checkout">
      <div class="checkout-news__wrapper">
        <div class="checkout-news__title">
          {{ title }}
        </div>
        <div class="checkout-news__subtitles">
          <div
            v-for="(item, index) in subtitles"
            :key="index"
            class="checkout-news__subtitle"
            v-html="item.subtitle"
          ></div>
        </div>
        <div class="checkout-news__text">
          {{ text }}
        </div>
        <div class="checkout-news__arrow">
          <img
            src="@/static/checkout/icons/arrow.svg"
            alt="arrow"
            class="checkout-news__arrow-icon"
          />
        </div>
      </div>
      <div class="checkout-news__discover" v-html="discover"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutNews',
  data() {
    return {
      title: 'We have good news for you.',
      subtitles: [
        {
          subtitle: 'Discover the unique approach to reach your mental goals.',
        },
        {
          subtitle:
            'There is a reliable, and simple, path to go from one state to the other. It’s meditation and cognitive behavioral therapy!',
        },
      ],
      text: 'Imagine what it would feel like to have greater control over your mind and life.To know how to always feel calm, centered, and focused no matter what. To be able to let go of things, overcome negative mental states, and master the inner chatter. If you want to experience stress relief, a creativity boost, a tranquil atmosphere in your home, or simply to enjoy deep relaxation, choose Mental Future and start your journey towards recovery.',
      discover:
        'DISCOVER HOW THE <span class="black">MENTAL FUTURE TRIANGLE</span> IMPROVES YOUR MIND AND LONG-TERM HEALTH AT THE SAME TIME',
    }
  },
}
</script>

<style lang="scss">
.checkout-news {
  text-align: center;
  padding-bottom: 100px;

  &__wrapper {
    position: relative;
    padding: 84px 24px 72px;
    margin-bottom: 24px;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.1);
  }

  &__title {
    font-weight: 800;
    font-size: 48px;
    line-height: 52px;
    padding-bottom: 48px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    max-width: 868px;
    margin: auto;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    padding-top: 52px;
    max-width: 1044px;
    margin-inline: auto;
  }

  &__arrow {
    position: absolute;
    top: -48px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background-color: var(--color-white);
  }

  &__discover {
    font-family: var(--nunito-regular);
    font-size: 36px;
    line-height: 49px;
    padding: 48px 52px;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.1);

    .black {
      font-family: var(--nunito-black);
    }
  }
}
</style>

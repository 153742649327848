<template>
  <footer class="checkout-footer">
    <div class="checkout-footer__container">
      <div class="checkout-footer__copy">
        2022 Mental Future. All Rights Reserved.
      </div>
      <div class="checkout-footer__items">
        <router-link to="#" class="checkout-footer__item">
          General Terms
        </router-link>
        <lrouter-link to="#" class="checkout-footer__item">
          Privacy Policy
        </lrouter-link>
      </div>
      <div class="checkout-footer__social">
        <router-link to="#" class="checkout-footer__social-item">
          <img src="@/static/checkout/facebook.svg" alt="facebook" svg-inlibe />
        </router-link>
        <router-link to="#" class="checkout-footer__social-item">
          <img
            src="@/static/checkout/instagram.svg"
            alt="facebook"
            svg-inlibe
          />
        </router-link>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'CheckoutFooter',
}
</script>

<style lang="scss">
.checkout-footer {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 100px;

  &__copy {
    padding-right: 36px;
  }

  &__container {
    display: flex;
    align-items: center;
    max-width: 1316px;
    width: 100%;
    padding: 0 17px;
    margin-inline: auto;
  }

  &__items {
    display: flex;
    align-items: center;
  }

  &__item {
    &:not(:last-child) {
      margin-right: 36px;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__social-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--color-white-01);

    &:not(:last-child) {
      margin-right: 24px;
    }
  }
}
</style>

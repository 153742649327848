<template>
  <div class="checkout-reviews">
    <div class="checkout-reviews__container">
      <ul class="checkout-reviews__items">
        <li
          v-for="(item, index) in items"
          :key="index"
          class="checkout-reviews__item"
        >
          <div class="checkout-reviews__item-top">
            <div class="checkout-reviews__item-top--left">
              <div class="checkout-reviews__item-avatar">
                <img
                  alt="Avatar"
                  :src="require(`@/static/checkout/reviews/${item.avatar}`)"
                />
              </div>
              <div class="checkout-reviews__item-name">
                {{ item.full_name }}
              </div>
            </div>
            <button class="checkout-reviews__item-button">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_698_3133)"><path d="M1.047 16.018H4.04V7.035H1.047v8.983zm16.468-8.234c0-.824-.674-1.497-1.497-1.497h-4.724l.711-3.421.023-.24c0-.307-.127-.591-.33-.793l-.793-.786L5.98 5.98c-.277.27-.442.643-.442 1.055v7.486c0 .823.674 1.497 1.497 1.497h6.737c.621 0 1.153-.375 1.377-.914l2.26-5.277c.068-.172.106-.352.106-.546V7.784z" fill="#fff"/></g><defs><clipPath id="clip0_698_3133"><path fill="#fff" transform="translate(.297 .297)" d="M0 0h17.965v17.965H0z"/></clipPath></defs></svg>
            </button>
          </div>
          <div class="checkout-reviews__item-bottom">
            {{ item.description }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutWhiteReviews',
  data() {
    return {
      items: [
        {
          avatar: 'maciejc.png',
          full_name: 'Maciej Wozniak',
          description:
            '"I have been struggling with depression and was scared to ask anyone for help. After learning how to meditate and going through Mental Future’s CBT course, I no longer feel sad or depressed! I noticed a big improvement in my mental health. Thanks to all the coaches who helped make this app! "',
        },
        {
          avatar: 'andrew.png',
          full_name: 'Andrew Gordon',
          description:
            '"Staying awake all night was something I used to do almost every night. I was just unable to fall asleep at all. Insomnia was slowly decreasing my quality of life and i started having feelings of hopelessness, regret and anxiety. I’m so happy things have changed thanks to Mental Future. My life is much more meaningful now!"',
        },
        {
          avatar: 'amanda.png',
          full_name: 'Amanda Miller',
          description:
            '“Mental Future’s meditations and therapy course helped me start feeling calmer in just about 4 weeks. Over this period I noticed much more progress than I managed to make in the past 5 years of taking prescription drugs and anti-depressants. Thanks for doing this for people like me!”',
        },
        {
          avatar: 'justin.png',
          full_name: 'Justin Brown',
          description:
            '“About 3 years ago, I was starting to wake up at night and couldn’t sleep for 4 to 5 hours due to personal relationship issues and increased stress. I was unable to catch good sleep at all and it affected my daytime activities – I lost focus, motivation for life and was generally in a bad place. I came across Mental Future by complete accident and decided to give it a go. 3 months later I couldn’t be happier I made this step. Will definitely continue – my thanks to the entire Mental Future team!”',
        },
        {
          avatar: 'yogesh.png',
          full_name: 'Yogesh Jain',
          description:
            '“Working as a developer in the IT industry certainly can be sometimes overwhelming – especially if you find yourself in a toxic corporate environment. As an expatriate I was constantly having to deal with prejudice and racial remarks at the workplace. The fear of losing my job and not being able to pay my bills was really overwhelming and I was stuck in a vicious cycle with no exit in view. Mental Future helped me increase my awareness which made it easier for me to make the right decisions. ”',
        },
        {
          avatar: 'stephanie.png',
          full_name: 'Stephanie Allen',
          description:
            '“As a single mother of three it has never been easy for me: I had to work and provide for my children, making sure they have food in their plates and all requirements for a healthy upbringing. I often had to hide my emotions and suppress the stress. Now, thanks to Mental Future, I learned how to embrace my emotions and focus more on positive thoughts, which led to me starting to enjoy certain aspects of life more.”',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.checkout-reviews {
  padding-bottom: 72px;

  &__items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__item {
    flex: 0 1 calc(50% - 16px);
    max-width: calc(50% - 16px);
    padding: 24px;
    background-color: var(--color-white);
    box-shadow: 0px 20px 40px rgba(47, 53, 66, 0.1);
    margin-top: 32px;
    border-radius: 12px;
    min-height: 222px;
  }

  &__item-top {
    display: flex;
    align-items: center;
    padding-bottom: 16px;

    &--left {
      display: flex;
      align-items: center;
    }
  }

  &__item-avatar {
    margin-right: 8px;
  }

  &__item-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }

  &__item-bottom {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }

  &__item-button {
    width: 32px;
    height: 32px;
    background: #2ed573;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>

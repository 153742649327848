<template>
  <div class="profile__achievements achievements">
    <template v-for="(achievements_item, i) in achievements" :key="i">
      <div
        class="achievements__last-item"
        v-if="achievements_item.isLast == true"
      >
        <div class="achievements__last-item-title">Your Last Achievement</div>
        <img
          :src="
            require(`@/static/profile/achievements/${achievements_item.machine_name}--active.svg`)
          "
          :alt="`${achievements_item.machine_name}`"
          class="achievements__last-item-image"
        />
        <div class="achievements__last-item-name">
          {{ achievements_item.name }}
        </div>
      </div>
    </template>
    <ul class="achievements__grid">
      <li
        class="achievements__grid-item"
        :class="[{ received: achievements_item.isReceived == true }]"
        v-for="(achievements_item, i) in achievements"
        :key="i"
      >
        <div class="achievements__grid-item-icon-wrapper">
          <img
            v-if="achievements_item.isReceived == true"
            :src="
              require(`@/static/profile/achievements/${achievements_item.machine_name}--active.svg`)
            "
            :alt="`${achievements_item.name}`"
            class="achievements__grid-item-icon"
          />
          <img
            v-else
            :src="
              require(`@/static/profile/achievements/${achievements_item.machine_name}.svg`)
            "
            :alt="`${achievements_item.name}`"
            class="achievements__grid-item-icon"
          />
        </div>
        <div class="achievements__grid-item-name">
          {{ achievements_item.name }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Achievements',
  data() {
    return {
      achievements: {},
    }
  },
  created() {
    axios.get(`user/achievements`).then((response) => {
      this.achievements = response.data
    })
  },
}
</script>

<style lang="scss">
.profile {
  &__container {
    @media (max-width: 991px) {
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
  }

  &__top {
    position: relative;
    padding-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__top-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
    padding-bottom: 36px;
  }

  &__links {
    display: flex;
    align-items: center;
    overflow: auto;
    margin-bottom: 36px;

    @media (min-width: 992px) {
      justify-content: center;
    }
  }

  &__link {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 18px;
    white-space: nowrap;
    transition: 0.4s ease;

    &:not(.active) {
      color: rgba(#fff, 0.5);
      margin-right: 12px;
      margin-left: 12px;

      &:nth-child(1) {
        margin-left: 0;
      }

      &:hover,
      &:focus {
        color: var(--color-white);
      }
    }

    &.active {
      background-color: rgba(#fff, 0.1);
      color: var(--color-white);
      padding-right: 12px;
      padding-left: 12px;
    }
  }
}
.achievements {
  @media (max-width: 991px) {
    padding: 0 24px;
  }

  &__last-item {
    padding: 24px;
    max-width: 380px;
    margin: 72px auto;
    text-align: center;
    background-color: var(--color-white);
    border-radius: 12px;
    color: var(--color-main);

    @media (max-width: 991px) {
      margin: 32px auto 40px;
    }
  }

  &__last-item-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    padding-bottom: 40px;
  }

  &__last-item-image {
    width: 85px;
    height: 60px;
    margin-bottom: 24px;
  }

  &__last-item-name {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    padding-bottom: 8px;
  }

  &__last-item-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 36px;
    max-width: 715px;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 991px) {
      max-width: 765px;
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 24px;
    }

    @media (max-width: 576px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 374px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__grid-item {
    &.received {
      .achievements__grid-item-icon-wrapper {
        background: var(--color-white);
      }

      .achievements__grid-item-name {
        font-weight: 700;
      }
    }
  }

  &__grid-item-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 50%;
    background-color: rgba(#fff, 0.1);
    margin-bottom: 10px;
  }

  &__grid-item-icon {
    width: 60%;
    height: 60%;
  }

  &__grid-item-name {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-white);
    text-align: center;
  }
}
</style>

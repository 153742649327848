<template>
  <template v-if="sleepCollection">
    <collection-section :data="sleepCollection.section" />
  </template>
</template>

<script>
import axios from 'axios'
import CollectionSection from '@/components/collection/CollectionSection.vue'

export default {
  name: 'SleepCollection',
  components: { CollectionSection },
  data() {
    return {
      sleepCollection: {},
      tags: [],
    }
  },
  created() {
    axios.get(`screen/sleep`).then((response) => {
      this.sleepCollection = response
    })

    axios.get(`tags`).then((response) => {
      this.tags = response.tags
    })
  },
}
</script>

<style lang="scss"></style>

<template>
  <div class="checkout-testimonials">
    <div class="checkout-testimonials__container">
      <div class="checkout-testimonials__title">
        Don’t just take my word for it... there are
        <b>countless other people who have already joined</b>
      </div>
      <ul class="checkout-testimonials__items">
        <li
          v-for="(item, index) in items"
          :key="index"
          class="checkout-testimonials__item"
        >
          <img
            :src="
              require(`@/static/checkout-white/testimonials/${item.avatar}`)
            "
            :alt="item.name"
            class="checkout-testimonials__avatar"
          />
          <div class="checkout-testimonials__item-name">
            {{ item.name }}
          </div>
          <div class="checkout-testimonials__item-text">
            {{ item.text }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutTestimonials',
  data() {
    return {
      items: [
        {
          avatar: 'amy.png',
          name: 'Amy Huang',
          text: "It's amazing what you've done for us!",
        },
        {
          avatar: 'nathan.png',
          name: 'Nathan Baker',
          text: "I can't believe how much it's all helped...",
        },
        {
          avatar: 'carol.png',
          name: 'Carol Matz',
          text: "I'm just so grateful you've shared this information with us all.",
        },
        {
          avatar: 'sasha.png',
          name: 'Sasha Golovkin',
          text: '...thank God I found your app when I did!',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.checkout-testimonials {
  padding-bottom: 94px;

  &__title {
    font-weight: 400;
    font-size: 56px;
    line-height: 1;
    text-align: center;
    padding-bottom: 100px;
  }

  &__items {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    width: 326px;
    flex: 0 0 326px;

    &:nth-child(2) {
      position: relative;
      margin-left: -60px;
      margin-top: 164px;
      z-index: 2;
    }

    &:nth-child(3) {
      position: relative;
      margin-left: -45px;
      margin-top: 89px;
      z-index: 1;

      .checkout-testimonials__item-name,
      .checkout-testimonials__item-text {
        padding-left: 55px;
      }
    }

    &:nth-child(4) {
      margin-left: -90px;

      .checkout-testimonials__item-name,
      .checkout-testimonials__item-text {
        padding-left: 105px;
      }
    }
  }

  &__item-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    padding: 12px 0 4px;

    &:after {
      content: ':';
    }
  }
  &__item-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    &:after,
    &:before {
      content: '"';
    }
  }
}
</style>

<template>
  <label class="package__card">
    <div class="package__popular__head" v-if="popular">
      <p class="package__popular__title">MOST POPULAR</p>
    </div>
    <div class="package__item" :class="{ popular: popular }">
      <a-radio
        class="package__item__checkbox"
        name="package"
        :value="purchaseTypes.MONTHLY"
      ></a-radio>
      <div class="package__item__detail">
        <h4 class="package__item__title">Monthly Plan</h4>
        <p class="package__item__price">
          <span class="strikethrough"
            >{{ currency }}{{ (+data.amount + 5).toFixed(2) }}</span
          >
          {{ currency }}{{ +data.amount }}
        </p>
      </div>
      <div class="package__item__right overlay">
        <p class="package__item__price package__item__price-old strikethrough">
          {{ currency }}{{ ((+data.amount + 5) / 30).toFixed(2) }}
        </p>
        <span class="package__item__price-p-small">{{ currency }}</span>
        <p class="package__item__price-per-day">
          {{ Math.floor(+data.amount / 30).toFixed(0) }}
        </p>
        <div class="package__item__p-wrapper">
          <p class="package__item__price-p-small">
            .{{ (+data.amount / 0.3).toFixed(0) }}
          </p>
          <p class="package__item__price-p-small">per day</p>
        </div>
      </div>
    </div>
  </label>
</template>

<script>
import { purchaseTypes } from '@/data/constants'
import { getCurrency } from '@/utils/helpers'
export default {
  name: 'PaymentMonthlyCard',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    popular: Boolean,
  },
  data() {
    return {
      purchaseTypes,
    }
  },
  computed: {
    currency() {
      return this.getCurrency(this.data.currency)
    },
  },
  methods: {
    getCurrency,
  },
}
</script>

<style></style>

<template>
  <section class="benefits">
    <div class="container container--content">
      <h2 class="benefits__title welcome-title">
        Join Millions Of People, Already Benefiting From Mental Future
      </h2>
      <p class="benefits__description welcome-description">
        Be a part of a growing community of people, seeking a positive change in
        their lives.
      </p>
      <ul class="benefits__list">
        <li class="benefits__list-item">
          <div class="benefits__list-item-value">2M+</div>
          <h4 class="benefits__list-item-title">Community</h4>
          <p class="benefits__list-item-text">
            Join a supportive community of practitioners, expert coaches and
            therapists
          </p>
        </li>
        <li class="benefits__list-item">
          <div class="benefits__list-item-value">98%</div>
          <h4 class="benefits__list-item-title">Success</h4>
          <p class="benefits__list-item-text">
            Nearly all first-time users report an improvement after their first
            session
          </p>
        </li>
        <li class="benefits__list-item">
          <div class="benefits__list-item-value">1000+</div>
          <h4 class="benefits__list-item-title">Tracks</h4>
          <p class="benefits__list-item-text">
            An extensive and ever-growing library, full of meditations,
            sleepcasts, music and various therapies
          </p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.benefits {
  padding-bottom: 144px;

  @media (max-width: 767px) {
    padding-bottom: 72px;
  }

  &__list {
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  &__list-item {
    flex: 1 1 100%;
    max-width: 100%;
    color: var(--color-white);
    text-align: center;

    @media (max-width: 767px) {
      margin-bottom: 52px;
    }

    @media (min-width: 768px) {
      flex: 1 0 244px;
      max-width: 244px;
    }
  }

  &__list-item-value {
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 12px;

    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 8px;
    }
  }

  &__list-item-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-top: 0;
    margin-bottom: 12px;
    color: var(--color-white);

    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px;
    }
  }

  &__list-item-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}
</style>

<template>
  <div class="checkout-triangle">
    <div class="checkout-triangle__container container-checkout">
      <div class="checkout-triangle__items">
        <div class="checkout-triangle__image">
          <img src="@/static/checkout/icons/triangle.svg" alt="triangle" />
        </div>
        <div
          v-for="(item, index) in items"
          :key="index"
          class="checkout-triangle__item"
        >
          <div class="checkout-triangle__item-title">
            <div>
              {{ item.title }}
            </div>
          </div>
          <div class="checkout-triangle__item-subtitle">
            <div>
              {{ item.subtitle }}
            </div>
          </div>
          <div class="checkout-triangle__item-text">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutTriangle',
  data() {
    return {
      items: [
        {
          title: 'Habit',
          subtitle: 'Secret #1',
          text: 'Get started in the easiest possible way and make meditation and self-affirmations part of your daily life. Cultivate mindfulness by learning how to meditate step-by-step using our proven system.',
        },
        {
          title: 'Technique',
          subtitle: 'Secret #2',
          text: 'Meditation and mind recovery are not hard when you are doing the right practices under the guidance of an experienced professional or system. It is key to develop a practice which is both enjoyable and effective.',
        },
        {
          title: 'Transformation',
          subtitle: 'Secret #03',
          text: 'By incorporating the two pillars of habit and technique you can begin your transformation: a movement from scattered life to centered life. From automatic living to conscious living. From anxious to calm and empowered. Learn to live with wisdom and courage. Become focused and successful. Become the best version of yourself.',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.checkout-triangle {
  max-width: 1044px;
  width: 100%;
  margin-inline: auto;
  padding-bottom: 56px;

  &__image {
    position: absolute;
    top: 10px;
    left: 284px;
  }

  &__items {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    &:nth-child(3) {
      margin-left: auto;
      padding-top: 64px;

      .checkout-triangle__item-text {
        max-width: 294px;
      }
    }

    &:last-child {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      flex: 1 1 100%;
      max-width: 100%;
      padding-top: 159px;

      .checkout-triangle__item-title,
      .checkout-triangle__item-subtitle {
        flex: 1 1 100%;

        div {
          max-width: 369px;
          margin-inline: auto;
        }
      }

      .checkout-triangle__item-text {
        max-width: 369px;
      }
    }
  }

  &__item-title {
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 8px;
  }

  &__item-subtitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 16px;
  }

  &__item-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    max-width: 284px;
  }
}
</style>

<template>
  <div class="page-wrapper theme-green-forest">
    <div class="logo-container">
      <router-link to="/">
        <img src="@/assets/icons/logo.svg" alt="logo" />
      </router-link>
    </div>
    <div class="login-back">
      <button @click="$router.back()" class="login-back__btn">
        <img src="@/static/settings/arrow.svg" alt="Arrow back" title="Back" />
      </button>
    </div>
    <div class="registration">
      <div class="registration__title account-title">create account</div>
      <div class="registration__form-wrapper account-form-wrapper">
        <form class="registration__form" @submit.prevent="handleSubmit">
          <div class="registration__inputs">
            <div class="registration__input-wrapper input-wrapper">
              <input
                type="text"
                :disabled="isLead"
                placeholder="First Name"
                v-model="name"
                class="with-icon name"
              />
              <template v-if="status === true">
                <p
                  class="registation__input-error error-message"
                  v-for="(error, index) in errors.errors.name"
                  :key="index"
                >
                  {{ error }}
                </p>
              </template>
            </div>
            <div class="registration__input-wrapper input-wrapper">
              <input
                type="email"
                placeholder="Email"
                v-model="email"
                :disabled="isLead"
                class="with-icon"
              />
              <template v-if="status === true">
                <p
                  class="registation__input-error error-message"
                  v-for="(error, index) in errors.errors.email"
                  :key="index"
                >
                  {{ error }}
                </p>
              </template>
            </div>
            <div class="registration__input-wrapper input-wrapper">
              <input
                type="password"
                placeholder="Password"
                v-model="password"
                class="with-icon"
              />
              <template v-if="status === true">
                <p
                  class="registation__input-error error-message"
                  v-for="(error, index) in errors.errors.password"
                  :key="index"
                >
                  {{ error }}
                </p>
              </template>
            </div>
            <div
              class="registration__input-wrapper input-wrapper input-wrapper--sign-up"
            >
              <button type="submit">Sign Up</button>
            </div>
            <div class="registration__text account-text">
              <span> Already have an account? </span>
              <router-link :to="loginRoute">Log In</router-link>
            </div>
            <div class="account-privacy-policy">
              By continuing, you agree to our
              <div class="terms-policy">
                <router-link :to="{ name: 'TermsAndConditions' }">
                  Terms & Conditions
                </router-link>
                <span>and</span>
                <router-link :to="{ name: 'PrivacyAndPolicy' }"
                  >Privacy Policy</router-link
                >
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import { loadFacebookPixel, initFacebook } from '@/utils/facebookConfig'
import { putLead, getLead } from '@/services/lead.service'
import { notification } from 'ant-design-vue'

export default {
  name: 'Registration',
  props: ['user'],
  data() {
    return {
      name: '',
      password: '',
      phone: '',
      email: '',
      platform: 'web',
      device_type: 'web',
      errors: {},
      status: false,
      loginRoute: `/login${window.location.search}`,
    }
  },
  computed: {
    ...mapState('quiz', ['quizForm', 'quizData']),
    ...mapGetters('quiz', ['isCustomQuiz']),
    isLead() {
      return this.$route.query.u === 'lead'
    },
  },
  watch: {
    quizForm: {
      immediate: true,
      handler() {
        if (this.quizForm.email) {
          this.email = this.quizForm.email
        }
      },
    },
  },
  created() {
    if (this.isLead) {
      getLead(this.$route.query.statistic_id).then((res) => {
        this.email = res.email
        this.name = res.name
      })
    }
  },
  async mounted() {
    this.loadFacebookPixel()
    await initFacebook()
    window.fbAsyncInit()
  },
  methods: {
    ...mapMutations(['SET_TOKEN']),
    ...mapMutations('quiz', ['SET_QUIZ_FORM']),
    ...mapActions(['fetchUser']),
    loadFacebookPixel,
    handleSubmit() {
      if (this.isLead) {
        this.createLead()
      } else {
        const statistic_id = this.$route.query.statistic_id
        const headers = {
          Accept: 'application/json',
        }
        let data = {
          name: this.name,
          password: this.password,
          phone: this.phone,
          email: this.email,
          platform: this.platform,
        }
        if (statistic_id) {
          data = { ...data, statistic_id }
        }

        axios
          .post(`user`, data, { headers })
          .then(async (data) => {
            console.log('data', data)

            if (data.status === 208) {
              notification.error({
                message: data?.message,
              })
              return
            }
            this.response(data)
          })
          .catch((errors) => {
            this.errors = errors
            this.status = true
          })
      }
    },
    createLead() {
      const statistic_id = this.$route.query.statistic_id
      putLead({
        password: this.password,
        statistic_id,
      })
        .then((res) => {
          this.response(res)
        })
        .catch((errors) => {
          this.errors = errors
          this.status = true
        })
    },
    async response(data) {
      fbq('track', 'CompleteRegistration')
      this.SET_TOKEN(data.token)
      const isQuiz = localStorage.getItem('isQuiz')

      if (this.$route.query.redirect) {
        this.$router.push({
          path: this.$route.query.redirect,
          query: {
            ...this.$route.query,
            ...JSON.parse(this.$route.query?.redirectParams ?? null),
            vsl_id: this.$route.query.vsl_id,
            statistic_id: this.$route.query.statistic_id,
          },
        })
      } else if (isQuiz) {
        if (this.isCustomQuiz) {
          this.SET_QUIZ_FORM({ ...this.quizForm, isQuizCompleted: true })
          localStorage.setItem('quizForm', JSON.stringify(this.quizForm))
          this.$router.push('/quiz-dynamic-results/' + this.quizData.slug)
        } else {
          this.$router.push('/quiz-results')
        }
      } else {
        // const data = await this.fetchUser()
        // if (!data.gender && !data.goal) {
        //   this.$router.push('/quiz')
        // } else
        // this.$router.push('/home')
        window.location.href = '/home'
      }
    },
  },
}
</script>

<style lang="scss">
.registration {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 76px;
}
</style>

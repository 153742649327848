<template>
  <div class="checkout-investing">
    <div class="checkout-investing__container container-checkout">
      <div class="checkout-investing__title">
        The investment for this training is <b>$1714.95.</b>
      </div>
      <p class="checkout-investing__text">
        But what if... We are willing to gamble on you?
      </p>
      <div class="checkout-investing__text">
        What if we see the potential in you and what is possible the moment you
        start doing these meditations?
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutInvesting',
}
</script>

<style lang="scss">
.checkout-investing {
  padding-top: 72px;
  padding-bottom: 72px;
  text-align: center;

  &__title {
    font-weight: 400;
    font-size: 40px;
    line-height: 55px;
    padding-bottom: 36px;

    b {
      font-weight: 800;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    max-width: 1022px;
    margin-inline: auto;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}
</style>

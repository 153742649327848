<template>
  <NoteHeader :topDate="note.date" />
  <div class="profile__note note">
    <div class="note__title">{{ note.title }}</div>
    <div class="note__text">
      {{ note.content }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import NoteHeader from './NoteHeader.vue'

export default {
  name: 'Note',
  computed: {
    ...mapState('note', ['note']),
  },
  created() {
    this.fetchNote(this.$route.params.id)
  },
  methods: {
    ...mapActions('note', ['fetchNote']),
  },
  components: { NoteHeader },
}
</script>
<style lang="scss">
.note {
  max-width: 656px;
  margin-right: auto;
  margin-left: auto;
  color: var(--color-white);
  position: relative;
  width: 100%;

  &__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    padding-right: 24px;
    padding-bottom: 40px;
  }

  &__date {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 36px;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &__delete {
    position: absolute;
    top: 10px;
    right: 0;
  }
}
</style>

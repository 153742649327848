import axios from 'axios'

const prefix = '/user'

export const userSetFavorite = async (data) => {
  return await axios.post(`${prefix}/favorite`, data)
}

export const userCloudfrontCookies = async () => {
  return await axios.get(`${prefix}/cloudfront-cookies`)
}

export const userDeleteFavorite = async (data) => {
  return await axios.delete(`${prefix}/favorite`, { data })
}

export const userUpdate = async (data) => {
  return await axios.put(`${prefix}`, data)
}

export const userHistory = async (data) => {
  return await axios.post(`${prefix}/history`, data)
}

export const userAuth = async (auth, data) => {
  return await axios.post(`${prefix}/auth/${auth}`, data)
}

export const userGetCheckout = async (id) => {
  return await axios.get(`${prefix}/checkout/${id}`)
}

export const userRecoveryPassword = async (email) => {
  return await axios.get(`${prefix}/recovery-password?email=${email}`)
}

export const userUpdatePasswordByResetLink = async (data) => {
  return await axios.post(`${prefix}/recovery-password`, data)
}

export const userStatus = async () => {
  return await axios.get(`${prefix}/account-status`)
}

export const userData = async () => {
  return await axios.get(`${prefix}`)
}

export const userAuthByTmpToken = async ({ email, token, device = 'web' }) => {
  return await axios.post(`/authorization-by-tmp-token/${email}`, {
    email: email,
    tmp_token: token,
    device_name: device,
  })
}

export const registerWithEmail = async (formData) => {
  const data = new FormData()
  Object.entries(formData).forEach(([key, value]) => {
    data.set(key, value)
  })
  data.set('platform', 'web')
  return axios.post(prefix, data, { headers: { Accept: 'application/json' } })
}

export const userCreateTrackingSession = async (data) => {
  return await axios.post(`${prefix}/sessions`, data)
}

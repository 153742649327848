<template>
  <a :href="url" v-if="item.free">
    <div class="card__wrapper">
      <div class="card__image-wrapper">
        <img
          :src="`${item.cover}`"
          alt="image"
          loading="lazy"
          class="card__image"
        />
      </div>
      <div class="card__right">
        <div class="card__title">
          {{ item.name }}
        </div>
        <div class="card__type-wrapper">
          <span class="card__type">
            {{ item.type }}
          </span>
          <span class="card__duration" v-if="item.duration">
            {{ item.duration }} min
          </span>
          <span class="card__duration" v-else-if="item.amount_lessons">
            {{ item.amount_lessons }} sessions
          </span>
        </div>
      </div>
    </div>
  </a>
  <div class="card__wrapper" v-else @click="onUnlock">
    <div class="card__image-wrapper" :class="{ locked: !item.free }">
      <img
        :src="`${item.cover}`"
        alt="image"
        loading="lazy"
        class="card__image"
      />
    </div>
    <div class="card__right">
      <div class="card__title">
        {{ item.name }}
      </div>
      <div class="card__type-wrapper">
        <span class="card__type">
          {{ item.type }}
        </span>
        <span class="card__duration" v-if="item.duration">
          {{ item.duration }} min
        </span>
        <span class="card__duration" v-else-if="item.amount_lessons">
          {{ item.amount_lessons }} sessions
        </span>
      </div>
    </div>
    <SubscriptionModal @done="onDone" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import SubscriptionModal from '../subscribe/SubscriptionModal.vue'
import { metaEventCustomActions } from '@/data/constants'
export default {
  name: 'SectionCard',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    url: {
      type: String,
      default: '',
    },
  },
  methods: {
    ...mapMutations('subscription', ['SET_OPEN']),
    onUnlock() {
      this.SET_OPEN({
        isOpen: true,
        action: metaEventCustomActions.ON_LOCKED_CLICKED,
      })
    },
    onDone() {
      window.location.reload()
    },
  },
  components: { SubscriptionModal },
}
</script>

<style lang="scss" scoped>
.card {
  &__wrapper {
    display: grid;
    grid-template-columns: 201px 1fr;
    column-gap: 16px;
    cursor: pointer;
  }
  &__image-wrapper {
    width: 201px;
    height: 133px;

    &.locked {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 12px;
        right: 9px;
        display: block;
        width: 18px;
        height: 24px;
        background-image: url('~@/static/icons/lock.svg');
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 1;
      }
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }
  &__title {
    color: var(--color-white);
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
  }
  &__type {
    font-size: 12px;
    line-height: 16px;
    color: var(--color-white);
    font-weight: 400;
    text-transform: capitalize;
  }
  &__duration {
    color: var(--color-white);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    &::before {
      content: '•';
      padding: 0 5px;
      color: currentColor;
    }
  }
}
</style>

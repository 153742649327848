import axios from 'axios'
import { getWebpHead } from '../utils/helpers'

export const getAllTags = async () => {
  return await axios.get('/tags')
}

export const getCountries = async () => {
  return await axios.get('/countries')
}

export const searchByTag = async (params) => {
  return await axios.get('/search', { params, headers: getWebpHead() })
}

export const ping = async () => {
  return await axios.get('/ping')
}

export const getCountryByIp = async () => {
  return fetch('https://ipinfo.io/json').then((res) => res.json())
}

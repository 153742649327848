<template>
  <footer class="footer">
    <div class="footer__container container">
      <ul class="footer__list-social">
        <li class="footer__list-social-item">
          <a
            href="https://www.facebook.com/mentalfuture.official/"
            target="blank"
            class="footer__list-social-link footer__list-social-link--facebook"
          >
            <svg
              width="8"
              height="15"
              viewBox="0 0 8 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.8767 8.21947L7.2594 5.57347H4.87493V3.85953C4.85828 3.66233 4.88361 3.46368 4.94903 3.27829C5.01445 3.0929 5.11829 2.92551 5.25286 2.78854C5.38743 2.65158 5.54928 2.54853 5.72642 2.48704C5.90356 2.42555 6.09145 2.40719 6.27618 2.43332H7.35949V0.181409C6.72287 0.0702679 6.07934 0.00962714 5.43424 0C3.46779 0 2.1843 1.25106 2.1843 3.55927V5.5985H0V8.2445H2.1843V14.6061C3.07561 14.7563 3.98362 14.7563 4.87493 14.6061V8.21947H6.8767Z"
                fill="white"
              />
            </svg>
          </a>
        </li>
        <li class="footer__list-social-item">
          <a
            href="https://www.instagram.com/mentalfuture/"
            target="blank"
            class="footer__list-social-link footer__list-social-link--instagram"
          >
            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.7122 2.49219C10.5505 2.49219 10.3924 2.53996 10.258 2.62947C10.1235 2.71899 10.0187 2.84623 9.95678 2.99509C9.89489 3.14395 9.87869 3.30774 9.91024 3.46578C9.94179 3.62381 10.0197 3.76897 10.134 3.88291C10.2484 3.99684 10.3941 4.07443 10.5527 4.10587C10.7114 4.1373 10.8758 4.12116 11.0252 4.0595C11.1746 3.99784 11.3023 3.89344 11.3922 3.75947C11.482 3.6255 11.53 3.46799 11.53 3.30686C11.53 3.09079 11.4438 2.88357 11.2905 2.73079C11.1371 2.57801 10.9291 2.49219 10.7122 2.49219Z"
                fill="white"
              />
              <path
                d="M7.05625 3.54297C6.37375 3.54297 5.70657 3.74461 5.1391 4.12239C4.57162 4.50016 4.12933 5.03708 3.86815 5.66529C3.60696 6.2935 3.53863 6.98479 3.67178 7.65169C3.80493 8.3186 4.13357 8.93118 4.61617 9.41199C5.09877 9.89281 5.71364 10.2202 6.38302 10.3529C7.05241 10.4855 7.74624 10.4175 8.37679 10.1572C9.00734 9.89703 9.54629 9.45638 9.92546 8.891C10.3046 8.32563 10.507 7.66093 10.507 6.98096C10.507 6.52947 10.4178 6.08241 10.2443 5.66529C10.0709 5.24818 9.81675 4.86919 9.49631 4.54994C9.17588 4.2307 8.79546 3.97744 8.37679 3.80467C7.95812 3.63189 7.50942 3.54297 7.05625 3.54297ZM7.05625 9.18063C6.61993 9.17902 6.19387 9.04863 5.83187 8.80595C5.46987 8.56327 5.18816 8.21918 5.02231 7.8171C4.85645 7.41503 4.81389 6.97301 4.9 6.54686C4.98612 6.12071 5.19704 5.72952 5.50613 5.42271C5.81523 5.1159 6.20863 4.9072 6.63669 4.82298C7.06474 4.73876 7.50825 4.78281 7.91121 4.94953C8.31416 5.11625 8.6585 5.39819 8.90074 5.75974C9.14298 6.1213 9.27227 6.54625 9.27227 6.98096C9.27227 7.27051 9.21489 7.55723 9.10343 7.82463C8.99196 8.09204 8.8286 8.33489 8.62272 8.53925C8.41684 8.74362 8.17248 8.90548 7.90367 9.01554C7.63486 9.1256 7.34688 9.1817 7.05625 9.18063Z"
                fill="white"
              />
              <path
                d="M9.80447 13.9639H4.21126C3.09503 13.9617 2.02514 13.519 1.23584 12.7326C0.446543 11.9462 0.00216115 10.8803 0 9.76819V4.19568C0.00216115 3.08357 0.446543 2.01762 1.23584 1.23124C2.02514 0.444866 3.09503 0.00215316 4.21126 0H9.80447C10.9207 0.00215316 11.9906 0.444866 12.7799 1.23124C13.5692 2.01762 14.0136 3.08357 14.0157 4.19568V9.76819C14.0136 10.8803 13.5692 11.9462 12.7799 12.7326C11.9906 13.519 10.9207 13.9617 9.80447 13.9639ZM4.20307 1.31165C3.43601 1.3138 2.70097 1.61835 2.15857 2.15874C1.61617 2.69913 1.31049 3.43145 1.30833 4.19568V9.76819C1.31049 10.5324 1.61617 11.2647 2.15857 11.8051C2.70097 12.3455 3.43601 12.6501 4.20307 12.6522H9.80447C10.5715 12.6501 11.3066 12.3455 11.849 11.8051C12.3914 11.2647 12.697 10.5324 12.6992 9.76819V4.19568C12.697 3.43145 12.3914 2.69913 11.849 2.15874C11.3066 1.61835 10.5715 1.3138 9.80447 1.31165H4.20307Z"
                fill="white"
              />
            </svg>
          </a>
        </li>
        <li class="footer__list-social-item">
          <a
            href="https://www.tiktok.com/@mental.future"
            target="blank"
            class="footer__list-social-link footer__list-social-link--tiktok"
          >
            <svg
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.1571 6.63081H12.7891C12.1253 6.63151 11.4718 6.46724 10.8878 6.15286C10.3039 5.83848 9.80779 5.38394 9.44461 4.83038V10.9567C9.44461 11.8526 9.17797 12.7284 8.67839 13.4732C8.17882 14.2181 7.46873 14.7987 6.63796 15.1415C5.80719 15.4844 4.89305 15.5741 4.01111 15.3993C3.12917 15.2245 2.31907 14.7931 1.68322 14.1596C1.04738 13.5262 0.614351 12.7191 0.438923 11.8404C0.263494 10.9618 0.353539 10.051 0.697655 9.22333C1.04177 8.39565 1.6245 7.68824 2.37218 7.19052C3.11985 6.6928 3.99886 6.42714 4.89808 6.42714H5.1761V8.65936C5.08615 8.65936 4.99621 8.65936 4.89808 8.65936C4.58207 8.64065 4.26557 8.6865 3.96799 8.79411C3.67041 8.90172 3.39806 9.06881 3.16766 9.28511C2.93727 9.50141 2.75369 9.76236 2.62821 10.0519C2.50274 10.3415 2.43803 10.6536 2.43803 10.969C2.43803 11.2843 2.50274 11.5964 2.62821 11.886C2.75369 12.1755 2.93727 12.4365 3.16766 12.6528C3.39806 12.8691 3.67041 13.0362 3.96799 13.1438C4.26557 13.2514 4.58207 13.2973 4.89808 13.2785C5.20954 13.2894 5.5201 13.2391 5.812 13.1303C6.10391 13.0215 6.37142 12.8565 6.59926 12.6446C6.82709 12.4328 7.0108 12.1782 7.13983 11.8956C7.26886 11.613 7.34069 11.3078 7.35124 10.9975V0.585938H9.49369C9.59105 1.51412 10.013 2.3786 10.6858 3.028C11.3585 3.67739 12.2392 4.07029 13.1734 4.1379V6.62268"
                fill="white"
              />
            </svg>
          </a>
        </li>
        <li class="footer__list-social-item">
          <a
            href="https://www.youtube.com/c/MentalFuture"
            target="blank"
            class="footer__list-social-link footer__list-social-link--youtube"
          >
            <svg
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5999 3.35956C13.5999 2.60116 13.2975 1.87383 12.7592 1.33756C12.221 0.801291 11.4909 0.5 10.7297 0.5H3.37018C2.60896 0.5 1.87892 0.801291 1.34065 1.33756C0.802388 1.87383 0.5 2.60116 0.5 3.35956V6.75682C0.499997 7.51597 0.802122 8.24414 1.34016 8.7817C1.87819 9.31927 2.60821 9.62236 3.37018 9.62451H10.7297C11.4917 9.62236 12.2217 9.31927 12.7597 8.7817C13.2978 8.24414 13.5999 7.51597 13.5999 6.75682V3.35956ZM9.28232 5.31481L6.01143 6.94418C5.8806 7.0175 5.4472 6.94419 5.4472 6.77311V3.44104C5.4472 3.29439 5.88878 3.20477 6.01962 3.27809L9.16785 4.98078C9.27415 5.0541 9.41315 5.24149 9.28232 5.31481Z"
                fill="white"
              />
            </svg>
          </a>
        </li>
      </ul>
      <nav class="footer__menu">
        <ul class="footer__menu-list">
          <li class="footer__menu-item">
            <router-link to="privacy-policy" class="footer__menu-link">
              Privacy Policy
            </router-link>
          </li>
          <li class="footer__menu-item">
            <router-link to="/terms-of-service" class="footer__menu-link">
              Terms & Conditions
            </router-link>
          </li>
          <li class="footer__menu-item">
            <a href="/faq" class="footer__menu-link"> FAQ </a>
          </li>
        </ul>
      </nav>
      <p class="footer__copyright">
        © 2022 Mental Future. All Rights Reserved.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  components: {},
}
</script>

<style lang="scss" scoped>
@import '../assets/common';

.footer {
  margin-top: 92px;

  &__container {
    padding-left: 36px;
    padding-right: 36px;

    @media (max-width: 768px) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    margin-bottom: 36px;
  }

  &__list-social {
    display: flex;
    align-items: center;
  }

  &__list-social-item {
    padding-bottom: 36px;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  &__list-social-link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(#fff, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;

    &--facebook {
      svg {
        width: 11px;
        height: 22px;
      }
    }

    &--instagram {
      svg {
        width: 21px;
        height: 21px;
      }
    }

    &--tiktok {
      svg {
        width: 20px;
        height: 22px;
      }
    }

    &--youtube {
      svg {
        width: 20px;
        height: 14px;
      }
    }

    &:hover,
    &:focus {
      svg {
        path {
          fill: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  &__menu-list {
    display: flex;
    align-items: center;
    margin-bottom: 72px;

    @media (max-width: 767px) {
      flex-wrap: wrap;
      margin-bottom: 20px;
    }
  }

  &__menu-item {
    @media (max-width: 767px) {
      width: 50%;
      margin-bottom: 20px;
    }

    &:not(:last-child) {
      margin-right: 120px;

      @media (max-width: 991px) {
        margin-right: 60px;
      }

      @media (max-width: 767px) {
        margin-right: 0;
      }
    }
  }

  &__menu-link {
    font-weight: 700;
    font-size: 16px;
    color: var(--color-white);
  }

  &__copyright {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-white);
  }
}
</style>

<template>
  <div class="checkout-not-so-secret">
    <div class="checkout-not-so-secret__container container-checkout">
      <div class="checkout-not-so-secret__title">
        Our "Not So Secret" Method To <br />
        <b>Rewire Your Mindset</b>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutNotSoSecret',
}
</script>

<style lang="scss">
.checkout-not-so-secret {
  &__title {
    padding: 48px 40px;
    text-align: center;
    font-weight: 400;
    font-size: 36px;
    line-height: 49px;
    background-color: var(--color-white-01);
    border-radius: 12px;

    b {
      font-weight: 800;
    }
  }
}
</style>

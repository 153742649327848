<template>
  <div class="note-header">
    <button @click="$router.back()" class="btn-reset">
      <img
        src="@/static/settings/arrow.svg"
        alt="Arrow back"
        title="Back to homepage"
      />
    </button>
    <p class="note-date">{{ topDate }}</p>
    <button class="btn-reset" @click="onDelete" v-if="showDelete">
      <img
        src="@/static/profile/notes/delete.svg"
        alt="Edit note"
        title="Delete note"
      />
    </button>
    <span class="space-40" v-else></span>
  </div>
</template>

<script>
import moment from 'moment'
import { Modal, notification } from 'ant-design-vue'
import { deleteNote } from '@/services/note.service'

export default {
  name: 'NoteHeader',
  props: {
    showDelete: {
      type: Boolean,
      default: true,
    },
    topDate: {
      type: String,
      default: moment().format('ll'),
    },
  },
  methods: {
    onDelete() {
      const that = this
      Modal.confirm({
        title: 'Are you sure?',
        okText: 'Yes',
        onOk() {
          return deleteNote(that.$route.params.id)
            .then((_) => {
              notification.success({ message: 'Successfully deleted' })
              that.$router.push({ name: 'Notes' })
            })
            .catch((err) => {
              console.log(err)
              // notification.error({ message: err.message })
            })
        },
        onCancel() {},
      })
    },
  },
}
</script>

<style lang="scss">
.note-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 27px 36px 74px 43px;
}

.note-date {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-white);
  text-transform: uppercase;
}

.space-40 {
  width: 40px;
}
</style>

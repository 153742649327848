<template>
  <div class="page-wrapper">
    <Header :user="user" />
    <div class="privacy-and-policy">
      <div class="privacy-and-policy__container container container--big">
        <div class="privacy-and-policy__top">
          <b class="privacy-and-policy__top-title">PRIVACY POLICY OF THE MENTAL FUTURE PROJECT (THE “PROJECT”) <br />
            (Revised as of August 2022)</b>
        </div>
      </div>
      <div class="privacy-and-policy__privacy-policy">
        <div class="privacy-and-policy__privacy-policy-wrapper">
          <p>
            Thank you for choosing the Project (<b>“We”</b>, <b>“Us”</b>, <b>“Our”</b> or <b>“Project”</b>).
          </p>
          <p>
            Please review our Privacy Policy (hereinafter referred to as the <b>“Policy”</b>) to understand what type of
            data,
            for what purposes, and in which ways we can process. The Policy is designed in such a way, in order to make
            its content to you as clear as possible. If you have any questions or suggestions regarding our Policy,
            please contact us through the Help and/or Support section of the Project so we can reply to you in a quicker
            and more straight forward fashion.
          </p>
          <p>
            We act as a controller under the meaning of Regulation (EU) 2016/679 of the European Parliament and of the
            Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data
            and on the free movement of such data, and repealing Directive 95/46/EC (<b>“General Data Protection
              Regulation”</b> or <b>“GDPR”</b>) and we are responsible for the personal information we process. This
            Policy informs
            you how we protect your Personal Information (as defined below) and informs you of your privacy rights. The
            controller for the purposes of the GDPR and other applicable data protection or data privacy laws of the EU
            Member States, as well as other data protection requirements in respect of the Project, is:
          </p>
          <p>
            Nikolay Mihaylov
          </p>
          <p>
            Email: <a href="mailto:privacy@mentalfuture.com">privacy@mentalfuture.com</a>
          </p>
          <p>
            Data processing is carried out by us solely for the purposes defined by this Policy. Please note that you
            have the right to withdraw consent at any time, in the manner specified by this Policy.
          </p>
          <p>
            By accepting this Policy, you also accept the <router-link to="/terms-of-service">Terms of Service
            </router-link>. You acknowledge and agree that you
            have reached the age allowing you to enter into such binding agreement in the country of your residence or
            domicile, or that you have your parent or legal guardian’s permission (if required by the applicable law).
            Child (or a legal minor) is a person that has not reached the minimum age required to provide the consent
            for data collection and processing in accordance with the applicable laws of the respective country.
          </p>
          <p>
            We may update this Privacy Policy by posting a notice in the Project before such changes take effect.
          </p>
          <p>
            This Policy describes:
          </p>
          <ul>
            <li>What data we process;</li>
            <li>Whom we may share your data with;</li>
            <li>Purpose of data processing;</li>
            <li>How long do we store data;</li>
            <li>How do we protect your data;</li>
            <li>How you can manage the data.</li>
          </ul>
          <p>
            1. <i>PROCESSED DATA</i> <br />
            1.1. In general, by <b>“Data”</b> we mean any information that identifies, relates to, describes, or is
            reasonably
            capable of being associated with a particular individual. By the <b>“Data”</b>, we also refer to any
            information
            that relates to you and usage of the Services (as defined in the Terms of Service). All information of your
            activity in the Project and/or Services, processed by us, where it is necessary for providing access to the
            Project and/or Services, is hereinafter referred to as the <b>“Account”</b>.
          </p>
          <p>
            Despite the fact that the data processed by us may not include data of the “personal data” category, we
            treat all information associated with your Account as personal data. We collect following personal data
            related to users of our Services:
          </p>
          <ul>
            <li>Your IP address and unique mobile device identification numbers (such as your device ID, advertising ID,
              android Id);</li>
            <li>Broad location data (e.g. country Code);</li>
            <li>Data for advertising and analytics purposes (gpsAdid, Idfv / Idfa);</li>
            <li>Data about your device (Name, RAM, Resolution, OS, OS version, device language);</li>
            <li>Details of orders (transaction currency (including cryptocurrency), amount spent,
              date, time, vouchers or offers used);</li>
            <li>Time zone;</li>
            <li>Data about your account, we create a specific ID for you when you use the Services. We store your
              actions inside the Project, including waste of resources, behavioural activity;</li>
            <li>Contact information (such as name or nickname or email or other identification information);</li>
            <li>Data from platforms that the Project run on (such as to verify payment);</li>
            <li>Your messages to the Services (such as chat logs and player support tickets) any
              feedback you submitted about your experience with us; and/or</li>
            <li>Other data you choose to give us.</li>
          </ul>
          <p>
            We collect your personal data on the following lawful basis:
          <ul>
            <li>the data necessary to provide you with access to the Project or the provision of our
              Services on the basis of the Contract;</li>
            <li>the data necessary for marketing or analytical purposes on the basis of your consent.</li>
            <li>Methods of collecting information (categories of sources used in the collection).</li>
          </ul>
          </p>
          <p>
            1.2. Data you provide by yourself
            <br>
            We include the following information that you provide to this type of data:
          <ul>
            <li>date of creating an Account;</li>
            <li>your use of social functions and channels for communicating with other people in the
              Project;</li>
            <li>purchases or financial transactions, such as in-house purchases and/or received
              prizes;</li>
            <li>technical and support services.</li>
          </ul>
          </p>
          <p>
            <b>WARNING!</b> We are not responsible for the relevance, correctness, completeness or quality of the
            information
            you provide. When you place the data indicated independently, we
            proceed on the presumption that you have acted in good faith, accept the specified information as relevant,
            correct, complete and reliable. If you indicate inaccurate and/or irrelevant information, access to a number
            of Services may be limited for you, and a technical support and support services regarding the processing of
            personal data is suspended until the confirmation of your ownership of the specified information. These
            measures are necessary to ensure the protection of your data.
          </p>
          <p>
            1.3. Information we receive or generate as a result of your use of the Project.
            We may collect data related to your use of the Project, to your use of our services and the nature of this
            use and to your devices. The collection of such information is necessary for the analysis of possible
            technical errors in the Project and their timely elimination. The processing of information is related to
            the social nature of our Project.
          </p>
          <p>
            1.4. Information collected from external sources.
            We may receive information about you and your actions from third parties when you connect external accounts;
            from advertisers or service providers about your contacts and interactions with them.
          </p>
          <p>
            1.5. Cookies
            Cookies are text files with small pieces of data — like a username and password — that are used to identify
            your device as you use a network. Specific cookies known as HTTP cookies are used to identify specific users
            and improve your web browsing experience. Data stored in a cookie is created by the server upon your
            connection. This data is labelled with an ID, unique to you and your device.
          </p>
          <p>
            2. <i>PROVIDING DATA TO THIRD PARTIES</i>
            <br />
            We may share your information with the following types of third parties:
          <ul>
            <li>technical and customer support providers who assist us in the provision of the Services;</li>
            <li>third party software providers, including ‘software as a service’ solution providers, where the provider
              hosts the relevant personal data on our behalf;</li>
            <li>analytical services that help us develop and improve the Project;</li>
            <li>providers that help us generate and collate reviews in relation to our goods and
              services;</li>
            <li>advertising and promotional agencies and those organisations or online platforms
              selected by us to carry out marketing campaigns on our behalf and to advertise their
              own products or services that may be of interest to you; and/or</li>
            <li>professional advisers such as solicitors, accountants, tax advisors, auditors, and
              insurance brokers;</li>
            <li>service providers that assist us in providing our services.</li>
          </ul>
          </p>
          <p>
            You can get a non-exhaustive list of such companies here: ANNEX to the Privacy Policy.
          </p>
          <p>
            We may share your personal data with our affiliates and partners in the EU / EEA and outside the EU / EEA.
            If the transfer of data outside the EU / EEA is not regulated by an EU Commission sufficiency decision, we
            base it on the legal guarantees set out in Article 46 <i>etal. seqq.</i> GDPR. This mainly includes EU
            Commission-approved Standard Contractual Clauses, which we have strengthened with additional security
            measures such as additional individual risk assessment, additional contractual guarantees, and technical
            guarantees, including additional encryption or pseudonymization, to enable international transfers with
            Project affiliates and partners outside the EU/EEA.
          </p>
          <p>
            Learn more about the EU Commission’s approved
            <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-prot
            ection/standard-contractual-clauses-scc_en" target="_blank">Standard Contractual Clauses </a>.
          </p>
          <p>
            We may share personal data with external providers or service providers or providers whom we engage to
            perform services or functions on our behalf and in accordance with our instructions.
          </p>
          <p>
            If these providers are established within the EU, we ensure that they are contractually bound to comply with
            EU data protection regulations. We also guarantee in our contracts with these organisations that they only
            Process Personal Data in accordance with our instructions and provide consistent services and protect the
            integrity and confidentiality of your personal data entrusted to them. We may also disclose personal
            information to our advisers, consultants, law enforcement and other government agencies (such as tax and
            social security authorities), police, prosecutors, and courts. All these recipients are themselves
            responsible for complying with EU data protection regulations. Some of the suppliers we work with are
            located outside the European Economic Area. If the EU Commission has not recognized them as providing
            adequate protection of personal data, we rely on the legal guarantees described above.
          </p>
          <p>
            These companies may access and process your data in accordance with their own privacy policies, being an
            equivalent data controller. We encourage you to review their privacy policies to learn more about how they
            process data.
          </p>
          <p>
            We may disclose your personal data as permitted by law to investigate, prevent, or act regarding illegal
            activities, suspected fraud, violation of our intellectual property rights, situations involving potential
            threats to the physical safety of any person, violation of our Terms and Conditions or other agreements, or
            as required by law.
          </p>
          <p>
            Please feel free to contact us for additional information on third country data transfers as well as our
            safeguards and supplementary security measures.
          </p>
          <p>
            3. <i>REASONS FOR DATA PROCESSING</i>
            <br>
            We process your data only when necessary to provide access to the Project and/or Services, while taking
            reasonable security measures to protect your data from loss, misuse and unauthorised access, disclosure,
            modification or destruction.
          </p>
          <p>
            With respect to the users covered by the GDPR, we use the data collected through the Project and/or Services
            for the purposes of our legitimate interests in accordance with Article 6(1)(f) of the GDPR, as well as for
            the purposes stipulated in the Terms of Service and this Policy. E.g., we may use information we collect:
          </p>
          <ul>
            <li> to provide our Services or information you request, as well as to process and complete any
              transactions;</li>
            <li> to respond to your submissions, questions, comments, requests and complaints, as well as to provide
              customer service;</li>
            <li> to monitor and analyse the Project’s use and trends or to personalise and improve the Project or user
              experience on our websites, such as providing ads, Content (as defined in the Terms of Service) or
              features that match their profiles or interests as well as to increase the Project’s functionality and
              user friendliness;</li>
            <li> to provide gifts and rewards that you may win in the Project, etc.;</li>
            <li> to send you confirmations, updates, security alerts as well as support and
              administrative messages and otherwise;</li>
            <li> to facilitate your use of and our administration and operation of our web sites; and</li>
            <li> any other purpose for which the information is collected.</li>
          </ul>
          <p>
            Data is collected when you provide it within the Project and/or Services. Indirect collection shall be
            allowed only on an anonymous basis.
          </p>
          <p>
            4. <i>TERM OF PROCESSING AND STORAGE</i>
            <br>
            We process and store data during the Project period and within 30 (thirty) days from the confirmation of the
            request for deletion, except for the information that is necessary to fulfil the requirements of applicable
            law (for example, in the field of taxation and accounting) and anonymous information. We do not control the
            data published by you or copied by other users, as well as the data published in the chat of the Project (if
            applicable) or on thematic groups and forums. After deleting your data, your anonymized account can be saved
            in the Project if it is used in conjunction with other users.
          </p>
          <p>
            Information containing personal data of users (if applicable), collected or created by providing technical
            support to users, is stored during the period of the User Account that has applied for support in order to
            provide an optimal solution to issues and problems. Information that does not contain personal data can be
            stored for the entire duration of the Project.
          </p>
          <p>
            5. <i>DATA SECURITY AND PROTECTION</i>
            <br>
            The Project respects the confidentiality of your data and strives to ensure the highest level of protection.
            Despite the measures we are taking to protect your information, any measures that we apply will not have any
            effect if you neglect the reasonable data security requirements.
          </p>
          <p>
            The project implemented reliable means of protection to ensure the security of your data. We do everything
            possible for your safety when using the Project and are constantly improving our information protection
            methods. As some means of protecting information from unauthorised access, alteration, disclosure or
            destruction, we use the following methods:
          </p>
          <ul>
            <li>data encryption during storage and transmission;</li>
            <li> we carry out two-stage user authentication when requesting an action with the data;</li>
            <li> we improve the techniques and methods of collecting, storing and processing the data;</li>
            <li> access to the data, in encrypted and impersonal form, have only authorized employees, consultants or
              interested groups of people who need access to this information to perform their duties;</li>
            <li> all persons having access to the data are briefed on working with data, their knowledge and skills are
              systematically being checked.</li>
          </ul>
          <p>
            In spite of all measures taken by us, your careful attitude in respect of the Account and access to it has a
            great importance in the safety of your data. To prevent unauthorized access to your data and Account, we
            strongly recommend you not to share access to the account to any third parties and to comply with the rules
            of the Project and use of the Services. In case of your violation of the Terms of Use of the Services, we
            will not be able to guarantee the security of your data.
          </p>
          <p>
            <b>WARNING!</b> The following actions are prohibited by this Policy and the Terms of Use of the Services,
            and may also violate the confidentiality of your data:
          </p>
          <ul>
            <li> transfer of information about the Account and/or access rights to third parties. Please note that our
              official representative will never request access to your Account;</li>
            <li>the use of unauthorised third-party programs, the use of robot programs and/or programmes that change
              the Project’s process, including assistance in the process. In addition to the fact that the use of such
              programmes is expressly prohibited by the Terms of Use, they may contain malicious software that could
              harm the security of
              your data;</li>
            <li>the acquisition of assets from third parties. By acquiring assets bypassing the Terms
              of Service, you can become a victim of fraudsters.</li>
          </ul>
          <p>
            6. <i>DATA MANAGEMENT</i>
            <br>
            You have all the technical capabilities to determine how the Data shall be used. We are constantly improving
            the data management methods available to you. You can form a request within the Project by sending a request
            in the Help section. You are entitled to obtain information from us on how we handle your personal data, to
            see copies of all personal data held by us and to request that your personal data is amended, corrected or
            deleted from our systems. You can also limit, restrict or object to the processing of your data.
          </p>
          <p>
            For exercising your rights, you can:
          </p>
          <ul>
            <li>at any time withdraw your consent for data processing given in full or in part;</li>
            <li>access to your Data received by us;</li>
            <li>require limitation of data processing for the time required to verify the reliability of
              the information provided;</li>
            <li>change the data. You may discretionarily determine the data, which shall be
              associated with your Account.</li>
            <li>object to the processing of your data. If you believe that we have no legitimate reason for processing
              your data, please contact us using the contacts provided below. You may also file a complaint with the
              local supervisory authority;</li>
            <li>delete your Account and data related therewith.</li>
          </ul>
          <p>
            <i>
              The procedure for withdrawing consent to the processing and deletion of data:
            </i>
          </p>
          <p>
            PLEASE NOTE that the data is used by us solely for the purpose of providing you access to the Project and
            improving the quality of the Services. By deleting Account data, you will lose access to the Project and
            your data will be permanently deleted.
          </p>
          <p>
            The order of execution of the request:
          </p>
          <ol>
            <li>An initial request that meets the requirements of this Policy can be sent through the contact form in
              the Project in the section “Support” / “Privacy protection request”.</li>
            <li>Confirmation of the request — carried out by you through the specified and verified email address.</li>
            <li>Execution of the request — usually carried out within 30 (thirty) days from the date of confirmation
              of the request. This period is necessary for organizational and technical measures to fulfil your
              request and, if necessary, may require more time, for which we shall notify you in writing.</li>
          </ol>
          <p>PLEASE NOTE that we reserve the right to refuse to fulfil the request, if the ownership of the data is not
            confirmed by the User and/or the request affects the rights and freedoms of others.
          </p>
          <p>If you have lost access to the Project and do not have the technical ability to perform actions to confirm
            ownership of the Data, you can send a letter to the address:
          </p>
          <p>Recipient: bul. Tsar Osvoboditel 8, office 13, 1000, Sofia, Bulgaria</p>
          <p>In the letter you must provide information, by which we can uniquely identify you and the ownership of the
            Account data.
          </p>
          <p>FULL NAME AND CONTACT DETAILS OF THE AUTHORISED DATA PROTECTION MANAGER FROM MENTAL FUTURE LTD:</p>
          <p>
            User Data Protection Manager: <br>
            Personal Data Expert <br>
            Nikolay Mihaylov
          </p>
          <p>
            <b>Email:</b> <a href="mailto:privacy@mentalfuture.com">privacy@mentalfuture.com</a>
          </p>
          <p>
            7. <i>LOCAL LEGISLATION</i>
            <br>
            We provide you with access to international projects and strive to comply with applicable local laws. Please
            note that our Services are provided “as is” and you should inform yourself of the relevant applicable laws.
            By continuing your use of the Project, you represent and warrant that your use of the Project is not
            contrary to the applicable law and Terms of Service.
          </p>
          <p>
            8. <i>FINAL PROVISIONS</i>
            We reserve the right to modify this Policy at any time, so please review it on a periodic basis.
          </p>
          <p>
            This Policy is made in the English language.
          </p>
          <p style="text-align: center;">
            <b>
              ANNEX TO THE PRIVACY POLICY OF THE HERO WARS PROJECT (THE “PROJECT”)
              <br>
              Our Partner Organizations and Service Providers
            </b>
          </p>
          <p>
            <i>
              For technical purposes:
            </i>
          </p>
          <table>
            <thead>
              <tr>
                <th>Counterparty name</th>
                <th>Link to the counterparty's privacy policy</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Xsola</td>
                <td><a href="https://xsolla.com/privacypolicy/" target="_blank">https://xsolla.com/privacypolicy/</a>
                </td>
              </tr>
              <tr>
                <td>Meya</td>
                <td><a href="https://www.meya.ai/privacy" target="_blank">https://www.meya.ai/privacy</a></td>
              </tr>
              <tr>
                <td>Google</td>
                <td><a href="https://policies.google.com/privacy"
                    target="_blank">https://policies.google.com/privacy</a></td>
              </tr>
              <tr>
                <td>MaestroQA</td>
                <td><a href="https://global-uploads.webflow.com" target="_blank">https://global-uploads.webflow.com</a>
                </td>
              </tr>
              <tr>
                <td>Jira</td>
                <td><a href="https://www.atlassian.com/legal/privacy-policy"
                    target="_blank">https://www.atlassian.com/legal/privacy-policy</a></td>
              </tr>
              <tr>
                <td>Amazon</td>
                <td><a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=GX7NJQ4ZB8MHFRNJ"
                    target="_blank">https://www.amazon.com/gp/help/customer/display.html?nodeId=GX7NJQ4ZB8MHFRNJ</a>
                </td>
              </tr>
              <tr>
                <td>Zendesk</td>
                <td><a href="https://www.zendesk.co.uk/company/agreements-and-terms/privacy-policy/"
                    target="_blank">https://www.zendesk.co.uk/company/agreements-and-terms/privacy-policy/</a></td>
              </tr>
              <tr>
                <td>Sweethawk</td>
                <td><a href="https://sweethawk.co/privacy" target="_blank">https://sweethawk.co/privacy</a></td>
              </tr>
            </tbody>
          </table>
          <p>
            <i>
              For marketing and analytical purposes:
            </i>
          </p>
          <table>
            <thead>
              <tr>
                <th>Counterparty name</th>
                <th>Link to the counterparty's privacy policy</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Facebook</td>
                <td>
                  <a href="https://www.facebook.com/privacy/explanation" target="_blank">
                    https://www.facebook.com/privacy/explanation
                  </a>
                </td>
              </tr>
              <tr>
                <td>Google</td>
                <td>
                  <a href="https://policies.google.com/privacy" target="_blank">
                    https://policies.google.com/privacy
                  </a>
                </td>
              </tr>
              <tr>
                <td>DV360</td>
                <td>
                  <a href="https://policies.google.com/privacy" target="_blank">
                    https://policies.google.com/privacy
                  </a>
                </td>
              </tr>
              <tr>
                <td>Taboola</td>
                <td>
                  <a href="https://www.taboola.com/policies/privacy-policy" target="_blank">
                    https://www.taboola.com/policies/privacy-policy
                  </a>
                </td>
              </tr>
              <tr>
                <td>VK</td>
                <td>
                  <a href="" target="_blank">
                    https://m.vk.com/privacy?api_view=1&cc=CN&lang=en
                  </a>
                </td>
              </tr>
              <tr>
                <td>MyTarget</td>
                <td>
                  <a href="https://target.my.com/help/partners/privacygdpr/en" target="_blank">
                    https://target.my.com/help/partners/privacygdpr/en
                  </a>
                </td>
              </tr>
              <tr>
                <td>Yandex</td>
                <td>
                  <a href="https://yandex.com/company/privacy" target="_blank">
                    https://yandex.com/company/privacy
                  </a>
                </td>
              </tr>
              <tr>
                <td>CPMStar</td>
                <td>
                  <a href="https://www.iubenda.com/blog/cpmstar-privacy-policy-clause-iubenda/" target="_blank">
                    https://www.iubenda.com/blog/cpmstar-privacy-policy-clause-iubenda/
                  </a>
                </td>
              </tr>
              <tr>
                <td>Gamesight</td>
                <td>
                  <a href="https://gamesight.io/gdpr" target="_blank">
                    https://gamesight.io/gdpr
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            FAITHFULLY YOURS,
            <br>
            <br>
            ...
            <br>
            Email: <a href="mailto:info@mental-future.com">info@mental-future.com</a>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer.vue";

export default {
  props: ["user"],
  name: "PrivacyAndPolicyGlobal",
  components: { Header, Footer },
};
</script>

<style lang="scss" scoped>
.privacy-and-policy {

  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 25px;
  }

  &__top-title {
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
    padding-bottom: 36px;

    @media (max-width: 991px) {
      padding-bottom: 24px;
    }
  }

  &__privacy-policy-wrapper {
    max-width: 848px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    overflow: auto;

    @media (max-width: 991px) {
      padding: 0 24px;
    }
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
    margin: 16px 0;

    b {
      font-weight: 700;
    }

    a {
      text-decoration: underline;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }

  li {
    margin-left: 10px;
    color: white;
  }

  table {
    text-align: left;
    color: white;
    overflow: auto;
  }

  th,
  td {
    padding: 5px;
    border: 1px solid white;
    white-space: nowrap;
  }
}
</style>
import {
  getCustomQuiz,
  getQuiz,
  getQuizFields,
  getSingleQuiz,
} from '../../services/quiz.service'
import { TOTAL_TEST } from '@/components/quiz/quiz-data'

const createQuizForm = () => ({
  tension: 50,
  emotions: [],
  challenges: [],
  guilt: 50,
  concentration: 50,
  mood: 50,
  higher_energy_levels: [],
  stress: [],
  sleep_habits: [],
  happiness: [],
  higher_motivation: [],
  higher_confidence: [],
  content_type: [],
  custom_quiz: {},
  feel: 50,
  goal: [],
  appetite: 50,
  energy_rate: 50,
  feeling_sad: 50,
  effects: 50,
  thoughts_nervous: 50,
  negativity: 50,
  worry: 50,
  fear_afraid: 50,
})

const state = () => ({
  fields: {
    goal: [],
  },
  quiz: {},
  quizData: {},
  progress: 1,
  quizForm: createQuizForm(),
  totalTest: TOTAL_TEST,
  isQuizLoading: false,
  isQuizDataLoading: false,
  isSingleQuizLoading: false,
})

const actions = {
  async fetchQuizFields({ commit }) {
    return getQuizFields().then((res) => {
      commit('SET_FIELDS', res)
    })
  },
  async fetchQuiz({ commit, dispatch }, params) {
    commit('SET_QUIZ_LOADING', true)
    return getQuiz(params)
      .then((res) => {
        commit('SET_QUIZ', res)
        if (res.type === 'Lead Quiz' || res.type === 'Direct Sales') {
          dispatch('fetchCustomQuiz', res.quiz)
        }
        // localStorage.setItem('statistic_id', res.statistic_id)
      })
      .finally(() => {
        commit('SET_QUIZ_LOADING', false)
      })
  },
  async fetchCustomQuiz({ commit, dispatch }, id) {
    commit('SET_QUIZ_DATA_LOADING', true)
    return getCustomQuiz(id)
      .then((res) => {
        commit('SET_QUIZ_DATA', res)
      })
      .finally(() => {
        commit('SET_QUIZ_DATA_LOADING', false)
      })
  },

  async fetchSingleQuiz({ commit, dispatch }, id) {
    commit('SET_SINGLE_QUIZ_LOADING', true)
    return getCustomQuiz(id)
      .then((res) => {
        commit('SET_QUIZ_DATA', res)
        dispatch('fetchStatisticId', res.id)
      })
      .finally(() => {
        commit('SET_SINGLE_QUIZ_LOADING', false)
      })
  },

  async fetchStatisticId({ commit, dispatch }, id) {
    return getSingleQuiz(id).then((res) => {
      commit('SET_QUIZ', res)
    })
  },
}

const mutations = {
  SET_FIELDS(state, data) {
    state.fields = data
  },
  SET_QUIZ(state, data) {
    state.quiz = data
  },
  SET_QUIZ_DATA(state, data) {
    state.quizData = data
  },
  SET_TOTAL_TEST(state, data) {
    state.totalTest = data
  },
  SET_PROGRESS(state, data) {
    state.progress = data
  },
  SET_QUIZ_LOADING(state, data) {
    state.isQuizLoading = data
  },
  SET_QUIZ_DATA_LOADING(state, data) {
    state.isQuizDataLoading = data
  },
  SET_QUIZ_FORM(state, data) {
    state.quizForm = data
  },
  SET_SINGLE_QUIZ_LOADING(state, data) {
    state.isSingleQuizLoading = data
  },
}

const getters = {
  progressValue: ({ progress, totalTest }) => {
    return (progress * 100) / totalTest
  },
  isCustomQuiz: ({ quizData }) => {
    return quizData?.type === 'Direct Sales' || quizData?.type === 'Lead Quiz'
  },
}

export const quiz = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

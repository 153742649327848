export const TOTAL_TEST = 15

export const gender = {
  MALE: 'Male',
  FEMALE: 'Female',
  NON_BINARY: 'Non-binary',
}

export const genderTitles = {
  [gender.MALE]: 'men',
  [gender.FEMALE]: 'women',
  [gender.NON_BINARY]: 'non-binary people',
}

export const genderIcon = {
  [gender.MALE]: `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.2148 15.0449L35.0371 1.22266" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.8128 35.2975C19.3584 35.2975 24.6646 29.9912 24.6646 23.4456C24.6646 16.9 19.3584 11.5938 12.8128 11.5938C6.26719 11.5938 0.960938 16.9 0.960938 23.4456C0.960938 29.9912 6.26719 35.2975 12.8128 35.2975Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M35.0344 11.593V1.22266H24.6641" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  [gender.FEMALE]: `<svg width="22" height="36" viewBox="0 0 22 36" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.9993 21.9634C16.7267 21.9634 21.3696 17.3204 21.3696 11.593C21.3696 5.86563 16.7267 1.22266 10.9993 1.22266C5.27188 1.22266 0.628906 5.86563 0.628906 11.593C0.628906 17.3204 5.27188 21.9634 10.9993 21.9634Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M11 21.9648V35.2982" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6.55469 30.8516H15.4436" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `,
  [gender.NON_BINARY]: `<svg width="33" height="36" viewBox="0 0 33 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_641_10578)">
<path d="M13.0011 21.3208C11.5475 20.1182 10.5562 18.4484 10.1964 16.5964C9.83665 14.7445 10.1307 12.825 11.0283 11.1657C11.926 9.50631 13.3717 8.21008 15.1187 7.49778C16.8656 6.78549 18.8057 6.70117 20.6077 7.25979C22.4097 7.81841 23.962 8.98501 24.9997 10.5606C26.0375 12.1361 26.4964 14.0233 26.298 15.8995C26.0997 17.7756 25.2564 19.5247 23.9122 20.8485C22.5679 22.1722 20.806 22.9884 18.927 23.1579" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.4969 11.7347C15.9433 12.9394 16.927 14.6086 17.2803 16.4576C17.6336 18.3066 17.3347 20.2207 16.4343 21.8739C15.534 23.5271 14.0881 24.8171 12.3432 25.5234C10.5982 26.2297 8.66237 26.3087 6.86561 25.7473C5.06884 25.1858 3.52248 24.0185 2.4902 22.4443C1.45792 20.8701 1.00361 18.9865 1.2048 17.1148C1.40599 15.2431 2.25023 13.4991 3.59349 12.1803C4.93675 10.8614 6.69588 10.0496 8.57093 9.88281" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.1113 9.11299L32.2595 0.964844" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M32.2599 6.89077V0.964844H26.334" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.55664 26.1484V35.0373" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.59375 32.0742H11.5197" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_641_10578">
<rect width="32.5926" height="35.5556" fill="white" transform="translate(0.408203 0.222656)"/>
</clipPath>
</defs>
</svg>
`,
}

export const goal = {
  REDUCE_STRESS: 'Reduce Anxiety & Panic',
  INCREASE_HAPPINESS: 'Stop Depression',
  INCREASE_ENERGY: 'Stop Procrastination',
  INCREASE_CONFIDENCE: 'Overcome Breakup',
  INCREASE_MOTIVATION: 'ADHD',
  SLEEP_BETTER: 'Sleep Better',
}

export const goalTitleLeads = {
  [goal.REDUCE_STRESS]: 'reducing anxiety & panic',
  [goal.INCREASE_HAPPINESS]: 'stopping depression',
  [goal.INCREASE_ENERGY]: 'stopping procrastination',
  [goal.INCREASE_CONFIDENCE]: 'overcoming breakup',
  [goal.INCREASE_MOTIVATION]: 'manifesting wealth',
  [goal.SLEEP_BETTER]: 'sleeping better',
}

export const goalTitles = {
  [goal.REDUCE_STRESS]: 'to reduce anxiety & panic',
  [goal.INCREASE_HAPPINESS]: 'to stop depression',
  [goal.INCREASE_ENERGY]: 'to stop procrastination',
  [goal.INCREASE_CONFIDENCE]: 'to overcome breakup',
  [goal.INCREASE_MOTIVATION]: 'to manifest wealth',
  [goal.SLEEP_BETTER]: 'to sleep better',
}

export const goalIcon = {
  [goal.INCREASE_ENERGY]: `<svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_641_10536)">
    <path d="M19.0592 8.41035C19.0592 3.41035 14.3092 0.500442 9.99925 0.500442C8.74323 0.488268 7.49749 0.727664 6.33546 1.20454C5.17343 1.68142 4.11864 2.38608 3.23326 3.27705C2.34788 4.16802 1.64983 5.22728 1.18028 6.39229C0.710726 7.5573 0.479168 8.80459 0.49925 10.0605C0.49925 13.1105 1.49925 15.9604 4.49925 17.7404V23.5004H14.4992V20.0004C16.4992 20.0004 17.4993 19.8704 18.2193 19.1704C19.1193 18.2704 19.0292 14.4804 19.0292 14.4804C19.0292 14.4804 19.8792 14.4804 20.5292 14.4804C20.6414 14.4747 20.7508 14.444 20.8495 14.3903C20.9482 14.3367 21.0335 14.2614 21.0993 14.1704C21.1922 13.9742 21.2302 13.7566 21.2092 13.5405C21.3192 12.0005 19.0592 9.21035 19.0592 8.41035Z" stroke="white" stroke-linejoin="round"/>
    <path d="M9.6107 3.5H13.1507L11.4307 7.5H14.0907L8.3107 16.2L9.4507 10.5H6.7207L9.6107 3.5Z" stroke="white" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_641_10536">
    <rect width="21.71" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    `,
  [goal.INCREASE_CONFIDENCE]: `<svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_641_10529)">
    <path d="M20 14.3205C20.3978 14.3205 20.7794 14.4786 21.0607 14.7599C21.342 15.0412 21.5 15.4227 21.5 15.8205C21.5 16.2184 21.342 16.5999 21.0607 16.8812C20.7794 17.1625 20.3978 17.3205 20 17.3205H19C19.3978 17.3205 19.7794 17.4786 20.0607 17.7599C20.342 18.0412 20.5 18.4227 20.5 18.8205C20.5 19.6505 19.83 19.8205 19 19.8205H12.5C9.65 19.8205 9 19.3205 5.5 18.8205V10.3205C7.95 10.3205 12 5.82053 12 1.82053C12 0.240526 14.19 -0.349503 15 2.5405C15.5 4.3205 14 7.82053 14 7.82053H22C22.3978 7.82053 22.7794 7.97855 23.0607 8.25986C23.342 8.54116 23.5 8.9227 23.5 9.32053C23.5 10.1505 22.83 11.3205 22 11.3205H21C21.3978 11.3205 21.7794 11.4786 22.0607 11.7599C22.342 12.0412 22.5 12.4227 22.5 12.8205C22.5 13.2184 22.342 13.5999 22.0607 13.8812C21.7794 14.1625 21.3978 14.3205 21 14.3205H20Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.5 8.82031H0.5V20.8203H5.5V8.82031Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.50001 18.3203C3.40112 18.3203 3.30447 18.3496 3.22224 18.4045C3.14002 18.4595 3.07594 18.5377 3.0381 18.629C3.00025 18.7204 2.9903 18.8209 3.00959 18.9178C3.02889 19.0148 3.07651 19.1039 3.14644 19.1738C3.21636 19.2438 3.30549 19.2914 3.40248 19.3107C3.49947 19.33 3.59999 19.3201 3.69136 19.2822C3.78272 19.2444 3.86078 19.1804 3.91572 19.0981C3.97066 19.0159 4.00001 18.9192 4.00001 18.8203C4.00001 18.6877 3.94736 18.5606 3.85359 18.4668C3.75982 18.373 3.63262 18.3203 3.50001 18.3203Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_641_10529">
    <rect width="24" height="21.32" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    `,
  [goal.INCREASE_MOTIVATION]: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5 23.5V19.5H15.5V15.5H19.5V11.5H23.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.5 23.5L23.5 17.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20.5 17.5H23.5V20.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.5 18V16L9.5 13V10L11.5 12L14.14 10.49C14.2493 10.4263 14.3445 10.3411 14.4202 10.2397C14.4958 10.1384 14.5501 10.0228 14.58 9.8999C14.6093 9.77743 14.6129 9.6503 14.5905 9.52637C14.5682 9.40243 14.5203 9.28442 14.45 9.17993C14.3252 8.99301 14.1369 8.85766 13.92 8.79883C13.703 8.74 13.4722 8.76185 13.27 8.86011L12 9.5L10.59 7.38989C10.4077 7.11662 10.1608 6.89253 9.87132 6.73731C9.5818 6.58208 9.2585 6.50061 8.92999 6.5H4.33C4.06609 6.49979 3.80475 6.55182 3.56104 6.65308C3.31732 6.75434 3.09606 6.90292 2.91 7.09009L0.5 9.5V12.5C0.5 12.7652 0.605356 13.0195 0.792892 13.207C0.980429 13.3946 1.23478 13.5 1.5 13.5C1.76522 13.5 2.01957 13.3946 2.20711 13.207C2.39464 13.0195 2.5 12.7652 2.5 12.5V10.5801L4.5 9V12.8L3 21.5H5L7 14.5L8.5 17V18.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7 4.5C8.10457 4.5 9 3.60457 9 2.5C9 1.39543 8.10457 0.5 7 0.5C5.89543 0.5 5 1.39543 5 2.5C5 3.60457 5.89543 4.5 7 4.5Z" stroke="white" stroke-linejoin="round"/>
    </svg>
    `,
  [goal.INCREASE_HAPPINESS]: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.5 13C19.5 14.9891 18.7098 16.8967 17.3033 18.3032C15.8968 19.7097 13.9891 20.5 12 20.5C10.0109 20.5 8.10321 19.7097 6.69669 18.3032C5.29016 16.8967 4.5 14.9891 4.5 13" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.5 10C5.5 9.46957 5.71071 8.96077 6.08578 8.58569C6.46086 8.21062 6.96957 8 7.5 8C8.03043 8 8.53914 8.21062 8.91422 8.58569C9.28929 8.96077 9.5 9.46957 9.5 10" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.5 10C18.5 9.46957 18.2893 8.96077 17.9142 8.58569C17.5391 8.21062 17.0304 8 16.5 8C15.9696 8 15.4609 8.21062 15.0858 8.58569C14.7107 8.96077 14.5 9.46957 14.5 10" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `,
  [goal.SLEEP_BETTER]: `<svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_641_10504)">
    <path d="M16.09 5.59L19 4.5L16.09 3.41L15 0.5L13.91 3.41L11 4.5L13.91 5.59L15 8.5L16.09 5.59Z" stroke="white" stroke-linejoin="round"/>
    <path d="M20.55 11.04L22 10.5L20.55 9.95L20 8.5L19.45 9.95L18 10.5L19.45 11.04L20 12.5L20.55 11.04Z" stroke="white" stroke-linejoin="round"/>
    <path d="M16.9996 15C15.6696 14.9988 14.3585 14.6859 13.1713 14.0864C11.9841 13.487 10.9539 12.6176 10.1635 11.5481C9.37299 10.4786 8.84418 9.23856 8.61948 7.92773C8.39477 6.61691 8.48043 5.27174 8.86957 4C7.30238 4.02513 5.77265 4.48305 4.44948 5.32324C3.1263 6.16344 2.06121 7.35329 1.37192 8.76099C0.68263 10.1687 0.395987 11.7394 0.543685 13.2998C0.691384 14.8602 1.26767 16.3494 2.20883 17.6028C3.15 18.8562 4.4194 19.8248 5.87672 20.4019C7.33404 20.9789 8.92252 21.1419 10.4666 20.8726C12.0107 20.6032 13.4502 19.9121 14.6261 18.8757C15.802 17.8394 16.6684 16.498 17.1296 15H16.9996Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_641_10504">
    <rect width="22.5" height="21.5" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    `,
  [goal.REDUCE_STRESS]: `<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 10C9.82843 10 10.5 9.32843 10.5 8.5C10.5 7.67157 9.82843 7 9 7C8.17157 7 7.5 7.67157 7.5 8.5C7.5 9.32843 8.17157 10 9 10Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 10C16.8284 10 17.5 9.32843 17.5 8.5C17.5 7.67157 16.8284 7 16 7C15.1716 7 14.5 7.67157 14.5 8.5C14.5 9.32843 15.1716 10 16 10Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.75 15.6396C18.0685 16.674 17.1405 17.5226 16.0494 18.1091C14.9584 18.6957 13.7387 19.0018 12.5 18.9998" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.5 23.5C18.8513 23.5 24 18.3513 24 12C24 5.64873 18.8513 0.5 12.5 0.5C6.14873 0.5 1 5.64873 1 12C1 18.3513 6.14873 23.5 12.5 23.5Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `,
}

export const goalNewIcons = {
  [goal.REDUCE_STRESS]: require('@/assets/icons/faces/😰.png'),
  [goal.INCREASE_HAPPINESS]: require('@/assets/icons/faces/😢.png'),
  [goal.INCREASE_ENERGY]: require('@/assets/icons/faces/🥱.png'),
  [goal.INCREASE_CONFIDENCE]: require('@/assets/icons/faces/💔.png'),
  [goal.INCREASE_MOTIVATION]: require('@/assets/icons/faces/😵_💫.png'),
  [goal.SLEEP_BETTER]: require('@/assets/icons/faces/😴.png'),
}

export const age = {
  S20: '20s',
  S30: '30s',
  S40: '40s',
  S50: '50+',
}

export const ageTitles = {
  [age.S20]: '20s',
  [age.S30]: '30s',
  [age.S40]: '40s',
  [age.S50]: '50s or above',
}

export const ageLabel = {
  [age.S20]: '18-29',
  [age.S30]: '30-39',
  [age.S40]: '40-49',
  [age.S50]: '50+',
}

export const typicalDay = {
  AT_THE_OFFICE: 'At the office',
  DAILY_LONG_WALKS: 'Daily long walks',
  MOSTLY_AT_HOME: 'Mostly at home',
  PHYSICAL_WORK: 'Physical work',
}

export const typicalDayImage = {
  [typicalDay.AT_THE_OFFICE]: require('@/static/quiz/static/at-the-office.png'),
  [typicalDay.DAILY_LONG_WALKS]: require('@/static/quiz/static/daily-long-walks.png'),
  [typicalDay.MOSTLY_AT_HOME]: require('@/static/quiz/static/mostly-at-home.png'),
  [typicalDay.PHYSICAL_WORK]: require('@/static/quiz/static/physical-work.png'),
}

export const method = {
  OUR_WAY: 'Our way',
  CONVENTIONAL_WAY: 'Conventional way',
}

export const methodImage = {
  [method.OUR_WAY]: {
    male: require('@/static/quiz/static/method-man-left.png'),
    female: require('@/static/quiz/static/method-women-left.png'),
  },
  [method.CONVENTIONAL_WAY]: {
    male: require('@/static/quiz/static/method-man-right.png'),
    female: require('@/static/quiz/static/method-women-right.png'),
  },
}

export const emotions = {
  STRESS: 'Stress',
  SELF_DOUBT: 'Self-doubt',
  SADNESS: 'Sadness',
  SHAME: 'Shame',
  EMPTINESS: 'Emptiness',
  HEALTH_WORRIES: 'Health Worries',
}

export const emotionsIcon = {
  [emotions.STRESS]: require('@/static/quiz/icons/stress.svg'),
  [emotions.SELF_DOUBT]: require('@/static/quiz/icons/self-doubt.svg'),
  [emotions.SHAME]: require('@/static/quiz/icons/shame.svg'),
  [emotions.SADNESS]: require('@/static/quiz/icons/sadness.svg'),
  [emotions.EMPTINESS]: require('@/static/quiz/icons/emptyness.svg'),
  [emotions.HEALTH_WORRIES]: require('@/static/quiz/icons/health-worries.svg'),
}

export const emotionsIconNew = {
  [emotions.STRESS]: require('@/assets/icons/faces/😰.png'),
  [emotions.SELF_DOUBT]: require('@/assets/icons/faces/😕.png'),
  [emotions.SHAME]: require('@/assets/icons/faces/😳.png'),
  [emotions.SADNESS]: require('@/assets/icons/faces/😢.png'),
  [emotions.EMPTINESS]: require('@/assets/icons/faces/😐.png'),
  [emotions.HEALTH_WORRIES]: require('@/assets/icons/faces/😷.png'),
}

export const challenges = {
  WORK_WORRIES: 'Work Worries',
  OVERTHINKING: 'Overthinking',
  RELATIONSHIP_WORRIES: 'Relationship Worries',
  RISING_ANXIETY: 'Rising Anxiety',
  INTRUSIVE_THOUGHTS: 'Intrusive Thoughts',
  HEALTH_WORRIES: 'Health Worries',
}

export const challengesIcon = {
  [challenges.WORK_WORRIES]: require('@/static/quiz/icons/work-worries.svg'),
  [challenges.OVERTHINKING]: require('@/static/quiz/icons/overthinking.svg'),
  [challenges.RELATIONSHIP_WORRIES]: require('@/static/quiz/icons/relationship-worries.svg'),
  [challenges.RISING_ANXIETY]: require('@/static/quiz/icons/rising-anxiety.svg'),
  [challenges.INTRUSIVE_THOUGHTS]: require('@/static/quiz/icons/intrusive-thoughts.svg'),
  [challenges.HEALTH_WORRIES]: require('@/static/quiz/icons/health-worries.svg'),
}

export const challengesIconNew = {
  [challenges.WORK_WORRIES]: require('@/assets/icons/faces/😟.png'),
  [challenges.OVERTHINKING]: require('@/assets/icons/faces/😵_💫.png'),
  [challenges.RELATIONSHIP_WORRIES]: require('@/assets/icons/faces/😔.png'),
  [challenges.RISING_ANXIETY]: require('@/assets/icons/faces/😰.png'),
  [challenges.INTRUSIVE_THOUGHTS]: require('@/assets/icons/faces/😓.png'),
  [challenges.HEALTH_WORRIES]: require('@/assets/icons/faces/😷.png'),
}

export const quizComments = {
  [goal.INCREASE_ENERGY]: {
    title: `Of our users reported <br />
    improved <span class="strong">energy</span>`,
    percent: '90%',
    comments: [
      {
        id: 1,
        text: '“I was reading about natural ways to boost energy and stumbled upon this app. I was more than impressed! After following the practices I now have sustained energy throughout the day!”',
        name: 'Ryan',
        image: require('@/assets/img/ryan.png'),
      },
      {
        id: 2,
        text: '“Since I started using Mental Future I noticed an improvement in my energy and feel awesome! Now I have steady, lasting energy that lasts throughout the day.”',
        name: 'Shirley',
        image: require('@/assets/img/shirley.png'),
      },
    ],
  },
  [goal.INCREASE_CONFIDENCE]: {
    title: `Of our users reported <br />
    improved <span class="strong">confidence</span>`,
    percent: '90%',
    comments: [
      {
        id: 1,
        text: '“Before my self-esteem was shattered and I was feeling lost. Within weeks of using Mental Future my confidence started to return. I am now going out with friends and dating again!”',
        name: 'Kathleen',
        image: require('@/assets/img/Kathleen.png'),
      },
      {
        id: 2,
        text: '“Mental Future is so easy and straightforward to use. Thanks to the practices in the app I managed to get out of my personal shelter and outline a plan for my life where I did not have one before!”',
        name: 'Tyler',
        image: require('@/assets/img/Tyler.png'),
      },
    ],
  },
  [goal.INCREASE_MOTIVATION]: {
    title: `Of our users reported <br />
    improved <span class="strong">motivation</span>`,
    percent: '90%',
    comments: [
      {
        id: 1,
        text: '“I recommend this app to anyone. I now bounce out of bed each morning determined to make the day ahead great - before I used to dread the alarm in the morning!”',
        name: 'Adam',
        image: require('@/assets/img/Adam.png'),
      },
      {
        id: 2,
        text: '“Guys this app helped me rewire my brain. Not only does it teach how to increase motivation, it teaches so much more about life in general!”',
        name: 'Julie',
        image: require('@/assets/img/Julie.png'),
      },
    ],
  },
  [goal.INCREASE_HAPPINESS]: {
    title: `Of our users reported <br />
    improved <span class="strong">happiness</span>`,
    percent: '90%',
    comments: [
      {
        id: 1,
        text: '“I used to feel sad all day and was getting no enjoyment out of any activity. I saw an ad for this app and decided to give it a go - after practicing gratitude I realised that there are a lot of positives to life.”',
        name: 'Noah',
        image: require('@/assets/img/Noah.png'),
      },
      {
        id: 2,
        text: '“My life is now unrecognizable compared to how it was before I started using Mental Future. I now volunteer at a shelter and the joy I get from helping other people has given my life new meaning.”',
        name: 'Jaqueline',
        image: require('@/assets/img/Jaqueline.png'),
      },
    ],
  },
  [goal.SLEEP_BETTER]: {
    title: `Of our users reported <br />
    improved <span class="strong">sleeping habits</span>`,
    percent: '90%',
    comments: [
      {
        id: 1,
        text: `“Thanks to you I'm now sleeping well the majority of the time, even when sharing a bed with my boyfriend. I use the Mental Future app regularly to relax my mind in the evening.”`,
        name: 'Patricia',
        image: require('@/assets/img/Patricia.png'),
      },
      {
        id: 2,
        text: '“Your app was recommended to me by a relative, and it has helped me so much; and not just in sleeping, but in calming my mind for a state conducive to long, productive study periods.”',
        name: 'Kenneth',
        image: require('@/assets/img/Kenneth.png'),
      },
    ],
  },
  [goal.REDUCE_STRESS]: {
    title: `Of our users reported <br />
    improved <span class="strong">reduced stress</span>`,
    percent: '90%',
    comments: [
      {
        id: 1,
        text: `“Within weeks of using the app and putting the practices into use my anxiety was lifted and I stopped the influx of negative thoughts throughout the day!”`,
        name: 'Ashley',
        image: require('@/assets/img/Ashley.png'),
      },
      {
        id: 2,
        text: '“Constant stress was negatively impacting my job performance. Thanks to Mental Future I managed to turn things around - bosses noticed my change in attitude and promoted me to supervisor!”',
        name: 'Patrick',
        image: require('@/assets/img/Patrick.png'),
      },
    ],
  },
}

export const newQuizComments = {
  [goal.SLEEP_BETTER]: {
    title: `Of our users reported and<br/> improvement in sleep quality`,
    percent: '87%',
    comments: [
      {
        id: 1,
        text: 'Before discovering “Mental Future”, quality sleep had become a distant memory. I was plagued by an erratic sleep schedule and had accepted it as my new normal. Everything changed once I started using the app. Its most stellar feature is the rich library of sleep sounds and guided meditations, which never fail to lull me into peaceful slumber!',
        name: 'Olivia',
        image: require('@/assets/img/olivia.png'),
      },
      {
        id: 2,
        text: 'Three months into using “Mental Future” and I am sleeping better than ever! It’s not just an app - it’s a pocket companion which gives me all the tools needed for a good night’s sleep. I wholeheartedly recommend it to anyone, struggling with falling asleep!',
        name: 'David',
        image: require('@/assets/img/david.png'),
      },
    ],
  },
  [goal.INCREASE_MOTIVATION]: {
    title: `Of our users reported improvement<br/> in ADHD management`,
    percent: '89%',
    comments: [
      {
        id: 1,
        text: 'Navigating life with ADHD has always been a challenge. I had tried various methods and tools but none resonated with me as well as “Mental Future”. The mindfulness exercises and guided meditation here are truly transformational and have been particularly effective in helping me manage anxiety at times of peak stress.',
        name: 'Shelby',
        image: require('@/assets/img/Shelby.png'),
      },
      {
        id: 2,
        text: 'Since I started using “Mental Future” I’ve noticed a noticeable improvement in my ability to manage and cope with my ADHD symptoms. My days are more productive and I’ve found ways to effectively maintain focus. It feels like I’ve finally regained control over my life!',
        name: 'Ethan',
        image: require('@/assets/img/Ethan.png'),
      },
    ],
  },
  [goal.REDUCE_STRESS]: {
    title: `Of our users reported an decrease<br/> in anxiety levels`,
    percent: '91%',
    comments: [
      {
        id: 1,
        text: '“Mental Future” offers an array of anxiety management tools, each of which has been designed to provide instant relief in situations of high stress. The app’s guided deep breathing meditations have been my go-to remedy for acute anxiety episodes. Within moments of following the calming rhythm of breathing in and out my panic symptoms disappear!',
        name: 'Pablo',
        image: require('@/assets/img/Pablo.png'),
      },
      {
        id: 2,
        text: 'I recommend “Mental Future” to anyone struggling with panic attacks! Ever since I started using the app I’ve experienced fewer panic attacks and a noticeable reduction in my overall anxiety levels. I feel like I am finally regaining control over my mental health!',
        name: 'Heather',
        image: require('@/assets/img/Heather.png'),
      },
    ],
  },
  [goal.INCREASE_HAPPINESS]: {
    title: `Of our users reported a reduction of<br/> depression-related symptoms`,
    percent: '86%',
    comments: [
      {
        id: 1,
        text: 'Before discovering “Mental Future” it felt like my life was in a state of constant emotional turmoil. This unique application has become a critical part of my recovery process. I found the cognitive behavioral therapy (CBT) lessons and exercises instrumental in helping me identify negative thought patterns and replace them with positive ones.',
        name: 'Susan',
        image: require('@/assets/img/Susan.png'),
      },
      {
        id: 2,
        text: 'Since incorporating “Mental Future” into my daily routine I’ve noticed a profound improvement in my mood and overall outlook. What truly sets the app apart is its library of meditations specifically designed for depressive moods. It’s evident that a lot of thought has been put into making the app comforting for users battling depression.',
        name: 'Richard',
        image: require('@/assets/img/Richard.png'),
      },
    ],
  },
  [goal.INCREASE_CONFIDENCE]: {
    title: `Of our users reported an increase to<br/> post-breakup confidence`,
    percent: '92%',
    comments: [
      {
        id: 1,
        text: 'I can’t recommend “Mental Future” enough to anyone suffering from a heartbreak! The app’s calming mindfulness exercises helped me regain composure during moments of intense distress and the daily affirmations enabled me to rebuild my self-esteem. Mental Future doesn’t promise an instant cure but offers something much more valuable - a step-by-step guide to understanding, accepting and overcoming a breakup.',
        name: 'Jake',
        image: require('@/assets/img/Jake.png'),
      },
      {
        id: 2,
        text: 'From the very moment I downloaded the app I knew I had found something special. What stood out to me was the empathetic and non-judgemental approach it took to dealing with breakup. Every feature and piece of content in it has been carefully crafted to support emotional healing!',
        name: 'Skyler',
        image: require('@/assets/img/Skyler.png'),
      },
    ],
  },
  [goal.INCREASE_ENERGY]: {
    title: `Of our users reported a reduction of<br/> procrastination-related behaviors`,
    percent: '88%',
    comments: [
      {
        id: 1,
        text: 'Before “Mental Future” I found myself stuck in the frustrating loop of constantly postponing tasks and accumulating workload. This in turn made me develop a constant sense of anxiety due to overdue deadlines and unfulfilled promises. When I stumbled upon “Mental Future” everything changed. Its impact extends beyond mere task completion - “Mental Future” has ushered in a newfound sense of tranquillity in my life.',
        name: 'Wayne',
        image: require('@/assets/img/Wayne.png'),
      },
      {
        id: 2,
        text: 'This app is truly marvellous - it completely transformed my entire mindset towards time management. I am no longer haunted by the ghosts of unfinished tasks, nor do I dread the mounting workload. I finally learned to turn the ‘I’ll do it later’ into ‘I’ll do it now’. I recommend it to anyone seeking to conquer procrastination!',
        name: 'Cheryl',
        image: require('@/assets/img/Cheryl.png'),
      },
    ],
  },
}

export const quizMentalCards = [
  {
    name: 'Meditation',
    bgImage: require('@/assets/img/quiz-meditation.png'),
    icon: require('@/assets/icons/mental-future-s.svg'),
  },
  {
    name: 'Music',
    bgImage: require('@/assets/img/quiz-music.png'),
    icon: require('@/assets/icons/quiz-music.svg'),
  },
  {
    name: 'Nature Sounds',
    bgImage: require('@/assets/img/quiz-sounds.png'),
    icon: require('@/assets/icons/quiz-leaf.svg'),
  },
]

export const mentalRecap = [
  {
    icon: require('@/assets/icons/goal-heart.svg'),
    title: 'Your goal/s',
  },
  {
    icon: require('@/assets/icons/boy-cup.svg'),
    title: 'Improvements you want to achieve',
  },
  {
    icon: require('@/assets/icons/face-crying.svg'),
    title: 'Main struggles',
  },
  {
    icon: require('@/assets/icons/brain-mental.svg'),
    title: 'Your current mindset about overcoming your struggles',
  },
]

export const goalItems = {
  [goal.INCREASE_ENERGY]: {
    title: 'Energy',
    subtitle: 'Having enough energy is essential for daily performance',
    question: 'What would higher energy levels benefit you most in?',
    answer_key: 'higher_energy_levels',
    model: 'higher_energy_levels',
  },
  [goal.INCREASE_CONFIDENCE]: {
    title: 'Confidence',
    subtitle: 'Having higher confidence is essential to achieving success',
    question:
      'What area of your daily life would higher confidence be most beneficial in?',
    answer_key: 'higher_attribute_repeat',
    model: 'higher_confidence',
  },
  [goal.INCREASE_MOTIVATION]: {
    title: 'Motivation',
    subtitle: 'Having a higher motivation crucial to achieving your goals',
    question:
      'What area of your daily life would higher motivation be most beneficial in?',
    answer_key: 'higher_attribute_repeat',
    model: 'higher_motivation',
  },
  [goal.INCREASE_HAPPINESS]: {
    title: 'Happiness',
    subtitle: 'Feeling joyful will increase your overall quality of life',
    question: 'How would feeling joyful benefit you most?',
    answer_key: 'higher_attribute_repeat',
    model: 'happiness',
  },
  [goal.SLEEP_BETTER]: {
    title: 'Sleep',
    subtitle: 'Getting just enough sleep is essential for feeling healthier',
    question: 'What do you want to improve in your sleeping habits',
    answer_key: 'sleep_habits',
    model: 'sleep_habits',
  },
  [goal.REDUCE_STRESS]: {
    title: 'Stress',
    subtitle:
      'Reducing stress will massively improve your mental health and quality of life',
    question: 'What areas would reducing stress benefit most in your life?',
    answer_key: 'higher_attribute_repeat',
    model: 'stress',
  },
}

export const anxietyScores = {
  [goal.INCREASE_ENERGY]: [87, 88],
  [goal.INCREASE_MOTIVATION]: [88, 89],
  [goal.SLEEP_BETTER]: [90, 91],
  [goal.REDUCE_STRESS]: [91, 92],
  [goal.INCREASE_CONFIDENCE]: [92, 94],
  [goal.INCREASE_HAPPINESS]: [93, 94],
}

export const answersQuestions = {
  ['goal']: {
    title: 'Goal',
    subtitle: 'What are your primary goals?',
  },
  ['age']: {
    title: 'Age',
    subtitle: 'Select your age',
  },
  ['typical_day']: {
    title: 'Workplace',
    subtitle: 'Workplace',
  },
  ['gender']: {
    title: 'Gender',
    subtitle: 'Choose your gender',
  },
}

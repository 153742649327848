<template>
  <div class="checkout-benefits-of-meditation">
    <div class="checkout-benefits-of-meditation__container container-checkout">
      <div class="checkout-benefits-of-meditation__wrapper">
        <div
          class="checkout-benefits-of-meditation__description"
          v-html="description"
        ></div>
        <div class="checkout-benefits-of-meditation__title">
          {{ title }}
        </div>
        <checkout-get-started-now />
        <checkout-timer />
        <div
          class="checkout-benefits-of-meditation__subtitle"
          v-html="subtitle"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckoutGetStartedNow from './CheckoutGetStartedNow.vue'
import CheckoutTimer from './CheckoutTimer.vue'

export default {
  name: 'CheckoutBenefitsOfMeditation',
  components: {
    CheckoutGetStartedNow,
    CheckoutTimer,
  },
  data() {
    return {
      title: 'Feel the benefits of our techniques with only 5 minutes a day!',
      description:
        'If you want mental health care to be a bigger part of your life, so you can really<br/> enjoy all its benefits, then join our step-by-step revolutionary program',
      subtitle:
        'Don’t just take our word for it... there are <b>countless other people who have already joined</b>',
    }
  },
}
</script>

<style lang="scss">
.checkout-benefits-of-meditation {
  padding-bottom: 100px;
  padding-bottom: 70px;

  .checkout-get-started-now {
    max-width: 580px;
    margin-inline: auto;
  }

  &__wrapper {
    position: relative;
    padding: 56px 15px 72px;
    background-color: var(--color-white-01);
    border-radius: 12px;
  }

  &__title,
  &__description,
  &__subtitle {
    text-align: center;
  }

  &__description {
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    padding-bottom: 54px;
  }

  &__title {
    font-weight: 800;
    font-size: 48px;
    line-height: 56px;
    padding-bottom: 52px;
    max-width: 866px;
    margin-inline: auto;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    max-width: 725px;
    margin-inline: auto;

    b {
      font-weight: 800;
    }
  }
}
</style>

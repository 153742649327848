<template>
  <div class="checkout-timer">
    <div class="checkout-timer__container">
      <div class="checkout-timer__title">Available only for</div>
      <div class="checkout-timer__items">
        <div class="checkout-timer__item">
          <p class="checkout-timer__item-value">{{ days }}</p>
          Days
        </div>
        <div class="checkout-timer__item">
          <p class="checkout-timer__item-value">{{ hours }}</p>
          Hours
        </div>
        <div class="checkout-timer__item">
          <p class="checkout-timer__item-value">{{ minutes }}</p>
          Minutes
        </div>
        <div class="checkout-timer__item">
          <p class="checkout-timer__item-value">{{ seconds }}</p>
          Seconds
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import moment from 'moment'

export default {
  name: 'CheckoutTimer',
  setup() {
    const days = ref('00')
    const hours = ref('00')
    const minutes = ref('00')
    const seconds = ref('00')
    const lunchDate = moment('04:00:00', 'HH:mm:ss')

    const formatData = (d) => {
      return d < 10 ? '0' + d : d
    }

    setInterval(() => {
      seconds.value = formatData(lunchDate.seconds())
      minutes.value = formatData(lunchDate.minutes())
      hours.value = formatData(lunchDate.hours())
      lunchDate.subtract(1, 'second')
    }, 1000)

    return { days, hours, minutes, seconds }
  },
}
</script>

<style lang="scss">
.checkout-timer {
  padding-top: 72px;
  padding-bottom: 96px;

  &__container {
    max-width: 1130px;
    padding: 0 15px;
    margin-inline: auto;
  }

  &__title {
    font-weight: 800;
    font-size: 36px;
    line-height: 49px;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 68px;
  }

  &__items {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    width: 220px;
    height: 220px;
    padding: 30px 15px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    text-transform: uppercase;
  }

  &__item-value {
    font-weight: 700;
    font-size: 96px;
    line-height: 1;
    width: 100%;
    text-align: center;
  }
}
</style>

<template>
  <label class="package-card" :class="isBestValue && 'package-card--best'">
    <div v-if="isBestValue" class="package-card__best">Best Value</div>
    <a-radio
      name="package"
      :value="packagePlan"
      class="package-card__checkbox"
    ></a-radio>
    <div class="package-card__left">
      <div class="package-card__head">
        <div class="package-card__title">{{ title }}</div>
        <div
          class="package-card__oldday"
          v-if="packagePlan !== purchaseTypes.LIFETIME"
        >
          {{ cardPackage.currency }} {{ oldPricePerDay }}
        </div>
      </div>
      <div class="package-card__center">
        <div class="package-card__price">
          <span class="package-card__price-old"
            >{{ cardPackage.currency }} {{ cardPackage.amount * 2 }}</span
          >
          <span
            class="package-card__price-new"
            v-if="packagePlan !== purchaseTypes.LIFETIME"
            >{{ cardPackage.currency }} {{ cardPackage.amount }}</span
          >
        </div>
        <div class="package-card__perday">
          {{ cardPackage.currency }} {{ newPricePerDay }}
        </div>
      </div>
      <div class="package-card__footer">
        <div class="package-card__desc">{{ desc }}</div>
        <div
          class="package-card__footer-right"
          v-if="packagePlan !== purchaseTypes.LIFETIME"
        >
          per day
        </div>
      </div>
    </div>
  </label>
</template>

<script setup>
import { cardCalculate } from '@/utils/cardCalculate'
import { purchaseTypes } from '@/data/constants'

const props = defineProps(['cardPackage', 'popular'])
const isBestValue =
  props.cardPackage.tags === 'Best Value' ||
  props.cardPackage.tags === 'Most Popular'

const { packagePlan, title, desc, oldPricePerDay, newPricePerDay } =
  cardCalculate(props.cardPackage)
</script>

<style scoped lang="scss">
.package-card {
  max-width: 402px;
  width: 100%;
  border-radius: 12px;
  background: #fff;
  padding: 40px 24px;
  color: #134e5e;
  position: relative;
  display: flex;
  align-items: center;

  &--best {
    @media (max-width: 1024px) {
      margin-top: 15px;
    }
  }

  &__best {
    position: absolute;
    left: 50%;
    top: -18px;
    transform: translateX(-50%);
    height: 38px;
    width: 70%;
    background: #134e5e;
    border-radius: 12px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 20px;
    font-style: normal;
    line-height: 21px;
    text-transform: uppercase;
  }

  :deep(.ant-radio-inner) {
    width: 24px;
    height: 24px;
    border-color: #134e5e;
  }

  :deep(.ant-radio-inner::after) {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    background-color: #134e5e;
  }

  :deep(.ant-radio-checked .ant-radio-inner) {
    border-color: #134e5e;
  }

  &__head {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__oldday {
    color: #134e5e;
    font-weight: 700;
    font-size: 14px;
    text-decoration: line-through;
  }

  &__center {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
  }

  &__perday {
    color: #134e5e;
    font-weight: 700;
    font-size: 28px;
    line-height: 1;
  }

  &__price {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 4px;

    &-old {
      font-weight: 700;
      text-decoration: line-through;
      color: #ff4757;
    }

    &-new {
      font-weight: 400;
    }
  }

  &__desc {
    color: #134e5e;
    font-weight: 700;
    font-size: 14px;
    opacity: 0.8;
  }

  &__left {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    line-height: 1;
  }

  &__checkbox {
    margin-right: 12px;
    width: 24px;
    height: 24px;

    .ant-radio-inner {
      border-color: rgba(#ffffff, 0.5);
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: #ffffff;
    }

    .ant-radio-inner {
      background: transparent;
    }

    .ant-radio-inner::after {
      background: var(--color-white);
      width: 30px;
      height: 30px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;

    &-right {
      color: #134e5e;
      font-weight: 700;
      font-size: 12px;
    }
  }

  @media (max-width: 767px) {
    padding: 24px 16px;

    &__best {
      font-size: 14px;
      height: 30px;
      top: -15px;
    }

    :deep(.ant-radio-inner) {
      width: 20px;
      height: 20px;
    }

    :deep(.ant-radio-inner::after) {
      width: 26px;
      height: 26px;
      margin-top: -13px;
      margin-left: -13px;
    }

    &__title {
      font-size: 16px;
    }

    &__desc {
      font-size: 12px;
    }

    &__perday {
      font-size: 20px;
    }
  }
}
</style>

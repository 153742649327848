// checkout-willing-to-do
<template>
  <div class="checkout-willing-to-do">
    <div class="checkout-willing-to-do__container container-checkout">
      <div class="checkout-willing-to-do__wrapper">
        <div class="checkout-willing-to-do__title">
          Here’s what we’re willing to do.
        </div>
        <div class="checkout-willing-to-do__texts">
          <p class="checkout-willing-to-do__text">
            We will not allow you to pay us the $1714.95.
          </p>
          <p class="checkout-willing-to-do__text">
            We will not even allow you to invest half of that amount.
          </p>
          <p
            class="checkout-willing-to-do__text checkout-willing-to-do__text--bold"
          >
            We will allow you to offset some of our costs and cover some of our
            expenses.
          </p>
        </div>
      </div>
      <div class="checkout-willing-to-do__taking">
        We’re taking a full <b>$1700 off.</b>
      </div>
      <div class="checkout-willing-to-do__price">14.95$</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutWillingToDo',
}
</script>

<style lang="scss">
.checkout-willing-to-do {
  &__wrapper {
    padding: 48px 0;
    background-color: var(--color-white-01);
    border-radius: 12px;
    text-align: center;
    margin-bottom: 72px;
  }

  &__title {
    font-weight: 800;
    font-size: 48px;
    line-height: 66px;
    padding-bottom: 36px;
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;

    &--bold {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__taking {
    font-weight: 400;
    font-size: 48px;
    line-height: 66px;
    text-align: center;
    padding-bottom: 50px;

    b {
      font-weight: 800;
    }
  }

  &__price {
    position: relative;
    font-weight: 800;
    font-size: 107px;
    line-height: 146px;
    text-align: center;
    max-width: 580px;
    width: 100%;
    margin-inline: auto;
    padding: 17px 0;
    border-radius: 12px;
    background-image: url('~@/static/checkout/price-bg.svg');
    background-size: 100%;
    background-position-y: 47%;
    background-position-x: center;
    background-repeat: no-repeat;
    z-index: 1;
  }
}
</style>

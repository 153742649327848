import axios from 'axios'

export const getUserWorkbooks = async () => {
  return await axios.get(`/user/workbook`)
}

export const getWorkbook = async (id) => {
  return await axios.get(`/workbook/${id}`, {
    params: { t: new Date().getTime() },
  })
}

export const getUserWorkbook = async (id) => {
  return await axios.get(`/user/workbook/${id}`, {
    params: { t: new Date().getTime() },
  })
}

export const createUserWorkbook = async (data) => {
  return await axios.post(`/user/workbook/`, data)
}

export const deleteUserWorkbook = async (id) => {
  return await axios.delete(`/user/workbook/${id}`)
}

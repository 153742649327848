<template>
  <section class="reviews">
    <div class="container container--content">
      <h2 class="reviews__title welcome-title">Over 2,003,459 Happy Users</h2>
      <ul class="reviews__list">
        <li class="reviews__list-item">
          <div class="reviews__list-item-stars">
            <svg
              width="152"
              height="24"
              viewBox="0 0 76 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2473_2858)">
                <path
                  d="M11.9999 4.33008C11.965 4.23344 11.901 4.14987 11.8169 4.09082C11.7327 4.03177 11.6324 4.00004 11.5296 4H8.33692L6.48534 0.280029C6.44064 0.196516 6.37437 0.126391 6.29341 0.0771484C6.21244 0.0279059 6.11977 0.00131521 6.025 0C5.93145 0.000954057 5.83992 0.0275246 5.76049 0.0769043C5.68107 0.126284 5.61679 0.196603 5.57461 0.280029L3.69304 4H0.500373C0.397867 4.00145 0.298146 4.03367 0.214246 4.09253C0.130345 4.15139 0.066121 4.23424 0.029949 4.33008C-0.00349486 4.42777 -0.00704913 4.53308 0.0196865 4.63281C0.0464222 4.73254 0.102262 4.82199 0.180099 4.88989L2.92237 7.16992L1.5312 11.3401C1.49559 11.4379 1.49207 11.5444 1.52112 11.6443C1.55017 11.7442 1.61034 11.8323 1.69283 11.8958C1.77533 11.9592 1.8759 11.9948 1.98 11.9973C2.0841 11.9999 2.18634 11.9693 2.27187 11.9099L6.00496 9.36011L9.71801 11.9099C9.80332 11.9688 9.9046 12.0005 10.0083 12.0005C10.112 12.0005 10.2132 11.9688 10.2985 11.9099C10.3861 11.8495 10.4506 11.7614 10.4815 11.6597C10.5124 11.558 10.5078 11.4489 10.4686 11.3501L9.07753 7.17993L11.8198 4.88989C11.9052 4.82755 11.9686 4.73977 12.001 4.63916C12.0333 4.53855 12.033 4.43047 11.9999 4.33008Z"
                  fill="white"
                />
              </g>
              <g clip-path="url(#clip1_2473_2858)">
                <path
                  d="M27.9999 4.33008C27.965 4.23344 27.901 4.14987 27.8169 4.09082C27.7327 4.03177 27.6324 4.00004 27.5296 4H24.3369L22.4853 0.280029C22.4406 0.196516 22.3744 0.126391 22.2934 0.0771484C22.2124 0.0279059 22.1198 0.00131521 22.025 0C21.9315 0.000954057 21.8399 0.0275246 21.7605 0.0769043C21.6811 0.126284 21.6168 0.196603 21.5746 0.280029L19.693 4H16.5004C16.3979 4.00145 16.2981 4.03367 16.2142 4.09253C16.1303 4.15139 16.0661 4.23424 16.0299 4.33008C15.9965 4.42777 15.993 4.53308 16.0197 4.63281C16.0464 4.73254 16.1023 4.82199 16.1801 4.88989L18.9224 7.16992L17.5312 11.3401C17.4956 11.4379 17.4921 11.5444 17.5211 11.6443C17.5502 11.7442 17.6103 11.8323 17.6928 11.8958C17.7753 11.9592 17.8759 11.9948 17.98 11.9973C18.0841 11.9999 18.1863 11.9693 18.2719 11.9099L22.005 9.36011L25.718 11.9099C25.8033 11.9688 25.9046 12.0005 26.0083 12.0005C26.112 12.0005 26.2132 11.9688 26.2985 11.9099C26.3861 11.8495 26.4506 11.7614 26.4815 11.6597C26.5124 11.558 26.5078 11.4489 26.4686 11.3501L25.0775 7.17993L27.8198 4.88989C27.9052 4.82755 27.9686 4.73977 28.001 4.63916C28.0333 4.53855 28.033 4.43047 27.9999 4.33008Z"
                  fill="white"
                />
              </g>
              <g clip-path="url(#clip2_2473_2858)">
                <path
                  d="M43.9999 4.33008C43.965 4.23344 43.901 4.14987 43.8169 4.09082C43.7327 4.03177 43.6324 4.00004 43.5296 4H40.3369L38.4853 0.280029C38.4406 0.196516 38.3744 0.126391 38.2934 0.0771484C38.2124 0.0279059 38.1198 0.00131521 38.025 0C37.9315 0.000954057 37.8399 0.0275246 37.7605 0.0769043C37.6811 0.126284 37.6168 0.196603 37.5746 0.280029L35.693 4H32.5004C32.3979 4.00145 32.2981 4.03367 32.2142 4.09253C32.1303 4.15139 32.0661 4.23424 32.0299 4.33008C31.9965 4.42777 31.993 4.53308 32.0197 4.63281C32.0464 4.73254 32.1023 4.82199 32.1801 4.88989L34.9224 7.16992L33.5312 11.3401C33.4956 11.4379 33.4921 11.5444 33.5211 11.6443C33.5502 11.7442 33.6103 11.8323 33.6928 11.8958C33.7753 11.9592 33.8759 11.9948 33.98 11.9973C34.0841 11.9999 34.1863 11.9693 34.2719 11.9099L38.005 9.36011L41.718 11.9099C41.8033 11.9688 41.9046 12.0005 42.0083 12.0005C42.112 12.0005 42.2132 11.9688 42.2985 11.9099C42.3861 11.8495 42.4506 11.7614 42.4815 11.6597C42.5124 11.558 42.5078 11.4489 42.4686 11.3501L41.0775 7.17993L43.8198 4.88989C43.9052 4.82755 43.9686 4.73977 44.001 4.63916C44.0333 4.53855 44.033 4.43047 43.9999 4.33008Z"
                  fill="white"
                />
              </g>
              <g clip-path="url(#clip3_2473_2858)">
                <path
                  d="M59.9999 4.33008C59.965 4.23344 59.901 4.14987 59.8169 4.09082C59.7327 4.03177 59.6324 4.00004 59.5296 4H56.3369L54.4853 0.280029C54.4406 0.196516 54.3744 0.126391 54.2934 0.0771484C54.2124 0.0279059 54.1198 0.00131521 54.025 0C53.9315 0.000954057 53.8399 0.0275246 53.7605 0.0769043C53.6811 0.126284 53.6168 0.196603 53.5746 0.280029L51.693 4H48.5004C48.3979 4.00145 48.2981 4.03367 48.2142 4.09253C48.1303 4.15139 48.0661 4.23424 48.0299 4.33008C47.9965 4.42777 47.993 4.53308 48.0197 4.63281C48.0464 4.73254 48.1023 4.82199 48.1801 4.88989L50.9224 7.16992L49.5312 11.3401C49.4956 11.4379 49.4921 11.5444 49.5211 11.6443C49.5502 11.7442 49.6103 11.8323 49.6928 11.8958C49.7753 11.9592 49.8759 11.9948 49.98 11.9973C50.0841 11.9999 50.1863 11.9693 50.2719 11.9099L54.005 9.36011L57.718 11.9099C57.8033 11.9688 57.9046 12.0005 58.0083 12.0005C58.112 12.0005 58.2132 11.9688 58.2985 11.9099C58.3861 11.8495 58.4506 11.7614 58.4815 11.6597C58.5124 11.558 58.5078 11.4489 58.4686 11.3501L57.0775 7.17993L59.8198 4.88989C59.9052 4.82755 59.9686 4.73977 60.001 4.63916C60.0333 4.53855 60.033 4.43047 59.9999 4.33008Z"
                  fill="white"
                />
              </g>
              <g clip-path="url(#clip4_2473_2858)">
                <path
                  d="M75.9999 4.33008C75.965 4.23344 75.901 4.14987 75.8169 4.09082C75.7327 4.03177 75.6324 4.00004 75.5296 4H72.3369L70.4853 0.280029C70.4406 0.196516 70.3744 0.126391 70.2934 0.0771484C70.2124 0.0279059 70.1198 0.00131521 70.025 0C69.9315 0.000954057 69.8399 0.0275246 69.7605 0.0769043C69.6811 0.126284 69.6168 0.196603 69.5746 0.280029L67.693 4H64.5004C64.3979 4.00145 64.2981 4.03367 64.2142 4.09253C64.1303 4.15139 64.0661 4.23424 64.0299 4.33008C63.9965 4.42777 63.993 4.53308 64.0197 4.63281C64.0464 4.73254 64.1023 4.82199 64.1801 4.88989L66.9224 7.16992L65.5312 11.3401C65.4956 11.4379 65.4921 11.5444 65.5211 11.6443C65.5502 11.7442 65.6103 11.8323 65.6928 11.8958C65.7753 11.9592 65.8759 11.9948 65.98 11.9973C66.0841 11.9999 66.1863 11.9693 66.2719 11.9099L70.005 9.36011L73.718 11.9099C73.8033 11.9688 73.9046 12.0005 74.0083 12.0005C74.112 12.0005 74.2132 11.9688 74.2985 11.9099C74.3861 11.8495 74.4506 11.7614 74.4815 11.6597C74.5124 11.558 74.5078 11.4489 74.4686 11.3501L73.0775 7.17993L75.8198 4.88989C75.9052 4.82755 75.9686 4.73977 76.001 4.63916C76.0333 4.53855 76.033 4.43047 75.9999 4.33008Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2473_2858">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
                <clipPath id="clip1_2473_2858">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(16)"
                  />
                </clipPath>
                <clipPath id="clip2_2473_2858">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(32)"
                  />
                </clipPath>
                <clipPath id="clip3_2473_2858">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(48)"
                  />
                </clipPath>
                <clipPath id="clip4_2473_2858">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(64)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <p class="reviews__list-item-text">
            Mental Future’s meditations and therapy course helped me start
            feeling calmer in just about 4 weeks. Over this period I noticed
            much more progress than I managed to make in the past 5 years of
            taking prescription drugs and anti-depressants. Thanks for doing
            this for people like me!
          </p>
          <div class="reviews__list-item-user">
            <img
              class="reviews__list-item-user-avatar"
              src="@/static/reviews/amanda-miller.png"
            />
            <div class="reviews__list-item-user-nickname">Amanda Miller</div>
          </div>
        </li>
        <li class="reviews__list-item">
          <div class="reviews__list-item-stars">
            <svg
              width="152"
              height="24"
              viewBox="0 0 76 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2473_2858)">
                <path
                  d="M11.9999 4.33008C11.965 4.23344 11.901 4.14987 11.8169 4.09082C11.7327 4.03177 11.6324 4.00004 11.5296 4H8.33692L6.48534 0.280029C6.44064 0.196516 6.37437 0.126391 6.29341 0.0771484C6.21244 0.0279059 6.11977 0.00131521 6.025 0C5.93145 0.000954057 5.83992 0.0275246 5.76049 0.0769043C5.68107 0.126284 5.61679 0.196603 5.57461 0.280029L3.69304 4H0.500373C0.397867 4.00145 0.298146 4.03367 0.214246 4.09253C0.130345 4.15139 0.066121 4.23424 0.029949 4.33008C-0.00349486 4.42777 -0.00704913 4.53308 0.0196865 4.63281C0.0464222 4.73254 0.102262 4.82199 0.180099 4.88989L2.92237 7.16992L1.5312 11.3401C1.49559 11.4379 1.49207 11.5444 1.52112 11.6443C1.55017 11.7442 1.61034 11.8323 1.69283 11.8958C1.77533 11.9592 1.8759 11.9948 1.98 11.9973C2.0841 11.9999 2.18634 11.9693 2.27187 11.9099L6.00496 9.36011L9.71801 11.9099C9.80332 11.9688 9.9046 12.0005 10.0083 12.0005C10.112 12.0005 10.2132 11.9688 10.2985 11.9099C10.3861 11.8495 10.4506 11.7614 10.4815 11.6597C10.5124 11.558 10.5078 11.4489 10.4686 11.3501L9.07753 7.17993L11.8198 4.88989C11.9052 4.82755 11.9686 4.73977 12.001 4.63916C12.0333 4.53855 12.033 4.43047 11.9999 4.33008Z"
                  fill="white"
                />
              </g>
              <g clip-path="url(#clip1_2473_2858)">
                <path
                  d="M27.9999 4.33008C27.965 4.23344 27.901 4.14987 27.8169 4.09082C27.7327 4.03177 27.6324 4.00004 27.5296 4H24.3369L22.4853 0.280029C22.4406 0.196516 22.3744 0.126391 22.2934 0.0771484C22.2124 0.0279059 22.1198 0.00131521 22.025 0C21.9315 0.000954057 21.8399 0.0275246 21.7605 0.0769043C21.6811 0.126284 21.6168 0.196603 21.5746 0.280029L19.693 4H16.5004C16.3979 4.00145 16.2981 4.03367 16.2142 4.09253C16.1303 4.15139 16.0661 4.23424 16.0299 4.33008C15.9965 4.42777 15.993 4.53308 16.0197 4.63281C16.0464 4.73254 16.1023 4.82199 16.1801 4.88989L18.9224 7.16992L17.5312 11.3401C17.4956 11.4379 17.4921 11.5444 17.5211 11.6443C17.5502 11.7442 17.6103 11.8323 17.6928 11.8958C17.7753 11.9592 17.8759 11.9948 17.98 11.9973C18.0841 11.9999 18.1863 11.9693 18.2719 11.9099L22.005 9.36011L25.718 11.9099C25.8033 11.9688 25.9046 12.0005 26.0083 12.0005C26.112 12.0005 26.2132 11.9688 26.2985 11.9099C26.3861 11.8495 26.4506 11.7614 26.4815 11.6597C26.5124 11.558 26.5078 11.4489 26.4686 11.3501L25.0775 7.17993L27.8198 4.88989C27.9052 4.82755 27.9686 4.73977 28.001 4.63916C28.0333 4.53855 28.033 4.43047 27.9999 4.33008Z"
                  fill="white"
                />
              </g>
              <g clip-path="url(#clip2_2473_2858)">
                <path
                  d="M43.9999 4.33008C43.965 4.23344 43.901 4.14987 43.8169 4.09082C43.7327 4.03177 43.6324 4.00004 43.5296 4H40.3369L38.4853 0.280029C38.4406 0.196516 38.3744 0.126391 38.2934 0.0771484C38.2124 0.0279059 38.1198 0.00131521 38.025 0C37.9315 0.000954057 37.8399 0.0275246 37.7605 0.0769043C37.6811 0.126284 37.6168 0.196603 37.5746 0.280029L35.693 4H32.5004C32.3979 4.00145 32.2981 4.03367 32.2142 4.09253C32.1303 4.15139 32.0661 4.23424 32.0299 4.33008C31.9965 4.42777 31.993 4.53308 32.0197 4.63281C32.0464 4.73254 32.1023 4.82199 32.1801 4.88989L34.9224 7.16992L33.5312 11.3401C33.4956 11.4379 33.4921 11.5444 33.5211 11.6443C33.5502 11.7442 33.6103 11.8323 33.6928 11.8958C33.7753 11.9592 33.8759 11.9948 33.98 11.9973C34.0841 11.9999 34.1863 11.9693 34.2719 11.9099L38.005 9.36011L41.718 11.9099C41.8033 11.9688 41.9046 12.0005 42.0083 12.0005C42.112 12.0005 42.2132 11.9688 42.2985 11.9099C42.3861 11.8495 42.4506 11.7614 42.4815 11.6597C42.5124 11.558 42.5078 11.4489 42.4686 11.3501L41.0775 7.17993L43.8198 4.88989C43.9052 4.82755 43.9686 4.73977 44.001 4.63916C44.0333 4.53855 44.033 4.43047 43.9999 4.33008Z"
                  fill="white"
                />
              </g>
              <g clip-path="url(#clip3_2473_2858)">
                <path
                  d="M59.9999 4.33008C59.965 4.23344 59.901 4.14987 59.8169 4.09082C59.7327 4.03177 59.6324 4.00004 59.5296 4H56.3369L54.4853 0.280029C54.4406 0.196516 54.3744 0.126391 54.2934 0.0771484C54.2124 0.0279059 54.1198 0.00131521 54.025 0C53.9315 0.000954057 53.8399 0.0275246 53.7605 0.0769043C53.6811 0.126284 53.6168 0.196603 53.5746 0.280029L51.693 4H48.5004C48.3979 4.00145 48.2981 4.03367 48.2142 4.09253C48.1303 4.15139 48.0661 4.23424 48.0299 4.33008C47.9965 4.42777 47.993 4.53308 48.0197 4.63281C48.0464 4.73254 48.1023 4.82199 48.1801 4.88989L50.9224 7.16992L49.5312 11.3401C49.4956 11.4379 49.4921 11.5444 49.5211 11.6443C49.5502 11.7442 49.6103 11.8323 49.6928 11.8958C49.7753 11.9592 49.8759 11.9948 49.98 11.9973C50.0841 11.9999 50.1863 11.9693 50.2719 11.9099L54.005 9.36011L57.718 11.9099C57.8033 11.9688 57.9046 12.0005 58.0083 12.0005C58.112 12.0005 58.2132 11.9688 58.2985 11.9099C58.3861 11.8495 58.4506 11.7614 58.4815 11.6597C58.5124 11.558 58.5078 11.4489 58.4686 11.3501L57.0775 7.17993L59.8198 4.88989C59.9052 4.82755 59.9686 4.73977 60.001 4.63916C60.0333 4.53855 60.033 4.43047 59.9999 4.33008Z"
                  fill="white"
                />
              </g>
              <g clip-path="url(#clip4_2473_2858)">
                <path
                  d="M75.9999 4.33008C75.965 4.23344 75.901 4.14987 75.8169 4.09082C75.7327 4.03177 75.6324 4.00004 75.5296 4H72.3369L70.4853 0.280029C70.4406 0.196516 70.3744 0.126391 70.2934 0.0771484C70.2124 0.0279059 70.1198 0.00131521 70.025 0C69.9315 0.000954057 69.8399 0.0275246 69.7605 0.0769043C69.6811 0.126284 69.6168 0.196603 69.5746 0.280029L67.693 4H64.5004C64.3979 4.00145 64.2981 4.03367 64.2142 4.09253C64.1303 4.15139 64.0661 4.23424 64.0299 4.33008C63.9965 4.42777 63.993 4.53308 64.0197 4.63281C64.0464 4.73254 64.1023 4.82199 64.1801 4.88989L66.9224 7.16992L65.5312 11.3401C65.4956 11.4379 65.4921 11.5444 65.5211 11.6443C65.5502 11.7442 65.6103 11.8323 65.6928 11.8958C65.7753 11.9592 65.8759 11.9948 65.98 11.9973C66.0841 11.9999 66.1863 11.9693 66.2719 11.9099L70.005 9.36011L73.718 11.9099C73.8033 11.9688 73.9046 12.0005 74.0083 12.0005C74.112 12.0005 74.2132 11.9688 74.2985 11.9099C74.3861 11.8495 74.4506 11.7614 74.4815 11.6597C74.5124 11.558 74.5078 11.4489 74.4686 11.3501L73.0775 7.17993L75.8198 4.88989C75.9052 4.82755 75.9686 4.73977 76.001 4.63916C76.0333 4.53855 76.033 4.43047 75.9999 4.33008Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2473_2858">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
                <clipPath id="clip1_2473_2858">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(16)"
                  />
                </clipPath>
                <clipPath id="clip2_2473_2858">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(32)"
                  />
                </clipPath>
                <clipPath id="clip3_2473_2858">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(48)"
                  />
                </clipPath>
                <clipPath id="clip4_2473_2858">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(64)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <p class="reviews__list-item-text">
            Staying awake all night was something I used to do almost every
            night. I was just unable to fall asleep at all. Insomnia was slowly
            decreasing my quality of life and i started having feelings of
            hopelessness, regret and anxiety. I’m so happy things have changed
            thanks to Mental Future. My life is much more meaningful now!
          </p>
          <div class="reviews__list-item-user">
            <img
              class="reviews__list-item-user-avatar"
              src="@/static/reviews/andrew-gordon.png"
            />
            <div class="reviews__list-item-user-nickname">Andrew Gordon</div>
          </div>
        </li>
        <li class="reviews__list-item">
          <div class="reviews__list-item-stars">
            <svg
              width="152"
              height="24"
              viewBox="0 0 76 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2473_2858)">
                <path
                  d="M11.9999 4.33008C11.965 4.23344 11.901 4.14987 11.8169 4.09082C11.7327 4.03177 11.6324 4.00004 11.5296 4H8.33692L6.48534 0.280029C6.44064 0.196516 6.37437 0.126391 6.29341 0.0771484C6.21244 0.0279059 6.11977 0.00131521 6.025 0C5.93145 0.000954057 5.83992 0.0275246 5.76049 0.0769043C5.68107 0.126284 5.61679 0.196603 5.57461 0.280029L3.69304 4H0.500373C0.397867 4.00145 0.298146 4.03367 0.214246 4.09253C0.130345 4.15139 0.066121 4.23424 0.029949 4.33008C-0.00349486 4.42777 -0.00704913 4.53308 0.0196865 4.63281C0.0464222 4.73254 0.102262 4.82199 0.180099 4.88989L2.92237 7.16992L1.5312 11.3401C1.49559 11.4379 1.49207 11.5444 1.52112 11.6443C1.55017 11.7442 1.61034 11.8323 1.69283 11.8958C1.77533 11.9592 1.8759 11.9948 1.98 11.9973C2.0841 11.9999 2.18634 11.9693 2.27187 11.9099L6.00496 9.36011L9.71801 11.9099C9.80332 11.9688 9.9046 12.0005 10.0083 12.0005C10.112 12.0005 10.2132 11.9688 10.2985 11.9099C10.3861 11.8495 10.4506 11.7614 10.4815 11.6597C10.5124 11.558 10.5078 11.4489 10.4686 11.3501L9.07753 7.17993L11.8198 4.88989C11.9052 4.82755 11.9686 4.73977 12.001 4.63916C12.0333 4.53855 12.033 4.43047 11.9999 4.33008Z"
                  fill="white"
                />
              </g>
              <g clip-path="url(#clip1_2473_2858)">
                <path
                  d="M27.9999 4.33008C27.965 4.23344 27.901 4.14987 27.8169 4.09082C27.7327 4.03177 27.6324 4.00004 27.5296 4H24.3369L22.4853 0.280029C22.4406 0.196516 22.3744 0.126391 22.2934 0.0771484C22.2124 0.0279059 22.1198 0.00131521 22.025 0C21.9315 0.000954057 21.8399 0.0275246 21.7605 0.0769043C21.6811 0.126284 21.6168 0.196603 21.5746 0.280029L19.693 4H16.5004C16.3979 4.00145 16.2981 4.03367 16.2142 4.09253C16.1303 4.15139 16.0661 4.23424 16.0299 4.33008C15.9965 4.42777 15.993 4.53308 16.0197 4.63281C16.0464 4.73254 16.1023 4.82199 16.1801 4.88989L18.9224 7.16992L17.5312 11.3401C17.4956 11.4379 17.4921 11.5444 17.5211 11.6443C17.5502 11.7442 17.6103 11.8323 17.6928 11.8958C17.7753 11.9592 17.8759 11.9948 17.98 11.9973C18.0841 11.9999 18.1863 11.9693 18.2719 11.9099L22.005 9.36011L25.718 11.9099C25.8033 11.9688 25.9046 12.0005 26.0083 12.0005C26.112 12.0005 26.2132 11.9688 26.2985 11.9099C26.3861 11.8495 26.4506 11.7614 26.4815 11.6597C26.5124 11.558 26.5078 11.4489 26.4686 11.3501L25.0775 7.17993L27.8198 4.88989C27.9052 4.82755 27.9686 4.73977 28.001 4.63916C28.0333 4.53855 28.033 4.43047 27.9999 4.33008Z"
                  fill="white"
                />
              </g>
              <g clip-path="url(#clip2_2473_2858)">
                <path
                  d="M43.9999 4.33008C43.965 4.23344 43.901 4.14987 43.8169 4.09082C43.7327 4.03177 43.6324 4.00004 43.5296 4H40.3369L38.4853 0.280029C38.4406 0.196516 38.3744 0.126391 38.2934 0.0771484C38.2124 0.0279059 38.1198 0.00131521 38.025 0C37.9315 0.000954057 37.8399 0.0275246 37.7605 0.0769043C37.6811 0.126284 37.6168 0.196603 37.5746 0.280029L35.693 4H32.5004C32.3979 4.00145 32.2981 4.03367 32.2142 4.09253C32.1303 4.15139 32.0661 4.23424 32.0299 4.33008C31.9965 4.42777 31.993 4.53308 32.0197 4.63281C32.0464 4.73254 32.1023 4.82199 32.1801 4.88989L34.9224 7.16992L33.5312 11.3401C33.4956 11.4379 33.4921 11.5444 33.5211 11.6443C33.5502 11.7442 33.6103 11.8323 33.6928 11.8958C33.7753 11.9592 33.8759 11.9948 33.98 11.9973C34.0841 11.9999 34.1863 11.9693 34.2719 11.9099L38.005 9.36011L41.718 11.9099C41.8033 11.9688 41.9046 12.0005 42.0083 12.0005C42.112 12.0005 42.2132 11.9688 42.2985 11.9099C42.3861 11.8495 42.4506 11.7614 42.4815 11.6597C42.5124 11.558 42.5078 11.4489 42.4686 11.3501L41.0775 7.17993L43.8198 4.88989C43.9052 4.82755 43.9686 4.73977 44.001 4.63916C44.0333 4.53855 44.033 4.43047 43.9999 4.33008Z"
                  fill="white"
                />
              </g>
              <g clip-path="url(#clip3_2473_2858)">
                <path
                  d="M59.9999 4.33008C59.965 4.23344 59.901 4.14987 59.8169 4.09082C59.7327 4.03177 59.6324 4.00004 59.5296 4H56.3369L54.4853 0.280029C54.4406 0.196516 54.3744 0.126391 54.2934 0.0771484C54.2124 0.0279059 54.1198 0.00131521 54.025 0C53.9315 0.000954057 53.8399 0.0275246 53.7605 0.0769043C53.6811 0.126284 53.6168 0.196603 53.5746 0.280029L51.693 4H48.5004C48.3979 4.00145 48.2981 4.03367 48.2142 4.09253C48.1303 4.15139 48.0661 4.23424 48.0299 4.33008C47.9965 4.42777 47.993 4.53308 48.0197 4.63281C48.0464 4.73254 48.1023 4.82199 48.1801 4.88989L50.9224 7.16992L49.5312 11.3401C49.4956 11.4379 49.4921 11.5444 49.5211 11.6443C49.5502 11.7442 49.6103 11.8323 49.6928 11.8958C49.7753 11.9592 49.8759 11.9948 49.98 11.9973C50.0841 11.9999 50.1863 11.9693 50.2719 11.9099L54.005 9.36011L57.718 11.9099C57.8033 11.9688 57.9046 12.0005 58.0083 12.0005C58.112 12.0005 58.2132 11.9688 58.2985 11.9099C58.3861 11.8495 58.4506 11.7614 58.4815 11.6597C58.5124 11.558 58.5078 11.4489 58.4686 11.3501L57.0775 7.17993L59.8198 4.88989C59.9052 4.82755 59.9686 4.73977 60.001 4.63916C60.0333 4.53855 60.033 4.43047 59.9999 4.33008Z"
                  fill="white"
                />
              </g>
              <g clip-path="url(#clip4_2473_2858)">
                <path
                  d="M75.9999 4.33008C75.965 4.23344 75.901 4.14987 75.8169 4.09082C75.7327 4.03177 75.6324 4.00004 75.5296 4H72.3369L70.4853 0.280029C70.4406 0.196516 70.3744 0.126391 70.2934 0.0771484C70.2124 0.0279059 70.1198 0.00131521 70.025 0C69.9315 0.000954057 69.8399 0.0275246 69.7605 0.0769043C69.6811 0.126284 69.6168 0.196603 69.5746 0.280029L67.693 4H64.5004C64.3979 4.00145 64.2981 4.03367 64.2142 4.09253C64.1303 4.15139 64.0661 4.23424 64.0299 4.33008C63.9965 4.42777 63.993 4.53308 64.0197 4.63281C64.0464 4.73254 64.1023 4.82199 64.1801 4.88989L66.9224 7.16992L65.5312 11.3401C65.4956 11.4379 65.4921 11.5444 65.5211 11.6443C65.5502 11.7442 65.6103 11.8323 65.6928 11.8958C65.7753 11.9592 65.8759 11.9948 65.98 11.9973C66.0841 11.9999 66.1863 11.9693 66.2719 11.9099L70.005 9.36011L73.718 11.9099C73.8033 11.9688 73.9046 12.0005 74.0083 12.0005C74.112 12.0005 74.2132 11.9688 74.2985 11.9099C74.3861 11.8495 74.4506 11.7614 74.4815 11.6597C74.5124 11.558 74.5078 11.4489 74.4686 11.3501L73.0775 7.17993L75.8198 4.88989C75.9052 4.82755 75.9686 4.73977 76.001 4.63916C76.0333 4.53855 76.033 4.43047 75.9999 4.33008Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2473_2858">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
                <clipPath id="clip1_2473_2858">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(16)"
                  />
                </clipPath>
                <clipPath id="clip2_2473_2858">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(32)"
                  />
                </clipPath>
                <clipPath id="clip3_2473_2858">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(48)"
                  />
                </clipPath>
                <clipPath id="clip4_2473_2858">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(64)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <p class="reviews__list-item-text">
            I have been struggling with depression and was scared to ask anyone
            for help. After learning how to meditate and going through Mental
            Future’s CBT course, I no longer feel sad or depressed! I noticed a
            big improvement in my mental health. Thanks to all the coaches who
            helped make this app!
          </p>
          <div class="reviews__list-item-user">
            <img
              class="reviews__list-item-user-avatar"
              src="@/static/reviews/maciej-wozniak.png"
            />
            <div class="reviews__list-item-user-nickname">Maciej Wozniak</div>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.reviews {
  padding-bottom: 144px;
  overflow: hidden;

  @media (max-width: 767px) {
    padding-bottom: 72px;
  }

  &__title {
    margin-bottom: 52px;

    @media (min-width: 768px) {
      margin-bottom: 92px;
    }
  }

  &__list {
    display: flex;

    @media (max-width: 1200px) {
      overflow: auto;
    }

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  &__list-item {
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 12px;
    background-color: rgba(#ffffff, 0.1);
    color: #fff;

    @media (min-width: 768px) {
      flex: 1 0 347px;
      max-width: 347px;

      &:not(:last-child) {
        margin-right: 55px;
      }
    }

    @media (max-width: 767px) {
      &:not(:last-child) {
        margin-bottom: 36px;
      }
    }
  }

  &__list-item-stars {
    margin-bottom: 24px;
  }

  &__list-item-text {
    position: relative;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 24px;
    flex: 1 1 100%;

    &:after,
    &:before {
      display: inline-flex;
      content: '"';
    }
  }

  &__list-item-user {
    display: flex;
    align-items: center;
  }

  &__list-item-user-avatar {
    width: 56px;
    height: 56px;
    margin-right: 8px;
    border-radius: 50%;
    overflow: hidden;
  }

  &__list-item-user-nickname {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }
}
</style>

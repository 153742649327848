// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyDCYBzQbit9mBfCRL-QF4cOvk4O5hcQ3EQ',
  authDomain: 'pc-api-9080886543838379288-472.firebaseapp.com',
  projectId: 'pc-api-9080886543838379288-472',
  storageBucket: 'pc-api-9080886543838379288-472.appspot.com',
  messagingSenderId: '265569991226',
  appId: '1:265569991226:web:1cbf3282142e647ac01303',
  measurementId: 'G-VGRYTNGRR0',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

logEvent(analytics, 'page_view')

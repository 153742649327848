<template>
  <div class="checkout-meditation-course-title">
    <div class="checkout-meditation-course-title__container container-checkout">
      <div class="checkout-meditation-course-title__title">
        Let Us Show You
        <b>
          EVERYTHING You Get <br />
          With Our Meditation Course!</b
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutMeditationCourseTitle',
}
</script>

<style lang="scss">
.checkout-meditation-course-title {
  &__title {
    padding: 48px 40px;
    text-align: center;
    font-weight: 400;
    font-size: 36px;
    line-height: 49px;
    background-color: var(--color-white-01);
    border-radius: 12px;

    b {
      font-weight: 800;
    }
  }
}
</style>

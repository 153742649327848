import {
  getUserWorkbooks,
  getWorkbook,
  getUserWorkbook,
} from '@/services/workbook.service'
import { workbookType } from '@/components/workbook/workbook-data'

const state = () => ({
  userWorkbooks: [],
  workbook: {},
  userWorkbook: {},
  currentIndex: 0,
  generalIssuesForm: {},
  isLoading: false,
  questions: [],
  groups: [],
  answerForm: {},
})

const exposure_hierarchy__first = {
  id: '0000',
  name: 'Exposure Hierarchy Instructions',
  noValue: true,
  description:
    'Rate the level of distress that you predict the following specified activities could provoke.',
}

const countQuestions = (question, groups, except = []) => {
  if (groups.length) {
    groups = groups.map((i) => i.toLowerCase())
    return question.filter(
      (i) =>
        !i.group ||
        groups.includes(i.group.toLowerCase()) ||
        except.includes(i.group)
    )
  } else return question
}

const actions = {
  fetchUserWorkbooks({ commit }) {
    getUserWorkbooks().then((res) => {
      commit('SET_DATA', res.data)
    })
  },
  fetchWorkbook({ commit, state }, id) {
    commit('SET_LOADING', true)
    getWorkbook(id)
      .then((res) => {
        commit('SET_WORKBOOK', res)
        if (workbookType.EXPOSURE_HIERARCHY === res.name) {
          commit('SET_QUESTIONS', [
            exposure_hierarchy__first,
            ...countQuestions(res.question || [], state.groups),
          ])
        } else if (workbookType.GOALS === res.name) {
          commit(
            'SET_QUESTIONS',
            countQuestions(
              res.question || [],
              state.groups,
              res.question[0] ? [res.question[0].group] : []
            )
          )
        } else
          commit(
            'SET_QUESTIONS',
            countQuestions(res.question || [], state.groups)
          )
        commit('SET_LOADING', false)
      })
      .catch((err) => {
        commit('SET_LOADING', false)
      })
  },
  setQuestions({ commit, state }) {
    if (workbookType.GOALS === state.workbook.name) {
      commit(
        'SET_QUESTIONS',
        countQuestions(
          state.workbook.question || [],
          state.groups,
          state.workbook.question[0] ? [state.workbook.question[0].group] : []
        )
      )
    } else
      commit(
        'SET_QUESTIONS',
        countQuestions(state.workbook.question || [], state.groups)
      )
  },
  fetchUserWorkbook({ commit, dispatch }, id) {
    commit('SET_LOADING', true)
    getUserWorkbook(id)
      .then((res) => {
        dispatch('workbook/prepareWorkbook', res, { root: true })
        commit('SET_LOADING', false)
      })
      .catch((err) => {
        commit('SET_LOADING', false)
      })
  },
  prepareWorkbook({ commit, state }, res) {
    commit('SET_WORKBOOK', res)
    const firstElement = res.question[0]
    if (
      [workbookType.GENERAL_ISSUES, workbookType.GOALS].includes(res.name) &&
      firstElement
    ) {
      commit('SET_GROUPS', firstElement.answer.multiple_answer)
      if (workbookType.GOALS === res.name)
        commit(
          'SET_QUESTIONS',
          countQuestions(res.question || [], state.groups, [
            res.question[0].group,
          ])
        )
      else
        commit(
          'SET_QUESTIONS',
          countQuestions(res.question || [], state.groups)
        )
    } else if (workbookType.MONITORING_MOODS === res.name) {
      const answer = res.question[1]
      commit('SET_GROUPS', answer ? answer.answer.multiple_answer : [])
      commit('SET_QUESTIONS', countQuestions(res.question || [], state.groups))
    } else if (workbookType.THOUGHT_RECORD === res.name) {
      const answer = res.question[2]
      commit('SET_GROUPS', answer ? answer.answer.multiple_answer : [])
      commit('SET_QUESTIONS', countQuestions(res.question || [], state.groups))
    } else {
      commit('SET_QUESTIONS', res.question)
    }
  },
}

const mutations = {
  SET_DATA(state, data) {
    state.userWorkbooks = data
  },
  SET_GROUPS(state, data) {
    state.groups = data
  },
  RESET_WORKBOOK(state) {
    state.currentIndex = 0
    state.questions = []
    state.groups = []
    state.answerForm = {}
  },
  SET_LOADING(state, data) {
    state.isLoading = data
  },
  SET_QUESTIONS(state, data) {
    state.questions = data
  },
  SET_WORKBOOK(state, data) {
    state.workbook = data
  },
  SET_USER_WORKBOOK(state, data) {
    state.workbook = data
  },
  UPDATE_INDEX(state, data) {
    state.currentIndex = data
  },
  SET_ANSWER_FORM(state, data) {
    state.answerForm = data
  },
  UPDATE_GENERAL_ISSUES_FORM(state, data) {
    state.generalIssuesForm = data
  },
}

const getters = {
  currentPercent: ({ currentIndex, questions }) =>
    (100 * (currentIndex + 1)) / questions.length,
}

export const workbook = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}

<template>
  <div class="checkout-faq">
    <div class="checkout-faq__container">
      <h2 class="checkout-faq__title">FAQs</h2>
      <div>
        <a-collapse v-model:activeKey="activeKey" expand-icon-position="right">
          <template #expandIcon="{ isActive }">
            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{ rotate: isActive }" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.88.453L8 6.56 14.12.453 16 2.333l-8 8-8-8L1.88.453z" fill="#fff"/></svg>
          </template>
          <a-collapse-panel
            v-for="(q, i) in questions"
            :key="i"
            :header="q.name"
          >
            <p>{{ q.answer }}</p>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'CheckoutFAQ',
  data() {
    return {
      activeKey: [],
    }
  },
  computed: {
    ...mapState('faq', ['questions']),
  },
}
</script>

<style lang="scss"></style>
<style lang="scss" scoped>
:deep(.ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header) {
  color: var(--color-white);
  padding: 36px 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-transform: capitalize;
}
:deep(.ant-collapse-content) {
  background-color: inherit;
  border-top: none;
  color: var(--color-white);
  max-width: 1210px;
}
:deep(.ant-collapse-content > .ant-collapse-content-box) {
  padding: 0 0 36px;
}
:deep(.ant-collapse-item.ant-collapse-item-active > .ant-collapse-header) {
  padding: 36px 0 24px;
}
.checkout-faq {
  &__container {
    max-width: 1296px !important;
    margin: 0 auto 52px;
    background: rgba(#ffffff, 0.1);
    border-radius: 12px;
    padding: 48px 36px;
  }

  &__title {
    font-weight: 800;
    font-size: 56px;
    line-height: 76px;
    text-align: center;
    margin-bottom: 41px;
    color: var(--color-white);
  }

  .ant-collapse {
    background-color: inherit;
    border: none;
    color: var(--color-white);

    &-item {
      border-bottom: 1px dashed #ffffff;
    }
  }
}

.rotate {
  transform: rotate(180deg) !important;
}
</style>

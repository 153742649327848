<template>
  <div class="types">
    <div class="container container--fluid">
      <swiper
        :spaceBetween="20"
        :slides-per-view="auto"
        :slidesPerColumn="1"
        :loop="true"
        :loopedSlides="15"
        :loop-fill-group-with-blank="true"
        :slides-per-group-auto="true"
        :speed="3500"
        :lazy="true"
        :width="200"
        :simulate-touch="false"
        :centeredSlides="true"
        :autoplay="{
          enabled: true,
          delay: 0,
          disableOnInteraction: false,
        }"
        class="mySwiper types__list"
      >
        <swiper-slide v-for="item in info.item" :key="item.type">
          <a href="#" class="types__item">
            <div class="types__item-icon">
              <svg class="svg-icon" :class="item.iconName">
                <use
                  :xlink:href="
                    require(`@/static/types/icons/sprite.svg`) +
                    `${item.iconName}`
                  "
                ></use>
              </svg>
            </div>
            <div class="types__item-title">
              {{ item.type }}
            </div>
            <img
              :src="require(`@/static${item.image}`)"
              :alt="`${item.type}`"
              class="types__item-img"
            />
          </a>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import json from '@/data/types.json'
import { Swiper, SwiperSlide } from 'swiper/vue'
// import 'swiper/swiper-bundle.css'
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper'
SwiperCore.use([Autoplay, Pagination, Navigation])

export default {
  name: 'Types',
  components: { Swiper, SwiperSlide },
  data() {
    return {
      info: json,
    }
  },
}
</script>

<style lang="scss" scoped>
.types {
  padding-bottom: 10px;
  overflow: hidden;

  &__list {
    display: flex;
    position: relative;
    z-index: 1;
    height: 216px;
    .swiper-slide {
      height: 216px;
    }
  }

  &__item {
    display: block;
    position: relative;
    max-width: 200px;
    height: 216px;
    padding: 16px 12px;
    border-radius: 12px;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 216px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(#041114, 0.6);
      border-radius: 12px;
      overflow: hidden;
    }
  }

  &__item-icon {
    position: relative;
    z-index: 1;
    margin-bottom: 8px;
  }

  &__item-title {
    position: relative;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-white);
    z-index: 1;
  }

  &__item-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .svg-icon {
    width: 24px;
    height: 24px;
    color: var(--color-white);

    &.breathing-exercises {
      width: 37px;
    }
  }
}
</style>
>

<template>
  <div class="checkout-timer">
    <div class="checkout-timer__container">
      <div class="checkout-timer__title">
        {{ title }}
      </div>
      <div class="checkout-timer__items">
        <div class="checkout-timer__item">
          <p class="checkout-timer__item-value">{{ days }}</p>
          Days
        </div>
        <div class="checkout-timer__item">
          <p class="checkout-timer__item-value">{{ hours }}</p>
          Hours
        </div>
        <div class="checkout-timer__item">
          <p class="checkout-timer__item-value">{{ minutes }}</p>
          Minutes
        </div>
        <div class="checkout-timer__item">
          <p class="checkout-timer__item-value">{{ seconds }}</p>
          Seconds
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import moment from 'moment'

export default {
  name: 'CheckoutWhiteTimer',
  props: {
    title: String,
  },
  setup() {
    const days = ref('00')
    const hours = ref('00')
    const minutes = ref('00')
    const seconds = ref('00')
    const lunchDate = moment('04:00:00', 'HH:mm:ss')

    const formatData = (d) => {
      return d < 10 ? '0' + d : d
    }

    setInterval(() => {
      seconds.value = formatData(lunchDate.seconds())
      minutes.value = formatData(lunchDate.minutes())
      hours.value = formatData(lunchDate.hours())
      lunchDate.subtract(1, 'second')
    }, 1000)

    return { days, hours, minutes, seconds }
  },
}
</script>

<style lang="scss" scoped>
.checkout-timer {
  padding-top: 72px;
  padding-bottom: 96px;

  &__container {
    max-width: 1130px;
    padding: 0 15px;
    margin-inline: auto;
  }

  &__title {
    font-weight: 800;
    font-size: 36px;
    line-height: 49px;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 68px;
  }

  &__items {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    position: relative;
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    width: 220px;
    height: 220px;
    padding: 30px 15px;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 16px 40px rgba(47, 53, 66, 0.1);
    border: 2px dashed var(--color-red);
    border-radius: 12px;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    text-transform: uppercase;
    color: var(--color-red);

    &:not(:last-child) {
      &:before {
        content: ':';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -50px;
        font-weight: 400;
        font-size: 96px;
        color: var(--color-red);
      }
    }
  }
}
</style>

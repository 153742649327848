<template>
  <header class="checkout-header-white">
    <div class="checkout-header-white__container">
      <div class="checkout-header-white__logo">Mental Future</div>
    </div>
  </header>
</template>

<script>
export default {
  name: "CheckoutHeaderWhite",
};
</script>

<style lang="scss">
.checkout-header-white {
  padding-bottom: 92px;
  &__container {
    max-width: 1512px;
    width: 100%;
    margin-inline: auto;
    padding-inline: 36px;
    padding-top: 24px;
  }

  &__logo {
    font-family: var(--run);
    font-size: 36px;
    line-height: 1;
  }
}
</style>
<template>
  <section class="how-it-work">
    <div class="container container--content">
      <h2 class="how-it-work__title welcome-title">How It Works</h2>
      <p class="how-it-work__description welcome-description">
        Start the journey to a better You in a few simple steps.
      </p>
      <div class="how-it-work__bottom">
        <div class="how-it-work__bottom-wrapper">
          <ul class="how-it-work__bottom-list">
            <li
              class="how-it-work__bottom-item how-it-work__bottom-item--first"
              data-aos="fade-down-right"
              data-aos-delay="200"
              data-aos-duration="1500"
              data-aos-once="true"
            >
              <div class="how-it-work__bottom-number">01</div>
              <p class="how-it-work__bottom-text">
                We ask you a few questions in order to create your mind profile.
              </p>
            </li>
            <li
              class="how-it-work__bottom-item how-it-work__bottom-item--second"
              data-aos="fade-right"
              data-aos-delay="500"
              data-aos-duration="1300"
              data-aos-once="true"
            >
              <div class="how-it-work__bottom-number">02</div>
              <p class="how-it-work__bottom-text">
                Based on your answers you get personalized recommendations
                aiming to help you reach your goals faster. You are also free to
                explore our huge library of 1000+ meditations, sleepcasts,
                natural sounds and therapies.
              </p>
            </li>
            <li
              class="how-it-work__bottom-item how-it-work__bottom-item--three"
              data-aos="fade-right"
              data-aos-delay="500"
              data-aos-duration="1300"
              data-aos-once="true"
            >
              <div class="how-it-work__bottom-number">03</div>
              <p class="how-it-work__bottom-text">
                Break old patterns and build new habits. Learn how to practice
                mindfulness regularly to reduce stress factors.
              </p>
            </li>
            <li
              class="how-it-work__bottom-item how-it-work__bottom-item--fourth"
              data-aos="fade-up-right"
              data-aos-delay="200"
              data-aos-duration="1300"
              data-aos-once="true"
            >
              <div class="how-it-work__bottom-number">04</div>
              <p class="how-it-work__bottom-text">
                Practice out therapies and benefit from proven techniques,
                developed by leading scientists and expert therapists with over
                100 years of combined experience.
              </p>
            </li>
          </ul>
          <div
            class="how-it-work__bottom-image"
            data-aos="fade-left"
            data-aos-delay="1000"
            data-aos-duration="1500"
            data-aos-once="true"
            data-aos-anchor=".how-it-work__bottom-item--second"
          >
            <img src="@/static/solo-telephone.svg" alt="phone" />
          </div>
        </div>
      </div>
      <router-link to="/quiz" class="how-it-work__link start-quiz-link">
        <span class="start-quiz-link__text">Start my quiz</span>
      </router-link>
    </div>
  </section>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.how-it-work {
  padding-bottom: 144px;

  @media (max-width: 767px) {
    padding-bottom: 72px;
  }

  &__bottom-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 92px;

    @media (max-width: 767px) {
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 36px;
    }
  }

  &__bottom-list {
    @media (min-width: 768px) {
      max-width: 530px;
    }
  }

  &__bottom-item {
    &:not(:last-child) {
      margin-bottom: 72px;

      @media (max-width: 767px) {
        margin-bottom: 36px;
      }
    }
  }

  &__bottom-number {
    font-weight: 700;
    font-size: 36px;
    line-height: 1;
    color: rgba(#fff, 0.5);
    margin-bottom: 12px;

    @media (max-width: 767px) {
      font-size: 24px;
      margin-bottom: 8px;
    }
  }

  &__bottom-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: var(--color-white);

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__bottom-image {
    padding-top: 55px;
  }

  &__link {
    margin-right: auto;
    margin-left: auto;
  }
}
</style>

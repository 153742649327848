<template>
  <div class="loader" v-if="isLoading">
    <a-spin />
  </div>
  <section class="section" v-if="!isLoading && data.length">
    <div class="section__head">
      <div class="personal-plan__head">
        <h3 class="section__name">Your Personal Plan</h3>
      </div>
      <div class="personal-plan__see-all">
        <button
          v-if="isPremiumUser"
          class="personal-plan__see-all-btn"
          @click="$router.push('/personal-plan')"
        >
          <span>see all</span>
          <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 10l4.5-4.5L1 1" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </button>
        <button v-else class="personal-plan__see-all-btn" @click="onUnlock">
          <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.25 3.867V2.5a2.5 2.5 0 00-5 0v1.367A1.87 1.87 0 000 5.625v2.5A1.88 1.88 0 001.875 10h3.75A1.88 1.88 0 007.5 8.125v-2.5a1.87 1.87 0 00-1.25-1.758zM3.75 7.5a.833.833 0 110-1.667.833.833 0 010 1.667zm2.083-3.75H1.667V2.5a2.083 2.083 0 114.166 0v1.25z" fill="#fff"/></svg>
          <span>see all</span>
          <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 10l4.5-4.5L1 1" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </button>
      </div>
      <div class="personal-plan__buttons">
        <button
          :disabled="!canScrollLeft"
          class="personal-plan__button"
          @click="onScroll('left')"
        >
          <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.333 16c.187 0 .38-.08.507-.234.24-.28.2-.7-.08-.94L1.7 8l8.067-6.827a.665.665 0 00.073-.94A.665.665 0 008.9.16L.233 7.493A.674.674 0 000 8c0 .193.087.38.233.506L8.9 15.84c.127.106.28.16.433.16z" fill="#fff"/></svg>
        </button>
        <button
          :disabled="!canScrollRight"
          class="personal-plan__button"
          @click="onScroll('right')"
        >
          <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.667 16a.662.662 0 01-.507-.234c-.24-.28-.2-.7.08-.94L8.3 8 .233 1.173A.665.665 0 01.16.233.665.665 0 011.1.16l8.667 7.333A.674.674 0 0110 8c0 .193-.087.38-.233.506L1.1 15.84a.671.671 0 01-.433.16z" fill="#fff"/></svg>
        </button>
      </div>
    </div>
    <div class="personal-plan" ref="plan">
      <div class="personal-plan__steps">
        <span class="personal-plan__day">Day {{ day }}</span>
        <span class="step__line step__line--before"></span>
        <template v-for="(item, i) in filteredData" :key="item.id">
          <span v-if="i > 0" class="step__line"></span>
          <div class="personal-plan__step">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="item.watched" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M18.217 9.348l-7.826 7.304-2.609-2.609" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/><path d="M13 25c6.627 0 12-5.373 12-12S19.627 1 13 1 1 6.373 1 13s5.373 12 12 12z" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/></svg>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle opacity=".2" cx="12" cy="12" r="11.5" stroke="#fff"/></svg>
          </div>
        </template>
      </div>
      <div class="personal-plan__cards">
        <StepCard
          v-for="item in filteredData"
          :key="item.id"
          :data="item"
          :is-premium="isPremiumUser"
          :is-free-trial="item.free"
          @onUnlock="onUnlock"
        />
        <span class="last-space"></span>
      </div>
    </div>
  </section>
</template>

<script>
import StepCard from '@/components/cards/StepCard.vue'
import { mapMutations, mapState } from 'vuex'
import { metaEventCustomActions } from '@/data/constants'

export default {
  name: 'PersonalPlan',
  components: { StepCard },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    dayNumber: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      canScrollLeft: false,
      canScrollRight: false,
      windowWidth: window.innerWidth,
      isLoading: false,
    }
  },
  computed: {
    ...mapState(['isUserStatusLoading', 'userStatus']),
    day() {
      return this.dayNumber ? this.dayNumber : 0
    },
    isPremiumUser() {
      return !!this.userStatus.subscription_type
    },
    isMobile() {
      return this.windowWidth <= 991
    },
    filteredData() {
      return this.isMobile ? this.data.slice(0, 3) : this.data
    },
  },
  async mounted() {
    setTimeout(() => {
      this.canScrollRight =
        this.$refs.plan.scrollWidth - this.$refs.plan.clientWidth
    }, 1000)

    window.addEventListener('resize', this.onResize)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapMutations('subscription', ['SET_OPEN']),
    onUnlock() {
      this.SET_OPEN({
        isOpen: true,
        action: metaEventCustomActions.ON_LOCKED_CLICKED,
      })
    },
    onResize() {
      this.windowWidth = window.innerWidth
      this.canScrollRight =
        this.$refs.plan.scrollWidth - this.$refs.plan.clientWidth
    },
    onScroll(direction) {
      const scrollStep = 500
      if (direction === 'left') {
        this.$refs.plan.scrollLeft -= scrollStep
        this.canScrollLeft = this.$refs.plan.scrollLeft - scrollStep > 0
        this.canScrollRight =
          this.$refs.plan.scrollLeft -
            scrollStep +
            this.$refs.plan.clientWidth <
          this.$refs.plan.scrollWidth
      } else {
        this.$refs.plan.scrollLeft += scrollStep
        this.canScrollLeft = this.$refs.plan.scrollLeft + scrollStep > 0
        this.canScrollRight =
          this.$refs.plan.scrollLeft +
            scrollStep +
            this.$refs.plan.clientWidth <
          this.$refs.plan.scrollWidth
      }
    },
    onDone() {
      window.location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
.section {
  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    justify-content: space-between;
    padding-right: 36px;

    @media screen and (max-width: 991px) {
      padding-right: 24px;
    }
  }

  &__name {
    margin-bottom: 0;
    padding-left: 0;

    @media screen and (max-width: 991px) {
      padding-left: 0;
    }
  }
}
.personal-plan {
  padding-left: 36px;
  overflow: auto;
  scroll-behavior: smooth;

  @media screen and (max-width: 991px) {
    padding-left: 0;
    display: flex;
    gap: 15px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__steps {
    display: flex;
    align-items: center;
    position: relative;

    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }

  &__step {
    display: flex;
    align-items: center;
    margin: 0 8px;
    @media screen and (max-width: 991px) {
      margin: 8px 0;
    }
  }

  &__cards {
    margin-top: 24px;
    display: grid;
    grid-auto-flow: column;
    column-gap: 24px;
    grid-auto-columns: 288px;
    row-gap: 16px;

    @media screen and (max-width: 991px) {
      grid-auto-flow: row;
      margin-top: 0;
    }
  }

  &__day {
    position: absolute;
    left: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-white);

    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &__day-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-white);
  }

  &__head {
    display: flex;
    align-items: center;
    padding-left: 36px;

    @media screen and (max-width: 991px) {
      padding-left: 0;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 8px;

    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &__button {
    background: rgba(#fff, 0.2);
    border-radius: 4px;
    border: none;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 300ms ease-in-out;

    &:hover {
      background: rgba(#fff, 0.3);
    }

    &:disabled {
      cursor: not-allowed;
      background: rgba(#fff, 0.1);
      path {
        fill: #98b7b4;
      }
    }
  }

  .last-space {
    width: 12px;
    background: inherit;
  }

  &__see-all {
    display: none;
    @media screen and (max-width: 991px) {
      display: flex;
      align-items: center;

      &-btn {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: var(--color-white);
        background: inherit;
        border: none;
        text-transform: capitalize;

        span {
          margin: 0 8px 0 3px;
        }
      }
    }
  }
}

.step {
  &__line {
    min-width: 272px;
    height: 1px;
    background-color: rgba(#fff, 0.2);

    @media screen and (max-width: 991px) {
      width: 1px;
      min-width: auto;
      min-height: 112px;
    }

    &--before {
      height: 0px;
      min-width: 124px;

      @media screen and (max-width: 991px) {
        width: 0px;
        min-width: auto;
        min-height: 52px;
      }
    }
  }
}

.loader {
  margin: 24px;
}

.pt {
  padding-top: 22px;
}

.arrow-left {
  margin-right: 17px;
}

.back-btn {
  background: none;
  border: none;
  display: flex;
  align-items: center;
}
</style>

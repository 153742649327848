<template>
  <section class="unique">
    <div class="container container--content">
      <h2 class="unique__title welcome-title">Unique Framework</h2>
      <p class="unique__description welcome-description">
        The Mental Future app presents a reliable framework to fight against the
        negative effects of a bad mental state with a minimal time investment.
        Start your quiz now to create your mind profile!
      </p>
      <div class="unique__wrapper"
                    data-aos="fade"
              data-aos-delay="500"
              data-aos-duration="2500"
              data-aos-once="true">
        <picture>
          <source
            srcset="@/static/circle-mobile.svg"
            media="(max-width: 767px)"
          />
          <img
            src="@/static/circle-desktop.svg"
            class="unique__circle"
            alt="circle"
          />
        </picture>
      </div>
      <router-link to="/quiz" class="unique__btn start-quiz-link">
        <span class="start-quiz-link__text">Start my quiz</span>
      </router-link>
    </div>
  </section>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.unique {
  padding-bottom: 144px;
  overflow: hidden;

  @media (max-width: 767px) {
    padding-bottom: 72px;
  }

  &__description {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    max-width: 635px;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 72px;

    @media (min-width: 768px) {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 92px;
    }

    img {
      height: auto;
      max-width: 100%;
    }
  }

  &__btn {
    margin-left: auto;
    margin-right: auto;
  }
}
</style>

<template>
  <a-modal
    v-model:visible="isOpen"
    width="100%"
    :closable="false"
    :maskClosable="false"
    wrap-class-name="payments"
    :footer="null"
    :destroyOnClose="true"
  >
    <button class="payments__close" @click="$emit('close')">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.5 7.5l9 9M16.5 7.5l-9 9M12 23.5c6.351 0 11.5-5.149 11.5-11.5S18.351.5 12 .5.5 5.649.5 12 5.649 23.5 12 23.5z" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/></svg>
    </button>
    <Payments
      :isEurope="isEurope"
      :package="currentPackage"
      @onSuccess="$emit('onSuccess')"
    />
  </a-modal>
</template>

<script>
import Payments from './Payments.vue'
export default {
  name: 'PaymentsModal',
  components: { Payments },
  props: {
    currentPackage: {
      type: String,
      default: '',
    },
    isEurope: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.payments {
  .ant-modal {
    max-width: 887px;
  }

  .ant-modal-content {
    background: #134e5e;
    border-radius: 12px;
  }

  .ant-modal-body {
    padding: 0;
    position: relative;
  }

  &__close {
    position: absolute;
    top: 17px;
    right: 17px;
    background: inherit;
    border: none;
    cursor: pointer;
    z-index: 2;
  }
}
</style>

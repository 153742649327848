<template>
  <div class="page-wrapper theme-green-forest">
    <Header />
    <MeditationCollection />
    <Footer />
    <subscription-modal @click="onDone" />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import MeditationCollection from '@/layouts/MeditationCollection.vue'
import Footer from '@/components/Footer.vue'
import { mapMutations } from 'vuex'
import SubscriptionModal from '../components/subscribe/SubscriptionModal.vue'

export default {
  name: 'Meditation',
  components: { Header, MeditationCollection, Footer, SubscriptionModal },
  methods: {
    ...mapMutations('subscription', ['SET_OPEN']),
    onDone() {
      this.SET_OPEN({ isOpen: false })
      window.location.reload()
    },
  },
}
</script>
<style lang="scss" scoped>
.title-container {
  padding: 0 36px;
}
</style>

import axios from 'axios'

const prefix = '/stripe'

export const getSetupIntent = async () => {
  return await axios.get(`${prefix}/setup-intent`)
}

export const createSubscription = async (data) => {
  return await axios.put(`${prefix}/subscription`, data)
}

export const addPayment = async (data) => {
  return await axios.post(`${prefix}/payments`, data)
}

<template>
  <label class="package__card">
    <div class="package__popular__head" v-if="popular">
      <p class="package__popular__title">MOST POPULAR</p>
    </div>
    <div class="package__item" :class="{ popular: popular }">
      <a-radio
        name="package"
        :value="purchaseTypes.YEARLY"
        class="package__item__checkbox"
      ></a-radio>
      <div class="package__item__detail">
        <h4 class="package__item__title">
          Free Trial
          <span class="package__item__sub-title">With Yearly Plan</span>
        </h4>
        <p class="package__item__price">
          <span class="strikethrough"
            >{{ currency }}{{ (+data.amount + 30).toFixed(2) }}</span
          >
          {{ currency }}{{ data.amount }}
        </p>
        <p class="package__item__info">Billed annually after 1 week</p>
      </div>
      <div class="package__item__right overlay">
        <span class="package__item__price-p">{{ currency }}</span>
        <p class="package__item__price-day">0</p>
        <div class="package__item__p-wrapper">
          <p class="package__item__price-p">.00</p>
          <p class="package__item__price-p">due today</p>
        </div>
      </div>
    </div>
  </label>
</template>

<script>
import { purchaseTypes } from '@/data/constants'
import { getCurrency } from '@/utils/helpers'
export default {
  name: 'PaymentYearlyCard',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    popular: Boolean,
  },
  data() {
    return {
      purchaseTypes,
    }
  },
  computed: {
    currency() {
      return this.getCurrency(this.data.currency)
    },
  },
  methods: {
    getCurrency,
  },
}
</script>

<style></style>

<template>
  <div class="checkout-triangle">
    <div class="checkout-triangle__discover" v-html="discover"></div>
    <div class="checkout-triangle__items">
      <div class="checkout-triangle__image">
        <img src="@/static/checkout-white/icons/triangle.svg" alt="triangle" />
      </div>
      <div
        v-for="(item, index) in items"
        :key="index"
        class="checkout-triangle__item"
      >
        <div>
          <div class="checkout-triangle__item-title">
            <div>
              {{ item.title }}
            </div>
          </div>
          <div class="checkout-triangle__item-subtitle">
            <div>
              {{ item.subtitle }}
            </div>
          </div>
          <div class="checkout-triangle__item-text">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutTriangle',
  data() {
    return {
      text: 'Imagine what it would feel like to have greater control over your mind and life.To know how to always feel calm, centered, and focused no matter what. To be able to let go of things, overcome negative mental states, and master the inner chatter. If you want to experience stress relief, a creativity boost, a tranquil atmosphere in your home, or simply to enjoy deep relaxation, choose Mental Future and start your journey towards recovery.',
      discover:
        'DISCOVER HOW <span class="bold">THE MENTAL FUTURE TRIANGLE</span> IMPROVES YOUR MIND<br/> AND LONG-TERM HEALTH AT THE SAME TIME',
      items: [
        {
          title: 'Habit',
          subtitle: 'Secret #1',
          text: 'Get started in the easiest possible way and make meditation and self-affirmations part of your daily life. Cultivate mindfulness by learning how to meditate step-by-step using our proven system.',
        },
        {
          title: 'Technique',
          subtitle: 'Secret #2',
          text: 'Meditation and mind recovery are not hard when you are doing the right practices under the guidance of an experienced professional or system. It is key to develop a practice which is both enjoyable and effective.',
        },
        {
          title: 'Transformation',
          subtitle: 'Secret #03',
          text: 'By incorporating the two pillars of habit and technique you can begin your transformation: a movement from scattered life to centered life. From automatic living to conscious living. From anxious to calm and empowered. Learn to live with wisdom and courage. Become focused and successful. Become the best version of yourself.',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.checkout-triangle {
  max-width: 1160px;
  width: 100%;
  margin-inline: auto;
  padding-bottom: 56px;
  margin-bottom: 56px;
  background-image: url(~@/static/checkout-white/flower-big.svg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;

  &__discover {
    padding-bottom: 120px;
    text-align: center;
    font-family: var(--nunito-regular);
    font-size: 56px;
    line-height: 56px;
    color: #2f3542;
  }

  &__image {
    position: absolute;
    top: 10px;
    left: 350px;
  }

  &__items {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-inline: auto;
    max-width: 98%;
  }

  &__item {
    padding: 24px 36px;
    max-width: 344px;
    background-color: var(--color-white);
    box-shadow: 0px 20px 40px rgba(47, 53, 66, 0.1);
    border-radius: 8px;

    &:nth-child(3) {
      margin-left: 420px;
      margin-top: 60px;
    }

    &:last-child {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      flex: 1 1 100%;
      margin-top: 100px;
      max-width: 362px;
      margin-inline: auto;

      .checkout-triangle__item-title,
      .checkout-triangle__item-subtitle {
        flex: 1 1 100%;

        div {
          max-width: 362px;
          margin-inline: auto;
        }
      }
    }
  }

  &__item-title {
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 8px;
  }

  &__item-subtitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 16px;
  }

  &__item-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    max-width: 284px;
  }
}

:deep(.bold) {
  font-family: var(--nunito-black);
  color: #ff4757;
}
</style>

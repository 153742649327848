import axios from 'axios'

const prefix = '/lead'

export const postLead = async (data) => {
  return await axios.post(`${prefix}`, data)
}

export const putLead = async (data) => {
  return await axios.put(`${prefix}`, data)
}

export const getLead = async (id) => {
  return await axios.get(`${prefix}/${id}`)
}

export const models = {
  MEDITATION_SLEEP: 'meditation-sleep',
  MEDITATION_SLEEP_COURSE: 'meditation-sleep-course',
  MUSIC_SOUNDSCAPE: 'music-soundscape',
  MUSIC_SOUNDSCAPE_PLAYLIST: 'music-soundscape-playlist',
  CBT: 'cbt',
  CBT_COURSE: 'cbt-course',
}

export const purchaseTypes = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
  LIFETIME: 'lifetime',
}

export const packagePlaces = {
  AFTER_LOCKED_CONTENT: 'after_locked_content',
  AFTER_REGISTRATION: 'after_registration',
  AFTER_TRIAL_IS_OVER: 'after_trial_is_over',
}

export const purchaseComments = [
  {
    id: 1,
    name: 'Amanda Miller',
    comment:
      '“Mental Future’s meditations and therapy course helped me start feeling calmer in just about 4 weeks. Over this period I noticed much more progress than I managed to make in the past 5 years of taking prescription drugs and anti-depressants. Thanks for doing this for people like me!”',
    image: require('@/assets/img/user-1.png'),
  },
  {
    id: 2,
    name: 'Andrew Gordon',
    comment:
      '"Staying awake all night was something I used to do almost every night. I was just unable to fall asleep at all. Insomnia was slowly decreasing my quality of life and i started having feelings of hopelessness, regret and anxiety. I’m so happy things have changed thanks to Mental Future. My life is much more meaningful now!"',
    image: require('@/assets/img/user-2.png'),
  },
  {
    id: 3,
    name: 'Stephanie Allen',
    comment:
      '“As a single mother of three it has never been easy for me: I had to work and provide for my children. I often had to hide my emotions and suppress the stress. Now, thanks to Mental Future, I learned how to embrace my emotions and focus more on positive thoughts, which led to me starting to enjoy certain aspects of life more.”',
    image: require('@/assets/img/user-3.png'),
  },
]

export const checkouts = {
  GREEN_MAIN: 'green-main',
  WHITE_MAIN: 'white-main',
  DEFAULT: 'default',
  VSL: 'vsl-type-2',
}

export const paymentTypes = {
  STRIPE: 'stripe',
  PAYPAL: 'paypal',
}

export const packagePopular = 'Most Popular'

export const metaEventCustomActions = {
  ON_LOCKED_CLICKED: 'LockedContentIntent',
  ON_UNLOCK_BUTTON: 'BuyIntent',
  ON_QUIZ_FINISHED: 'InformIntent',
}

export const currency = {
  USD: 'USD',
  EUR: 'EUR',
}

export const checkoutTypes = {
  CHECKOUT: 'checkout',
  PROMO: 'promo',
}

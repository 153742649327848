import axios from 'axios'
import store from './store'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.headers.common['Accept'] = 'application/json'

axios.interceptors.request.use((config) => {
  if (localStorage.hasOwnProperty('t'))
    config.headers.common.Authorization =
      'Bearer' + ' ' + localStorage.getItem('t')
  config.params = { ...config.params, t: new Date().getTime() }
  return config
})

axios.interceptors.response.use(
  (response) => {
    return { ...response.data, status: response.status }
  },
  (e) => {
    const { response } = e
    store.dispatch('error/setError', {
      message: (response.data && response.data.message) || e,
      hasError: true,
    })
    return Promise.reject(response.data || e)
  }
)

<template>
  <div class="checkout-panic">
    <div class="checkout-panic__container container-checkout">
      <img
        :src="require(`@/static${img_src}`)"
        alt="silence"
        class="checkout-panic__image"
      />
      <div class="checkout-panic__wrapper">
        <div class="checkout-panic__title" v-html="title"></div>
        <div class="checkout-panic__texts">
          <p
            v-for="(item, index) in texts"
            :key="index"
            class="checkout-panic__text"
            v-html="item.text"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutPanic',
  data() {
    return {
      img_src: '/checkout/panic.png',
      title:
        'Struggling with anxiety? Feeling depressed and suffering from panic attacks? Having trouble sleeping? Procrastinating? Having a hard time overcoming a breakup? Feeling you are not financially stable?',
      texts: [
        {
          text: "Don't let mental issues ruin your personal and professional life. Take the first step towards CHANGE!",
        },
        {
          text: 'Every day, an average of 2000 people take their own lives, which is one person every 40 seconds. About 18.1% of the population suffers from anxiety and 6.2% - from mental illnesses.',
        },
        {
          text: "What's more important- over 45% of the working population says they suffer from increased stress levels!",
        },
        {
          text: 'Yes, all these data are part of OFFICIAL STATISTICS and are SHOCKING!',
        },
        {
          text: 'This is why our motivation is to offer a product on a natural basis, which not only dulls the symptoms of depression, panic attacks/anxiety, procrastination, insomnia, relationship traumas, lack of a goal-driven mindset but also effectively fights these conditions.',
        },
        {
          text: 'Imagine what it would feel like to have greater control over your mind and life. To know how to always feel calm, centered and focused. To be able to let go of things, overcome negative mental states and master the inner chatter. Be happy now, ACT NOW!',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.checkout-panic {
  padding-bottom: 190px;

  &__container {
    display: flex;
    align-items: flex-start;
  }

  &__image {
    max-width: 600px;
  }

  &__wrapper {
    padding-left: 36px;
  }

  &__title {
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    padding-bottom: 12px;
    max-width: 691px;
  }

  &__text {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
</style>

<template>
  <button @click="onStart" class="checkout-get-started-now">
    <img
      src="@/static/checkout-white/icons/shopping.svg"
      alt="shopping"
      class="checkout-get-started-now__icon"
    />
    <div class="checkout-get-started-now__text">Get Started Now</div>
  </button>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'CheckoutWhiteGetStartedNow',
  computed: {
    ...mapState(['token']),
  },
  methods: {
    ...mapMutations('subscription', ['SET_OPEN']),
    onStart() {
      if (!this.token) {
        this.$router.push({
          name: 'Login',
          query: {
            redirect: `${window.location.pathname}?from=auth`,
          },
        })
      } else if (['PromoCheckout', 'CheckoutUser'].includes(this.$route.name))
        this.SET_OPEN({ isOpen: true })
    },
  },
}
</script>

<style lang="scss" scoped>
.checkout-get-started-now {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-red);
  border-radius: 52px;
  padding: 26px 5px;

  &__text {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: var(--color-white);
    margin-left: 20px;
  }
}
</style>

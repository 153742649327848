<template>
  <template v-if="meditationCollection">
    <div class="tags tags-collection" v-if="tags">
      <button
        class="tag"
        :class="{ active: currentTag === 'All' }"
        @click="onChangeTag('All')"
      >
        All
      </button>
      <button
        class="tag"
        v-for="tag in tags"
        :class="{ active: this.selectedTags.includes(tag) }"
        :key="tag"
        @click="onChangeTag(tag)"
      >
        {{ tag }}
      </button>
    </div>
    <collection-section
      :tag="currentTag"
      :data="meditationCollection.section"
      :isLoading="isLoading"
    />
  </template>
</template>

<script>
import axios from 'axios'
import CollectionSection from '@/components/collection/CollectionSection.vue'
import { getAllMeditationSleep } from '@/services/meditation-sleep.service'
import { getScreen } from '@/services/screen.service'

export default {
  name: 'MeditationCollection',
  components: { CollectionSection },
  data() {
    return {
      meditationCollection: {},
      tags: [],
      currentTag: 'All',
      isLoading: false,
      selectedTags: [],
    }
  },
  created() {
    this.fetchData()
    axios.get(`tags`).then((response) => {
      this.tags = response.tags
    })
  },
  methods: {
    onChangeTag(tag) {
      this.isLoading = true
      this.currentTag = tag
      if (tag === 'All') {
        this.fetchData()
        this.selectedTags = []
      } else {
        if (this.selectedTags.includes(tag))
          this.selectedTags = this.selectedTags.filter((i) => i !== tag)
        else this.selectedTags.push(tag)
        getAllMeditationSleep({ tags: this.selectedTags.join(',') })
          .then((res) => {
            this.meditationCollection = { section: res.data }
            this.isLoading = false
          })
          .catch((err) => {
            console.log(err)
            this.isLoading = false
          })
      }
    },
    fetchData() {
      getScreen('meditation')
        .then((response) => {
          this.meditationCollection = response
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss"></style>

<template>
  <div class="checkout-be-crazy">
    <div class="checkout-be-crazy__container container-checkout">
      <div class="checkout-be-crazy__title">
        Here Is Our <b>"You've Gotta Be Crazy" Guarantee</b>
      </div>
      <div class="checkout-be-crazy__wrapper">
        <div class="checkout-be-crazy__img">
          <img src="@/static/checkout/gold-icon.png" alt="image" />
        </div>
        <div class="checkout-be-crazy__right">
          <div class="checkout-be-crazy__texts">
            <p class="checkout-be-crazy__text">
              We guarantee that you'll love this program, or we'll return your
              entire $14.95 and let you keep the training anyway.
            </p>
            <p class="checkout-be-crazy__text">
              That's right. You don't even have to send anything back. All you
              need to do is to email us your receipt with the title "Refund
              request" and we'll give you back your money with no question
              asked.
            </p>
            <p class="checkout-be-crazy__text">
              You have nothing to lose and everything to gain.
            </p>
          </div>
          <checkout-get-started-now />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckoutGetStartedNow from './CheckoutGetStartedNow.vue'
export default {
  components: { CheckoutGetStartedNow },
}
</script>

<style lang="scss" scoped>
.checkout-be-crazy {
  padding-bottom: 92px;

  &__title {
    font-weight: 400;
    font-size: 48px;
    line-height: 66px;
    padding-bottom: 72px;
    text-align: center;

    b {
      font-weight: 700;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 1096px;
    margin-inline: auto;
  }

  &__img {
    width: 284px;

    img {
      width: 284px;
      height: 284px;
    }
  }

  &__right {
    flex: 0 1 764px;
    max-width: 764px;
    padding: 36px;
    background-color: var(--color-white-01);
    border-radius: 12px;
  }

  &__texts {
    padding-bottom: 72px;
  }

  &__text {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}
</style>

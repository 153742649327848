<template>
  <section class="start">
    <div class="container container--content">
      <div class="start__wrapper">
        <h2 class="start__title welcome-title">
          Start your journey with Mental Future
        </h2>
        <p class="start__text welcome-description">
          Learn how to overcome stress by practicing meditation and CBT in as
          little as 10 minutes per day!
        </p>
        <img src="@/static/telephone.svg" alt="telephone" class="start__img" />
        <router-link to="/quiz" class="start__link start-quiz-link">
          <span class="start-quiz-link__text">Start my quiz</span>
        </router-link>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.start {
  padding-top: 72px;
  padding-bottom: 72px;
  margin-bottom: 150px;
  background-color: rgba(#fff, 0.1);

  @media (max-width: 767px) {
    margin-bottom: 72px;
  }

  &__wrapper {
    position: relative;
  }

  .welcome-title,
  .welcome-description {
    @media (min-width: 768px) {
      text-align: left;
    }
  }

  .welcome-title {
    @media (min-width: 768px) {
      max-width: 64%;
    }
  }

  &__text {
    @media (min-width: 768px) {
      max-width: 54%;
    }
  }

  &__img {
    @media (min-width: 768px) {
      position: absolute;
      right: 0;
      bottom: -215px;
    }

    @media (max-width: 767px) {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 72px;
    }
  }

  &__link {
    @media (max-width: 767px) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>

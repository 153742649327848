<template>
  <div class="title-container">
    <h3 class="name-title">
      Good Morning,

      <span>
        {{ user.name }}
      </span>
    </h3>

    <button
      class="unlock-mental web"
      @click="onSubscribe"
      v-if="!userStatus.subscription_via && !isUserStatusLoading"
    >
      <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9 18a2 2 0 100-4 2 2 0 000 4z" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/><path d="M13.5 9.5h-9a4 4 0 00-4 4v6a4 4 0 004 4h9a4 4 0 004-4v-6a4 4 0 00-4-4z" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.5 6a5.5 5.5 0 0111 0v3.63" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/></svg>
      Unlock Mental Future
    </button>
  </div>
  <div class="wrapper-section">
    <section
      class="section daily"
      v-if="dailyMeditation.content && dailyMeditation.content.length"
    >
      <ul class="section__items">
        <div class="slider-mask">
          <swiper
            :spaceBetween="24"
            :slidesPerView="'auto'"
            :slidesPerGroup="slidesPerGroup"
            :loop="false"
            :lazy="true"
            :simulateTouch="false"
            :navigation="{
              nextEl: `.swiper-button-next-${dailyMeditation.id}`,
              prevEl: `.swiper-button-prev-${dailyMeditation.id}`,
            }"
            @swiper="onSwiper"
            class="mySwiper collection__list"
          >
            <template
              v-for="(content, index) in dailyMeditation.content"
              :key="index"
            >
              <swiper-slide>
                <li class="section__item">
                  <router-link
                    :to="`/${content.model}/${content.id}`"
                    v-if="content.free"
                  >
                    <div class="section__item-wrapper">
                      <div
                        class="section__item-image-wrapper"
                        :class="{
                          locked: !content.free,
                          [content.model]: content.model,
                        }"
                      >
                        <img
                          :src="`${
                            dailyMeditation.content &&
                            dailyMeditation.content.length === 1
                              ? content.daily_featured_cover
                              : content.cover
                          }`"
                          alt="image"
                          loading="lazy"
                          class="section__item-image"
                        />
                      </div>
                      <div class="section__item-title">
                        {{ content.name }}
                      </div>
                      <div class="section__item-type-wrapper">
                        <span class="section__item-type">
                          {{ content.type }}
                        </span>
                        <span
                          class="section__item-duration"
                          v-if="content.duration"
                        >
                          {{ content.duration }}
                        </span>
                      </div>
                    </div>
                  </router-link>
                  <div v-else @click="onUnlock">
                    <div class="section__item-wrapper">
                      <div
                        class="section__item-image-wrapper"
                        :class="['locked', content.model]"
                      >
                        <img
                          :src="`${
                            dailyMeditation.content &&
                            dailyMeditation.content.length === 1
                              ? content.daily_featured_cover
                              : content.cover
                          }`"
                          alt="image"
                          loading="lazy"
                          class="section__item-image"
                        />
                      </div>
                      <div class="section__item-title">
                        {{ content.name }}
                      </div>
                      <div class="section__item-type-wrapper">
                        <span class="section__item-type">
                          {{ content.type }}
                        </span>
                        <span
                          class="section__item-duration"
                          v-if="content.duration"
                        >
                          {{ content.duration }}
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </swiper-slide>
            </template>
          </swiper>
          <div
            :class="`swiper-button-prev-${dailyMeditation.id} swiper-button-prev`"
          ></div>
          <div
            :class="`swiper-button-next-${dailyMeditation.id} swiper-button-next`"
          ></div>
        </div>
      </ul>
    </section>
  </div>
  <button
    class="unlock-mental mobile"
    @click="onSubscribe"
    v-if="!userStatus.subscription_via && !isUserStatusLoading"
  >
    <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9 18a2 2 0 100-4 2 2 0 000 4z" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/><path d="M13.5 9.5h-9a4 4 0 00-4 4v6a4 4 0 004 4h9a4 4 0 004-4v-6a4 4 0 00-4-4z" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.5 6a5.5 5.5 0 0111 0v3.63" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/></svg>
    Unlock Mental Future
  </button>
  <collection-section :data="filteredSections" />
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import CollectionSection from '@/components/collection/CollectionSection.vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper'
import { metaEventCustomActions } from '@/data/constants'

SwiperCore.use([Autoplay, Pagination, Navigation])

export default {
  name: 'ScreenAndCollection',
  components: {
    Swiper,
    SwiperSlide,
    CollectionSection,
  },
  computed: {
    ...mapState('screen', ['home']),
    ...mapState(['user', 'isUserStatusLoading', 'userStatus']),
    dailyMeditation() {
      return (
        (this.home &&
          this.home.section &&
          this.home.section.length &&
          this.home.section.find(
            (i) => i.section_type === 'Daily Meditation'
          )) || {
          content: [],
        }
      )
    },
    slidesPerGroup() {
      return window.innerWidth <= 991 ? 1 : 2
    },
    filteredSections() {
      return (
        this.home &&
        this.home.section &&
        this.home.section.filter((i) => i.section_type !== 'Daily Meditation')
      )
    },
  },
  created() {
    this.fetchHome()
  },
  methods: {
    ...mapActions('screen', ['fetchHome']),
    ...mapMutations('subscription', ['SET_OPEN']),
    ...mapActions(['getUserStatus']),
    onDone() {
      this.SET_OPEN({ isOpen: false })
      window.location.reload()
    },
    onUnlock() {
      this.SET_OPEN({
        isOpen: true,
        action: metaEventCustomActions.ON_LOCKED_CLICKED,
      })
    },
    onSubscribe() {
      this.SET_OPEN({
        isOpen: true,
        action: metaEventCustomActions.ON_UNLOCK_BUTTON,
      })
    },
    onSwiper(swiper) {
      console.log(swiper)
    },
  },
}
</script>

<style lang="scss">
@import '../assets/section';

.title-container {
  display: flex;
  align-items: center;
  margin: 36px 0 43px 36px;

  @media (max-width: 991px) {
    margin: 24px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.section.daily {
  margin-bottom: 31px;
}

.unlock-mental {
  border: none;
  background: var(--color-green);
  border-radius: 58px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: var(--color-white);
  padding: 14px 70px;
  transition: all 300ms ease-in-out;
  margin-left: 36px;
  max-width: 327px;

  &.web {
    display: flex;
  }

  &.mobile {
    display: none;
    margin-top: 1px;
    margin-left: 24px;
    font-size: 14px;
    line-height: 19px;
  }

  @media (max-width: 991px) {
    margin-left: 0;

    &.mobile {
      display: flex;
    }

    &.web {
      display: none;
    }
  }

  svg {
    margin-right: 8px;
    margin-top: -2px;
  }

  &:hover {
    opacity: 0.8;
  }
}
</style>

<template>
  <main class="checkout">
    <checkout-header />
    <checkout-warning />
    <checkout-silence />
    <checkout-total />
    <div class="container-checkout">
      <checkout-get-started-now />
    </div>
    <checkout-timer />
    <checkout-panic />
    <checkout-news />
    <checkout-triangle />
    <div class="container-checkout">
      <checkout-get-started-now />
    </div>
    <checkout-comentar />
    <checkout-magic />
    <checkout-weapon-against />
    <checkout-mood />
    <checkout-behind-curtains />
    <checkout-curtains />
    <checkout-benefits-of-meditation />
    <checkout-testimonials />
    <div class="container-checkout" style="padding-bottom: 92px">
      <checkout-get-started-now />
    </div>
    <checkout-not-so-secret />
    <checkout-not-so-secret-items />
    <checkout-change-their-life />
    <checkout-reviews />
    <div class="container-checkout" style="padding-bottom: 92px">
      <checkout-get-started-now />
    </div>
    <!-- <checkout-be-crazy /> -->
    <checkout-meditation-course-title />
    <checkout-what-you-get />
    <div class="container-checkout" style="padding-bottom: 48px">
      <checkout-get-started-now />
    </div>
    <!-- <checkout-investing /> -->
    <!-- <checkout-willing-to-do /> -->
    <CheckoutFAQ />
    <checkout-footer />
    <subscription-modal @onDone="onDone" />
  </main>
</template>

<script>
import CheckoutHeader from './CheckoutHeader'
import CheckoutWarning from './CheckoutWarning.vue'
import CheckoutSilence from './CheckoutSilence.vue'
import CheckoutTotal from './CheckoutTotal.vue'
import CheckoutGetStartedNow from './CheckoutGetStartedNow.vue'
import CheckoutTimer from './CheckoutTimer.vue'
import CheckoutPanic from './CheckoutPanic.vue'
import CheckoutNews from './CheckoutNews.vue'
import CheckoutTriangle from './CheckoutTriangle.vue'
import CheckoutComentar from './CheckoutComentar.vue'
import CheckoutMagic from './CheckoutMagic.vue'
import CheckoutWeaponAgainst from './CheckoutWeaponAgainst.vue'
import CheckoutMood from './CheckoutMood.vue'
import CheckoutBehindCurtains from './CheckoutBehindCurtains.vue'
import CheckoutCurtains from './CheckoutCurtains.vue'
import CheckoutBenefitsOfMeditation from './CheckoutBenefitsOfMeditation.vue'
import CheckoutTestimonials from './CheckoutTestimonials.vue'
import CheckoutNotSoSecret from './CheckoutNotSoSecret.vue'
import CheckoutNotSoSecretItems from './CheckoutNotSoSecretItems.vue'
import CheckoutChangeTheirLife from './CheckoutChangeTheirLife.vue'
import CheckoutReviews from './CheckoutReviews.vue'
import CheckoutBeCrazy from './CheckoutBeCrazy.vue'
import CheckoutMeditationCourseTitle from './CheckoutMeditationCourseTitle.vue'
import CheckoutWhatYouGet from './CheckoutWhatYouGet.vue'
import CheckoutInvesting from './CheckoutInvesting.vue'
import CheckoutWillingToDo from './CheckoutWillingToDo.vue'
import CheckoutFooter from './CheckoutFooter.vue'
import SubscriptionModal from '../subscribe/SubscriptionModal.vue'
import { mapActions, mapMutations } from 'vuex'
import CheckoutFAQ from './CheckoutFAQ.vue'

export default {
  name: 'Checkout',
  components: {
    CheckoutHeader,
    CheckoutWarning,
    CheckoutSilence,
    CheckoutTotal,
    CheckoutGetStartedNow,
    CheckoutTimer,
    CheckoutPanic,
    CheckoutNews,
    CheckoutTriangle,
    CheckoutComentar,
    CheckoutMagic,
    CheckoutWeaponAgainst,
    CheckoutMood,
    CheckoutBehindCurtains,
    CheckoutCurtains,
    CheckoutBenefitsOfMeditation,
    CheckoutTestimonials,
    CheckoutNotSoSecret,
    CheckoutNotSoSecretItems,
    CheckoutChangeTheirLife,
    CheckoutReviews,
    CheckoutBeCrazy,
    CheckoutMeditationCourseTitle,
    CheckoutWhatYouGet,
    CheckoutInvesting,
    CheckoutWillingToDo,
    CheckoutFooter,
    SubscriptionModal,
    CheckoutFAQ,
  },
  created() {
    this.getFAQs()
  },
  methods: {
    ...mapMutations('subscription', ['SET_OPEN']),
    ...mapActions('faq', ['getFAQs']),
    onDone() {
      this.SET_OPEN({ isOpen: false })
      this.$router.push('/')
    },
  },
}
</script>

<style lang="scss">
.checkout {
  background: var(--theme-green-forest);
  color: var(--color-white);
}

.container-checkout {
  max-width: 1440px;
  width: 100%;
  margin-inline: auto;
  padding-inline: 36px;
}
</style>

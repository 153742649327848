<template>
  <div class="checkout-white-logos">
    <div class="checkout-white-logos__container">
      <ul class="checkout-white-logos__logos">
        <li
          v-for="(logo, index) in logos"
          :key="index"
          class="checkout-white-logos__logo">
          <img :src="require(`@/static${logo.src}`)" :alt="`${logo.title}`">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutHeader',
  data () {
    return {
      logos: [
        {
          title: 'Forbes',
          src: '/checkout-white/logos/forbes.svg',
        },
        {
          title: 'Entrepreneur',
          src: '/checkout-white/logos/entrepreneur.svg',
        },
        {
          title: 'Abc',
          src: '/checkout-white/logos/abc.svg',
        },
        {
          title: 'Cbs',
          src: '/checkout-white/logos/cbs.svg',
        },
        {
          title: 'Inc',
          src: '/checkout-white/logos/inc.svg',
        },
        {
          title: 'Profit',
          src: '/checkout-white/logos/profit.png',
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.checkout-white-logos {
  padding-top: 72px;

  &__logos {
    display: flex;
    align-items: center;
  }

  &__logo {

    &:not(:last-child) {
      margin-right: 69px;
    }
  }
}
</style>
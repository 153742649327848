import { getRecommendation } from '../../services/gist.service'

const state = () => ({
  recommendation: [],
})

const actions = {
  async fetchRecommendation({ commit }) {
    return getRecommendation().then((res) => {
      commit('SET_RECOMMENDATION', res)
    })
  },
}

const mutations = {
  SET_RECOMMENDATION(state, data) {
    state.recommendation = data
  },
}

export const gist = {
  namespaced: true,
  state,
  actions,
  mutations,
}

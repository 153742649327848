<template>
  <div class="checkout-testimonials">
    <div class="checkout-testimonials__container container-checkout">
      <ul class="checkout-testimonials__items">
        <li
          v-for="(item, index) in items"
          :key="index"
          class="checkout-testimonials__item"
        >
          <img
            :src="require(`@/static/checkout/testimonials/${item.avatar}`)"
            :alt="item.name"
            class="checkout-testimonials__avatar"
          />
          <div class="checkout-testimonials__item-name">
            {{ item.name }}
          </div>
          <div class="checkout-testimonials__item-text">
            {{ item.text }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutTestimonials',
  data() {
    return {
      items: [
        {
          avatar: 'amy.png',
          name: 'Amy Huang',
          text: "It's amazing what you've done for us!",
        },
        {
          avatar: 'nathan.png',
          name: 'Nathan Baker',
          text: "I can't believe how much it's all helped...",
        },
        {
          avatar: 'carol.png',
          name: 'Carol Matz',
          text: "I'm just so grateful you've shared this information with us all.",
        },
        {
          avatar: 'sasha.png',
          name: 'Sasha Golovkin',
          text: '...thank God I found your app when I did!',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.checkout-testimonials {
  padding-bottom: 94px;

  &__items {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    width: calc(25% - 10px);
  }

  &__item-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    padding: 12px 0 4px;

    &:after {
      content: ':';
    }
  }
  &__item-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    &:after,
    &:before {
      content: '"';
    }
  }
}
</style>

<template>
  <div class="recommendation unlock-subscribe">
    <subscribe-header @onClose="onClose" />
    <div class="recommendation__body">
      <h3 class="recommendation__title">
        Recommended For You Based On Your Answers
      </h3>
      <a-carousel>
        <router-link
          :to="`/${item.model}/${item.id}`"
          class="recommendation__card"
          v-for="item in gistItems"
          :key="item.id"
        >
          <img class="recommendation__card__img" :src="item.cover" />
          <h3 class="recommendation__card__title">
            {{ item.name }}
          </h3>
          <p class="recommendation__card__text">{{ item.type }}</p>
        </router-link>
      </a-carousel>
      <button class="btn btn--white recommendation__start" @click="onStart">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 23.5c6.351 0 11.5-5.149 11.5-11.5S18.351.5 12 .5.5 5.649.5 12 5.649 23.5 12 23.5z" stroke="#134E5E" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.5 16.5v-9l9 4.5-9 4.5z" stroke="#134E5E" stroke-linecap="round" stroke-linejoin="round"/></svg>
        <span class="recommendation__start__text">Start Listening</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import SubscribeHeader from './SubscribeHeader.vue'
export default {
  name: 'Recommendation',
  components: { SubscribeHeader },
  computed: {
    ...mapState('recommendation', ['gistItems']),
  },
  created() {
    this.fetchGistRecommendation()
  },
  methods: {
    ...mapActions('recommendation', ['fetchGistRecommendation']),
    ...mapMutations('subscription', ['SET_OPEN']),
    onClose() {
      this.$emit('onClose')
    },
    onStart() {
      this.SET_OPEN({ isOpen: false })
      this.$router.push('/home')
    },
  },
}
</script>

<style lang="scss" scoped>
.recommendation {
  &__title {
    margin: 72px 0 50px;
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: var(--color-white);
    font-family: var(--run);
  }

  &__body {
    max-width: 327px;
    margin: 0 auto;
  }

  &__card {
    &__img {
      border-radius: 12px;
      height: 216px;
      width: 100%;
      object-fit: cover;
    }
    &__title {
      font-size: 14px;
      line-height: 19px;
      color: var(--color-white);
      font-weight: 700;
      margin: 12px 0 4px;
    }
    &__text {
      font-size: 12px;
      line-height: 16px;
      color: var(--color-white);
      font-weight: 400;
      text-transform: capitalize;
    }
  }

  &__start {
    margin-top: 174px;
    padding: 14px 0;
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;

    &:hover,
    &:focus {
      svg > path {
        stroke: var(--color-white);
      }
    }
    &__text {
      margin-left: 12px;
    }
  }
}

.ant-carousel :deep(.slick-slide) {
  width: 327px;
  background: inherit;
  overflow: hidden;
}

.ant-carousel :deep(.slick-dots-bottom) {
  bottom: -36px;
}

.ant-carousel :deep(.slick-dots li) {
  width: 8px;
  height: 8px;
  border: 1px solid #ffffff;
  border-radius: 50%;
}

.ant-carousel :deep(.slick-dots li.slick-active) {
  width: 8px;
}

.ant-carousel :deep(.slick-dots li button) {
  height: 8px;
  opacity: 1;
  background: inherit;
}

.ant-carousel :deep(.slick-dots li.slick-active button) {
  background: var(--color-white);
}
</style>

<template>
  <template v-if="musicCollection">
    <collection-section :data="musicCollection.section" />
  </template>
</template>

<script>
import axios from 'axios'
import CollectionSection from '@/components/collection/CollectionSection.vue'
import { getScreen } from '@/services/screen.service'
export default {
  name: 'MusicCollection',
  components: { CollectionSection },
  data() {
    return {
      musicCollection: {},
      tags: [],
    }
  },
  created() {
    getScreen('music').then((response) => {
      this.musicCollection = response
    })

    axios.get(`tags`).then((response) => {
      this.tags = response.tags
    })
  },
}
</script>

<style lang="scss"></style>

<template>
  <div
    class="packages"
    :class="!removeContainer && 'container container--vsl'"
    id="packages"
  >
    <a-radio-group
      v-show="!isPackageLoading"
      :value="modelValue"
      @update:value="
        (value) => {
          $emit('update:modelValue', value)
          store.dispatch('packageModule/setActivePackage', value)
        }
      "
      style="width: 100%"
    >
      <div class="package__items">
        <template v-for="c in defaultPackages" :key="c.id">
          <package-card :cardPackage="c" />
        </template>
      </div>
    </a-radio-group>
    <p class="package__text">
      By selecting a payment method, you agree to the
      <router-link to="/terms-of-service">
        <span>Terms & Conditions</span>
      </router-link>
      and
      <router-link to="privacy-policy">
        <span>Privacy Policy</span>
      </router-link>
    </p>
    <button
      class="paywall-button package-button"
      :class="fullWithButton && 'full-width'"
      :disabled="isPackageLoading"
      @click="onGetPlan"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 30 26" fill="none" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M23.2 25a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6zM10 25a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6zM1 1h4.2L10 21.4h13.2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.437 19H25l3.6-10.8H6.892" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
      {{ btnText }}
    </button>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import PackageCard from './PackageCard.vue'

const store = useStore()

// const props = defineProps([
//   'isPackageLoading',
//   'onGetPlan',
//   'defaultPackages',
//   'modelValue',
//   'btnText',
//   'removeContainer',
// ])

const props = defineProps({
  isPackageLoading: {
    type: Boolean,
  },
  onGetPlan: {
    type: Function,
  },
  defaultPackages: {
    type: Array,
  },
  modelValue: {
    type: String,
  },
  btnText: {
    type: String,
    default: 'Get My Plan',
  },
  removeContainer: {
    type: Boolean,
    default: false,
  },
  fullWithButton: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['update:modelValue'])
</script>

<style lang="scss">
.packages {
  // padding: 0 80px;
  // background: rgba(255, 255, 255, 0.1);
  scroll-margin: 300px;
}
.package {
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
    margin: 0 0 40px;

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__text {
    text-align: center;
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 20px;

    & span {
      font-weight: 700;
    }
  }

  &-button {
    margin: 0 auto;
  }

  @media (max-width: 767px) {
    margin-bottom: 24px;

    &__text {
      margin-bottom: 24px;
      font-size: 16px;
    }
  }
}
</style>

<template>
  <div class="checkout-what-you-get">
    <div class="checkout-what-you-get__container container-checkout">
      <div class="checkout-what-you-get__title">
        {{ title }}
      </div>
      <ul class="checkout-what-you-get__list">
        <li
          v-for="(item, index) in items"
          :key="index"
          class="checkout-what-you-get__item"
        >
          {{ item.text }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutWhatYouGet',
  data() {
    return {
      title: 'Here’s Exactly What You’re Getting:',
      items: [
        {
          text: 'Hundreds of courses, lessons, therapies tackling stress, anxiety, sleep disorders, motivation, focus, self-love and more',
        },
        {
          text: 'Beginner and Intermediate Meditation Courses addressing specific mental issues',
        },
        {
          text: '5-Minute Meditations for a busy schedule',
        },
        {
          text: 'Sleepcasts to effortlessly drift into sleep in 5 minutes',
        },
        {
          text: 'In-depth Cognitive behavioral therapy course, developed by expert practitioners, complete with journal logging for progress tracking',
        },
        {
          text: 'Expertly produced calming music and soundscapes to enter a relaxed state of mind',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.checkout-what-you-get {
  padding-top: 92px;
  padding-bottom: 72px;

  &__container {
    max-width: 1296px !important;
  }

  &__title {
    font-weight: 800;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    padding-bottom: 32px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 72px;
    border-bottom: 1px dashed var(--color-white);
  }

  &__item {
    position: relative;
    flex: 0 1 calc(50% - 40px);
    max-width: calc(50% - 40px);
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    margin-top: 44px;
    padding-left: 44px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 33px;
      height: 33px;
      background-image: url('~@/static/checkout/check-arrow.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
</style>

<template>
  <li class="section__item small" v-if="content.free">
    <router-link :to="`/${content.model}/${content.id}`">
      <div class="section__item-wrapper">
        <div class="section__item-image-wrapper small" :class="content.model">
          <img
            :src="`${content.cover}`"
            alt="image"
            loading="lazy"
            class="section__item-image"
          />
        </div>
        <div class="section__item-title small">
          {{ content.name }}
        </div>
        <div class="section__item-type-wrapper small">
          <span class="section__item-type">
            {{ content.type }}
          </span>
          <span class="section__item-duration" v-if="content.duration">
            {{ content.duration }}
          </span>
          <span v-else-if="content.amount_lessons">
            {{ content.amount_lessons }} sessions
          </span>
        </div>
      </div>
    </router-link>
  </li>
  <li class="section__item small" v-else>
    <div class="section__item-wrapper" @click="onUnlock">
      <div
        class="section__item-image-wrapper small"
        :class="[{ locked: !content.free }, content.model]"
      >
        <img
          :src="`${content.cover}`"
          alt="image"
          loading="lazy"
          class="section__item-image"
        />
      </div>
      <div class="section__item-title small">
        {{ content.name }}
      </div>
      <div class="section__item-type-wrapper small">
        <span class="section__item-type">
          {{ content.type }}
        </span>
        <span class="section__item-duration" v-if="content.duration">
          {{ content.duration }}
        </span>
      </div>
    </div>
  </li>
</template>

<script>
import { metaEventCustomActions } from '@/data/constants'
import { mapMutations } from 'vuex'
export default {
  name: 'SectionCardSmall',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    ...mapMutations('subscription', ['SET_OPEN']),
    onUnlock() {
      this.SET_OPEN({
        isOpen: true,
        action: metaEventCustomActions.ON_LOCKED_CLICKED,
      })
    },
    onDone() {
      window.location.reload()
    },
  },
}
</script>

<style></style>

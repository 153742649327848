<template>
  <div class="page-wrapper theme-green-forest">
    <Header :user="user" />
    <SleepCollection />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import SleepCollection from '@/layouts/SleepCollection.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Sleep',
  components: { Header, SleepCollection, Footer },
  props: ['user'],
}
</script>
<style lang="scss" scoped>
.title-container {
  padding: 0 36px;
}
</style>

<template>
  <div class="checkout-change-their-life">
    <div class="checkout-change-their-life__container">
      <div class="checkout-change-their-life__wrapper">
        <div class="checkout-change-their-life__title">
          {{ title }}
        </div>
        <checkout-white-get-started-now />
        <checkout-white-timer :title="'Available only for'" />
        <div
          class="checkout-change-their-life__subtitle"
          v-html="subtitle"
        ></div>
        <div class="checkout-change-their-life__arrow">
          <img
            src="@/static/checkout-white/icons/arrow.svg"
            alt="arrow"
            class="checkout-change-their-life__arrow-icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckoutWhiteGetStartedNow from './CheckoutWhiteGetStartedNow.vue'
import CheckoutWhiteTimer from './CheckoutWhiteTimer.vue'

export default {
  name: 'CheckoutChangeTheirLife',
  components: {
    CheckoutWhiteGetStartedNow,
    CheckoutWhiteTimer,
  },
  data() {
    return {
      title: 'Are You Ready To Start Your Journey?',
      subtitle: 'Some Of The People We Have Helped <b>Change Their Life</b>',
    }
  },
}
</script>

<style lang="scss">
.checkout-change-their-life {
  padding-bottom: 100px;

  &__wrapper {
    position: relative;
    padding: 127px 0 0;
    border-radius: 12px;
  }

  &__title,
  &__subtitle {
    text-align: center;
  }

  &__title {
    font-weight: 800;
    font-size: 48px;
    line-height: 56px;
    padding-bottom: 52px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 56px;
    line-height: 56px;
    margin-inline: auto;

    b {
      display: block;
      font-weight: 800;
    }
  }

  &__arrow {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background-color: var(--color-red);
  }
}
</style>

<template>
  <a-modal
    v-model:visible="isOpen"
    width="100%"
    wrap-class-name="full-modal"
    :closable="false"
    :footer="null"
    :destroyOnClose="true"
  >
    <Purchase
      v-if="!isRecommendation"
      @onClose="onClose"
      @onSuccess="onSuccess"
    />
    <Recommendation v-if="isRecommendation" @onClose="closeRecommendation" />
  </a-modal>
</template>

<script>
import Purchase from '@/components/subscribe/Purchase.vue'
import { mapMutations, mapState } from 'vuex'
import Recommendation from './Recommendation.vue'
export default {
  name: 'SubscriptionModal',
  components: { Purchase, Recommendation },
  props: {
    isShowRecommendation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPurchase: false,
      packageType: '',
      isRecommendation: false,
    }
  },
  computed: {
    ...mapState('subscription', ['isOpen']),
  },
  methods: {
    ...mapMutations('subscription', ['SET_OPEN']),
    onSelect(packageType) {
      this.isPurchase = true
      this.packageType = packageType
    },
    onClose() {
      this.isPurchase = false
      this.SET_OPEN({ isOpen: false })
    },
    closeRecommendation() {
      this.onClose()
      this.$emit('done')
    },
    onSuccess() {
      if (this.isShowRecommendation) {
        this.isPurchase = false
        this.isRecommendation = true
      } else {
        this.onClose()
        this.$emit('done')
      }
    },
  },
}
</script>

<style lang="scss">
.unlock-subscribe {
  background: linear-gradient(341.28deg, #71b280 -33.77%, #134e5e 114.22%);
  padding: 36px 26px;
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    font-family: var(--run);
    font-weight: 500;
    font-size: 36px;
    line-height: 36px;
    color: var(--color-white);
  }

  &__close {
    background-color: inherit;
    border: none;
    cursor: pointer;
  }

  &__body {
    margin: 72px auto 0;
    width: 335px;
  }

  &__title {
    font-family: var(--run);
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: var(--color-white);
    border-bottom: 1px solid var(--color-white);
    padding-bottom: 8px;
  }

  &__try {
    margin-top: 44px;

    &__info {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: var(--color-white);
      text-align: center;
      margin-bottom: 12px;
    }

    &__btn {
      font-weight: 500;
      background: var(--color-white);
      border-radius: 58px;
      font-size: 14px;
      line-height: 19px;
      color: var(--color-main);
      padding: 17px 0;
      border: none;
      cursor: pointer;
    }
  }

  &__prices {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    align-items: flex-end;
    margin-top: 44px;

    & > button {
      background: inherit;
      border: none;
      cursor: pointer;
    }
  }

  &__price {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &.yearly {
      margin-bottom: 12px;

      .unlock-subscribe__price__card {
        background: var(--color-main);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding-bottom: 16px;
      }
    }

    &__card {
      border-radius: 12px;
      background: var(--color-green);
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--color-white);
      padding: 16px 20px 20px 15px;
    }

    .card {
      &__title {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: var(--color-white);
      }

      &__currency {
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        display: flex;
        align-items: flex-start;

        & > strong {
          font-size: 32px;
          line-height: 30px;
          margin-top: -2px;
          margin-left: 2px;
        }
      }

      &__popular {
        font-size: 12px;
        font-weight: 400;
        line-height: 21px;
        color: var(--color-main);
        border-radius: 12px 12px 0px 0px;
        background-color: var(--color-white);
        text-align: center;
        padding: 2px 0 1px;
      }

      &__date {
        font-size: 12px;
        line-height: 15px;
        font-weight: 700;
        margin-left: 2px;

        & > p {
          margin: 0;
        }
      }

      &__price {
        display: flex;
      }

      &__label {
        font-size: 12px;
        line-height: 21px;
        font-weight: 400;
        color: var(--color-white);
        text-align: center;
        margin: 0;
      }
    }
  }

  &__terms {
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
    font-weight: 400;
    margin-top: 32px;
    margin-bottom: 50px;
  }
}
</style>

export const workbookFieldType = {
  MULTI_SELECT: 'multi select',
  MULTI_TEXT: 'multi text',
  TEXT: 'text',
  SELECT: 'select',
  RANGE: 'range',
}

export const workbookType = {
  GENERAL_ISSUES: 'General Issues',
  GOALS: 'Setting Goals',
  EXPOSURE_HIERARCHY: 'Exposure Hierarchy',
  COMPONENTS_EMOTION: 'Components Of Emotion',
  MONITORING_MOODS: 'Monitoring Moods',
  THOUGHT_RECORD: 'Thought Record',
  THOUGHT_RECORD_REVIEW: 'Thought Record Review',
  PLEASURE_MASTERY_LIST: 'Pleasure And Mastery List',
  THOUGHT_CHAIN_EFFECT: 'Thought Chain Effect',
  BAD_THOUGHT_DESTROYER: 'Bad Thoughts Destroyer',
  COGNITIVE_DISTORTIONS: 'Cognitive Distortions',
}

<template>
  <div class="checkout-white-discover">
    <div class="checkout-white-discover__title">
      {{ title }}
    </div>
    <div class="checkout-white-discover__texts">
      <p
        v-for="(item, index) in texts"
        :key="index"
        v-html="item.text"
        class="checkout-white-discover__text"
      ></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutWhiteDiscover',
  data() {
    return {
      title:
        'Discover the tools and solutions for reaching your mental goal from leading world experts',
      texts: [
        {
          text: 'No matter how long you have had your mental goal or how unique and strange you think it is, you can and you will overcome it with the help of our method.',
        },
        {
          text: 'Ideas like, the only way to treat this problem is to be on medication all your life or that it is something you will just have to cope with forever are NOT TRUE.',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.checkout-white-discover {
  text-align: center;
  padding-bottom: 72px;

  &__title {
    font-weight: 700;
    font-size: 46px;
    line-height: 63px;
    padding-bottom: 24px;
  }

  &__text {
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}
</style>

export function setCookie(cname, cvalue, exdays) {
  var d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  var expires = 'expires=' + d.toUTCString()
  document.cookie =
    cname +
    '=' +
    cvalue +
    ';' +
    expires +
    ';path=/;domain=mentalfuture.com;secure'
}

export const videoUrl =
  'https://static.mentalfuture.com/open-files/general/MeditationBackgroundMusicandSubtitlesV2.mp4'

export function support_format_webp() {
  const elem = document.createElement('canvas')

  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0
  } else {
    return false
  }
}

export function getWebpHead() {
  let head = {}
  if (support_format_webp()) {
    head = { Accept: 'image/webp' }
  }
  return head
}

export function getCurrency(c) {
  switch (c) {
    case 'EUR':
      return '€'
    default:
      return '$'
  }
}

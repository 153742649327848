import { getFAQs } from '@/services/faq.service'

const state = () => ({
  questions: {},
  meta: {},
  isLoading: false,
})

const actions = {
  async getFAQs({ commit }, params) {
    commit('SET_LOADING', true)
    return getFAQs(params)
      .then((res) => {
        commit('SET_QUESTIONS', res.data)
        commit('SET_META', res.meta)
      })
      .finally(() => commit('SET_LOADING', false))
  },
}

const mutations = {
  SET_QUESTIONS(state, data) {
    state.questions = data
  },
  SET_META(state, data) {
    state.meta = data
  },
  SET_LOADING(state, data) {
    state.isLoading = data
  },
}

export const faq = {
  namespaced: true,
  state,
  actions,
  mutations,
}

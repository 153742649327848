import axios from 'axios'

const prefix = '/faq'

export const getFAQs = async (params) => {
  return await axios.get(`${prefix}`, { params })
}

export const getFAQCategories = async (params) => {
  return await axios.get(`/faq-category`, { params })
}

<template>
  <div class="error">Some error</div>
</template>

<script>
export default {
  name: 'Error',
}
</script>

<style lang="scss">
.error {
  padding: 1rem 36px;
  background-color: rgb(233, 99, 99);
  color: var(--color-white);
}
</style>

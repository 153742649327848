<template>
  <div class="dashboard">
    <div class="dashboard__container">
      <div class="dashboard__quote quote">
        <div class="quote__value">
          {{ quote.quote }}
        </div>
        <div class="quote__author">
          {{ quote.author }}
        </div>
      </div>
      <!-- {{ sessions }} -->
      <div class="dashboard__sessions sessions">
        <div class="sessions__wrapper">
          <div class="sessions__item">
            <div class="sessions__progress progress">
              <div class="sessions__title progress__title">My Progress</div>
              <div class="progress__wrapper">
                <div class="progress__mindful-title">Mindful Days</div>
                <div class="progress__mindful-icon">
                  <img
                    src="@/static/profile/dashboard/days.svg"
                    alt="Mindful icon"
                  />
                </div>
                <div class="progress__mindful-value">
                  {{ sessions.mindful_days }}
                  <!-- TODO: 0 days, 1 day, 2-31 days -->
                  <span> days </span>
                </div>
                <ul class="progress__items">
                  <li class="progress__item">
                    <div class="progress__item-icon">
                      <img
                        src="@/static/profile/dashboard/total-sessions.svg"
                        alt="icon"
                      />
                    </div>
                    <div class="progress__item-title">Total Sessions</div>
                    <div class="progress__item-value">
                      {{ sessions.total_sessions }}
                    </div>
                  </li>
                  <li class="progress__item">
                    <div class="progress__item-icon">
                      <img
                        src="@/static/profile/dashboard/clock.svg"
                        alt="icon"
                      />
                    </div>
                    <div class="progress__item-title">Mindful Minutes</div>
                    <div class="progress__item-value">
                      {{ sessions.mindful_minutes }} min
                    </div>
                  </li>
                  <li class="progress__item">
                    <div class="progress__item-icon">
                      <img
                        src="@/static/profile/dashboard/longest-streak.svg"
                        alt="icon"
                      />
                    </div>
                    <div class="progress__item-title">Longest Streak</div>
                    <div class="progress__item-value">
                      {{ sessions.longest_streak }}
                      <!-- TODO: 0 days, 1 day, 2-31 days -->
                      <span> days </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="sessions__item calendar">
            <div class="sessions__title calendar__title">My Streaks</div>
            <div class="calendar__top">
              <div class="calendar__top-item">
                <div class="calendar__top-item-icon">
                  <img
                    src="@/static/profile/dashboard/total-sessions-white.svg"
                    alt="icon"
                  />
                </div>
                <div class="calendar__top-item-value">
                  Total
                  <div>{{ sessions.total_streak }} Streak</div>
                </div>
              </div>
              <div class="calendar__top-item">
                <div class="calendar__top-item-icon">
                  <img
                    src="@/static/profile/dashboard/longest-streak-white.svg"
                    alt="icon"
                  />
                </div>
                <div class="calendar__top-item-value">
                  Longest
                  <div>{{ sessions.longest_minute_session }} min</div>
                </div>
              </div>
              <div class="calendar__top-item">
                <div class="calendar__top-item-icon">
                  <img
                    src="@/static/profile/dashboard/clock-white.svg"
                    alt="icon"
                  />
                </div>
                <div class="calendar__top-item-value">
                  Current
                  <div>{{ sessions.longest_streak }} days</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Dashboard',
  data() {
    return {
      quote: {},
      sessions: [],
    }
  },
  created() {
    axios.get(`user/sessions`).then((response) => {
      this.sessions = response
    })
    axios
      .get(`quote`, {
        params: {
          rand: true,
        },
      })
      .then((response) => {
        this.quote = response
      })
  },
}
</script>

<style lang="scss" scoped>
.dashboard {
  &__container {
    max-width: 774px;
    width: 100%;
    padding: 0 24px;
    margin-left: auto;
    margin-right: auto;
  }

  &__quote {
    margin-bottom: 36px;
  }
}
.quote {
  padding: 36px 10px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  text-align: center;

  &__value,
  &__author {
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-white);
  }

  &__author {
    padding-top: 24px;
  }
}
.sessions {
  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-white);
    padding-bottom: 24px;
    text-align: center;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    width: calc(50% - 36px);
  }
}
.progress {
  &__wrapper {
    background-color: var(--color-white);
    padding: 24px;
    border-radius: 12px;
  }

  &__mindful-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    padding-bottom: 40px;
    color: var(--color-main);
  }
  &__mindful-icon {
    text-align: center;
    margin-bottom: 24px;
  }
  &__mindful-value {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-main);
    text-align: center;
    padding-bottom: 36px;
  }

  &__items {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__item {
    width: calc(33.33% - 27px);
  }

  &__item-icon {
    text-align: center;

    img {
      width: 24px;
      height: 24px;
    }
  }

  &__item-title,
  &__item-value {
    font-size: 12px;
    line-height: 16px;
    color: var(--color-main);
    text-align: center;
  }

  &__item-title {
    font-weight: 400;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &__item-value {
    font-weight: 700;
  }
}
.calendar {
  &__top {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    border-radius: 12px 12px 0 0;
    background-color: var(--color-main);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  &__top-item-icon {
    text-align: center;
    margin-bottom: 8px;

    img {
      width: 24px;
      height: 24px;
    }
  }

  &__top-item-value {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-white);
    text-align: center;

    & > div {
      font-weight: 700;
    }
  }
}
</style>

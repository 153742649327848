import {
  getAllMeditationSleep,
  getAllMeditationSleepCourse,
} from '../../services/meditation-sleep.service'
import { models } from '@/data/constants'
import { getAllMusicSoundscape } from '../../services/music-soundscape.service'
import { getAllCbt, getAllCbtCourse } from '../../services/cbt.service'
import { getRecommendation } from '../../services/gist.service'

const state = () => ({
  items: [],
  gistItems: [],
})

const actions = {
  fetchRecommendation({ commit }, { params, section }) {
    switch (section) {
      case models.MEDITATION_SLEEP:
        getAllMeditationSleep(params).then((res) => {
          commit('SET_DATA', res.data)
        })
        break
      case models.MEDITATION_SLEEP_COURSE:
        getAllMeditationSleepCourse(params).then((res) => {
          commit('SET_DATA', res.data)
        })
        break
      case models.MUSIC_SOUNDSCAPE:
        getAllMusicSoundscape(params).then((res) => {
          commit('SET_DATA', res.data)
        })
        break
      case models.MUSIC_SOUNDSCAPE_PLAYLIST:
        getAllMeditationSleepCourse(params).then((res) => {
          commit('SET_DATA', res.data)
        })
        break
      case models.CBT:
        getAllCbt(params).then((res) => {
          commit('SET_DATA', res.data)
        })
        break
      case models.CBT_COURSE:
        getAllCbtCourse(params).then((res) => {
          commit('SET_DATA', res.data)
        })
        break
    }
  },
  fetchGistRecommendation({ commit }) {
    getRecommendation().then((res) => {
      commit('SET_GIST_DATA', res)
    })
  },
}

const mutations = {
  SET_DATA(state, data) {
    state.items = data
  },
  SET_GIST_DATA(state, data) {
    state.gistItems = data
  },
}

export const recommendation = {
  namespaced: true,
  state,
  actions,
  mutations,
}

<template>
  <div class="checkout-header">
    <div class="checkout-header__container container-checkout">
      <div class="checkout-header__title">
        {{ title }}
      </div>
      <ul class="checkout-header__logos">
        <li
          v-for="(logo, index) in logos"
          :key="index"
          class="checkout-header__logo"
        >
          <img :src="require(`@/static${logo.src}`)" :alt="`${logo.title}`" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutHeader',
  data() {
    return {
      title: 'As Seen On:',
      logos: [
        {
          title: 'Forbes',
          src: '/checkout/logos/forbes.svg',
        },
        {
          title: 'Entrepreneur',
          src: '/checkout/logos/entrepreneur.svg',
        },
        {
          title: 'Abc',
          src: '/checkout/logos/abc.svg',
        },
        {
          title: 'Cbs',
          src: '/checkout/logos/cbs.svg',
        },
        {
          title: 'Inc',
          src: '/checkout/logos/inc.svg',
        },
        {
          title: 'Profit',
          src: '/checkout/logos/profit.png',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.checkout-header {
  &__container {
    display: flex;
    align-items: center;
    padding-block: 45px;
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-white);
    margin-right: 5.5%;
    white-space: nowrap;
  }

  &__logos {
    display: flex;
    align-items: center;
  }

  &__logo {
    &:not(:last-child) {
      margin-right: 76px;
    }
  }
}
</style>

import axios from 'axios'

const prefix = '/quiz'

export const getQuiz = async (params = { platform: 'web' }) => {
  return await axios.get(`${prefix}`, { params })
}

export const getSingleQuiz = async (id) => {
  return await axios.get(`${prefix}/web/${id}`)
}

export const getQuizFields = async () => {
  return await axios.get(`${prefix}/fields`)
}

export const getCustomQuiz = async (idOrSlug, params = { platform: 'web' }) => {
  return await axios.get(`quiz-builder/${idOrSlug}`, { params })
}

export const setQuiz = async (params) => {
  return await axios.post(`${prefix}`, null, { params })
}

export const getVsl = async (idOrSlug) => {
  return await axios.get(`/vsl/${idOrSlug}`)
}

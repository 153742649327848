<template>
  <div class="unlock-subscribe__header">
    <div>
      <span @click="$router.push('/')" class="unlock-subscribe__logo"
        >Mental Future</span
      >
    </div>
    <button
      v-if="!noCloseBtn"
      class="unlock-subscribe__close"
      @click="$emit('onClose')"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.5 7.5l9 9M16.5 7.5l-9 9M12 23.5c6.351 0 11.5-5.149 11.5-11.5S18.351.5 12 .5.5 5.649.5 12 5.649 23.5 12 23.5z" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/></svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'SubscribeHeader',
  props: {
    noCloseBtn: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.unlock-subscribe__logo {
  cursor: pointer;
  @media screen and (max-width: 650px) {
    display: none;
  }
}
</style>

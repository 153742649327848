<template>
  <div class="page-wrapper">
    <Header :user="user" />
    <div class="settings">
      <router-link to="/settings" class="settings__back-link">
        <img
          src="@/static/settings/arrow.svg"
          alt="Arrow back"
          title="Back to profile settings"
        />
      </router-link>
      <div class="settings__container container container--big">
        <div class="settings__top">
          <div class="settings__top-title">Help & Support</div>
        </div>
      </div>

      <div class="settings__faq-wrapper">
        <div class="settings__faq-items">
          <div class="settings__faq-item">
            <details class="settings__faq-item-details">
              <summary class="settings__faq-item-summary">
                Lorem ipsum dolor sit amet?
              </summary>
              <div class="settings__faq-item-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Praesent tempor aliquet congue. Mauris blandit, metus ac
                fringilla dictum, ex mauris convallis quam, eget facilisis diam
                justo vel neque. Sed congue et elit nec aliquam. Interdum et
                malesuada fames ac ante ipsum primis in faucibus. Pellentesque
                eget consectetur risus. Nunc et porttitor risus. Duis sit amet
                massa diam. Etiam molestie leo id mattis pharetra. Cras id
                volutpat mi.
              </div>
            </details>
          </div>
          <div class="settings__faq-item">
            <details class="settings__faq-item-details">
              <summary class="settings__faq-item-summary">
                Lorem ipsum dolor sit amet?
              </summary>
              <div class="settings__faq-item-text">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like
              </div>
            </details>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer.vue'

export default {
  props: ['user'],
  name: 'HelpAndSupport',
  components: { Header, Footer },
}
</script>

<style lang="scss">
.settings {
  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 25px;
  }

  &__top-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
    padding-bottom: 72px;
  }

  &__faq-wrapper {
    max-width: 726px;
    margin-right: auto;
    margin-left: auto;
  }

  &__faq-item {
    border: 1px solid rgba(#fff, 0.1);
    border-radius: 12px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__faq-item-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 16px;
    color: var(--color-white);
  }

  &__faq-item-details {
    & > .settings__faq-item-summary:after {
      transform: rotate(180deg);
      transform: rotate(180deg);
    }

    &[open] {
      & > .settings__faq-item-summary:after {
        transform: unset;
      }
    }
  }

  &__faq-item-summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
    cursor: pointer;
    user-select: none;
    padding: 16px;

    &:after {
      content: '';
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-left: 15px;
      width: 13px;
      height: 10px;
      background-image: url('~@/static/settings/arrow-faq.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
</style>

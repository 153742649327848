import axios from 'axios'
import { getWebpHead } from '../utils/helpers'

const prefix = '/cbt'

export const getAllCbt = async (params) => {
  return await axios.get(`${prefix}`, { params, headers: getWebpHead() })
}

export const getCbt = async (id) => {
  return await axios.get(`${prefix}/${id}`, { headers: getWebpHead() })
}

export const getCbtCourse = async (id) => {
  return await axios.get(`${prefix}-course/${id}`, { headers: getWebpHead() })
}

export const getAllCbtCourse = async (params) => {
  return await axios.get(`${prefix}-course`, { params, headers: getWebpHead() })
}

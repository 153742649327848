import { getCbt, getCbtCourse } from '@/services/cbt.service.js'
import {
  getMusicSoundscape,
  getMusicSoundscapePlaylist,
} from '@/services/music-soundscape.service.js'
import {
  getMeditationSleep,
  getMeditationSleepCourse,
} from '@/services/meditation-sleep.service'

const state = () => ({
  data: [],
  single: {},
})

const actions = {
  fetchCbt({ commit }, id) {
    return getCbt(id).then((res) => {
      commit('SET_SINGLE', res)
      return res
    })
  },
  fetchCbtCourse({ commit }, id) {
    return getCbtCourse(id).then((res) => {
      commit('SET_SINGLE', res)
      return res
    })
  },
  fetchMusicSoundscapePlaylist({ commit }, id) {
    return getMusicSoundscapePlaylist(id).then((res) => {
      commit('SET_SINGLE', res)
      return res
    })
  },
  fetchMusicSoundscape({ commit }, id) {
    return getMusicSoundscape(id).then((res) => {
      commit('SET_SINGLE', res)
      return res
    })
  },
  fetchMeditationSleep({ commit }, id) {
    return getMeditationSleep(id).then((res) => {
      commit('SET_SINGLE', res)
      return res
    })
  },
  fetchMeditationSleepCourse({ commit }, id) {
    return getMeditationSleepCourse(id).then((res) => {
      commit('SET_SINGLE', res)
      return res
    })
  },
}

const mutations = {
  SET_DATA(state, data) {
    state.data = data
  },
  SET_SINGLE(state, data) {
    state.single = data
  },
}

const getters = {
  data: ({ data }) => data,
  single: ({ single }) => single,
}

export const player = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

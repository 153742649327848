<template>
  <label class="package__card">
    <div class="package__popular__head" v-if="popular">
      <p class="package__popular__title">MOST POPULAR</p>
    </div>
    <div class="package__item" :class="{ popular: popular }">
      <a-radio
        name="package"
        :value="purchaseTypes.LIFETIME"
        class="package__item__checkbox"
      ></a-radio>
      <div class="package__item__detail">
        <h4 class="package__item__title">Lifetime Plan</h4>
        <p class="package__item__price">
          <span class="strikethrough">{{ currency }}{{ getRegularPrice }}</span>
          {{ currency }}{{ data.amount }}
        </p>
      </div>
      <div class="package__item__right overlay">
        <p class="package__item__price-p">Billed once</p>
      </div>
    </div>
  </label>
</template>

<script>
import { purchaseTypes } from '@/data/constants'
import { getCurrency } from '@/utils/helpers'
export default {
  name: 'PaymentYearlyCard',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    popular: Boolean,
  },
  data() {
    return {
      purchaseTypes,
    }
  },
  computed: {
    currency() {
      return this.getCurrency(this.data.currency)
    },
    getRegularPrice() {
      return 1499.99
    },
  },
  methods: {
    getCurrency,
  },
}
</script>

<style></style>

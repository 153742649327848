import axios from 'axios'
import { currency as c } from '@/data/constants'

const prefix = '/package'

export const getPackage = async ({ place, currency = c.USD }) => {
  return await axios.get(`${prefix}/web?place=${place}&currency=${currency}`)
}

export const getPackageByID = async (id) => {
  return await axios.get(`${prefix}/${id}`)
}

<template>
  <div class="wrapper-section" v-if="tag === 'All'">
    <template v-for="(section, index) in data" :key="index">
      <personal-plan
        v-if="section.section_type === 'Personal Plan'"
        :data="section.content"
        :dayNumber="section.day"
      />
      <template v-else>
        <section
          class="section"
          :class="[
            {
              daily: section.name == 'Daily Meditation',
            },
            { workbook: section.name == 'Workbooks' },
          ]"
          v-if="section.content && section.content.length"
        >
          <h3 class="section__name">{{ section.name }}</h3>
          <ul class="section__items">
            <div class="slider-mask" v-if="section.name == 'Daily Meditation'">
              <swiper
                :spaceBetween="24"
                :slidesPerView="'auto'"
                :slidesPerGroup="slidesPerGroup"
                :loop="false"
                :lazy="true"
                :simulateTouch="false"
                :navigation="{
                  nextEl: `.swiper-button-next-${section.id}`,
                  prevEl: `.swiper-button-prev-${section.id}`,
                }"
                @swiper="onSwiper"
                class="mySwiper collection__list"
              >
                <template
                  v-for="(content, index) in section.content"
                  :key="index"
                >
                  <swiper-slide>
                    <li class="section__item">
                      <router-link
                        v-if="content.free"
                        :to="`/${content.model}/${content.id}`"
                      >
                        <div class="section__item-wrapper">
                          <div
                            class="section__item-image-wrapper"
                            :class="content.model"
                          >
                            <img
                              :src="`${
                                section.content && section.content.length === 1
                                  ? content.daily_featured_cover
                                  : content.cover
                              }`"
                              alt="image"
                              loading="lazy"
                              class="section__item-image"
                            />
                          </div>
                          <div class="section__item-title">
                            {{ content.name }}
                          </div>
                          <div class="section__item-type-wrapper">
                            <span class="section__item-type">
                              {{ content.type }}
                            </span>
                            <span
                              class="section__item-duration"
                              v-if="content.duration"
                            >
                              {{ content.duration }}
                            </span>
                          </div>
                        </div>
                      </router-link>
                      <div
                        v-else
                        class="section__item-wrapper"
                        @click="onUnlock"
                      >
                        <div
                          class="section__item-image-wrapper"
                          :class="['locked', content.model]"
                        >
                          <img
                            :src="`${
                              section.content && section.content.length === 1
                                ? content.daily_featured_cover
                                : content.cover
                            }`"
                            alt="image"
                            loading="lazy"
                            class="section__item-image"
                          />
                        </div>
                        <div class="section__item-title">
                          {{ content.name }}
                        </div>
                        <div class="section__item-type-wrapper">
                          <span class="section__item-type">
                            {{ content.type }}
                          </span>
                          <span
                            class="section__item-duration"
                            v-if="content.duration"
                          >
                            {{ content.duration }}
                          </span>
                        </div>
                      </div>
                    </li>
                  </swiper-slide>
                </template>
              </swiper>
              <div
                :class="`swiper-button-prev-${section.id} swiper-button-prev`"
              ></div>
              <div
                :class="`swiper-button-next-${section.id} swiper-button-next`"
              ></div>
            </div>
            <div class="slider-mask" v-else>
              <swiper
                :spaceBetween="24"
                :slidesPerView="'auto'"
                :slidesPerGroup="slidesPerGroup"
                :loop="false"
                :lazy="true"
                :simulateTouch="false"
                :navigation="{
                  nextEl: `.swiper-button-next-${section.id}`,
                  prevEl: `.swiper-button-prev-${section.id}`,
                }"
                class="mySwiper collection__list"
              >
                <template v-for="(content, index) in section.content">
                  <swiper-slide v-if="isWorkbook(content.model)" :key="index">
                    <li class="section__item">
                      <template v-if="!content.free">
                        <div class="section__item-wrapper" @click="onUnlock">
                          <div
                            class="section__item-image-wrapper locked"
                            :class="[
                              { complete: content.complete },
                              content.model,
                            ]"
                          >
                            <img
                              :src="`${content.cover}`"
                              alt="image"
                              loading="lazy"
                              class="section__item-image"
                            />
                          </div>
                          <div class="section__item-title">
                            {{ content.name }}
                          </div>
                          <div class="section__item-type-wrapper">
                            <span class="section__item-type">
                              {{ content.type }}
                            </span>
                            <span
                              class="section__item-duration"
                              v-if="content.duration"
                            >
                              {{ content.duration }}
                            </span>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <template
                          v-if="
                            (isThoughtRecordReview(content) &&
                              !isThoughtRecordComplete(section.content)) ||
                            (isThoughtChainEffect(content) &&
                              !isThoughtRecordComplete(section.content))
                          "
                        >
                          <a-tooltip placement="top">
                            <template #title v-if="content.free">
                              <span
                                >Workbook "Thought Record" not completed
                                yet</span
                              >
                            </template>
                            <collection-workbook-card
                              :content="content"
                              :link="workbookLink(content, section.content)"
                            />
                          </a-tooltip>
                        </template>
                        <template
                          v-else-if="
                            isBadThoughtsDestroyer(content) &&
                            !hasThoughtChainEffectCompleted(section.content)
                          "
                        >
                          <a-tooltip placement="top">
                            <template #title v-if="content.free">
                              <span
                                >Workbook "Thought Chain Effect" not completed
                                yet</span
                              >
                            </template>
                            <collection-workbook-card
                              :content="content"
                              :link="workbookLink(content, section.content)"
                            />
                          </a-tooltip>
                        </template>
                        <collection-workbook-card
                          v-else
                          :content="content"
                          :link="workbookLink(content, section.content)"
                        />
                      </template>
                    </li>
                  </swiper-slide>
                  <swiper-slide v-else :key="index + 'else'">
                    <li class="section__item">
                      <router-link
                        v-if="content.free"
                        :to="`/${content.model}/${content.id}`"
                      >
                        <div class="section__item-wrapper">
                          <div
                            class="section__item-image-wrapper"
                            :class="content.model"
                          >
                            <img
                              :src="`${content.cover}`"
                              alt="image"
                              loading="lazy"
                              class="section__item-image"
                            />
                          </div>
                          <div class="section__item-title">
                            {{ content.name }}
                          </div>
                          <div class="section__item-type-wrapper">
                            <span class="section__item-type">
                              {{ content.type }}
                            </span>
                            <span
                              class="section__item-duration"
                              v-if="content.duration"
                            >
                              {{ content.duration }}
                            </span>
                          </div>
                        </div>
                      </router-link>
                      <div
                        @click="onUnlock"
                        v-else
                        class="section__item-wrapper"
                      >
                        <div
                          class="section__item-image-wrapper"
                          :class="[content.model, 'locked']"
                        >
                          <img
                            :src="`${content.cover}`"
                            alt="image"
                            loading="lazy"
                            class="section__item-image"
                          />
                        </div>
                        <div class="section__item-title">
                          {{ content.name }}
                        </div>
                        <div class="section__item-type-wrapper">
                          <span class="section__item-type">
                            {{ content.type }}
                          </span>
                          <span
                            class="section__item-duration"
                            v-if="content.duration"
                          >
                            {{ content.duration }}
                          </span>
                        </div>
                      </div>
                    </li>
                  </swiper-slide>
                </template>
              </swiper>
              <div
                :class="`swiper-button-prev-${section.id} swiper-button-prev`"
              ></div>
              <div
                :class="`swiper-button-next-${section.id} swiper-button-next`"
              ></div>
            </div>
          </ul>
        </section>
      </template>
    </template>
  </div>
  <div class="wrapper-section no-data-found" v-else-if="isLoading">
    <a-spin />
  </div>
  <div class="wrapper-section" v-else-if="data.length">
    <ul class="section__items section__items--wrap">
      <SectionCardSmall v-for="item in data" :key="item.id" :content="item" />
    </ul>
  </div>
  <div class="wrapper-section grid" v-else>
    <span class="no-data-found">No data found</span>
  </div>
</template>

<script>
import SectionCardSmall from '../cards/SectionCardSmall.vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper'
import PersonalPlan from '../views/home/PersonalPlan.vue'
import { mapMutations } from 'vuex'
import { workbookType } from '@/components/workbook/workbook-data'
import CollectionWorkbookCard from './CollectionWorkbookCard.vue'
import { metaEventCustomActions } from '@/data/constants'

SwiperCore.use([Autoplay, Pagination, Navigation])

export default {
  name: 'CollectionSection',
  components: {
    SectionCardSmall,
    Swiper,
    SwiperSlide,
    PersonalPlan,
    CollectionWorkbookCard,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    tag: {
      type: String,
      default: 'All',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    slidesPerGroup() {
      return window.innerWidth <= 991 ? 1 : 2
    },
  },
  methods: {
    ...mapMutations('workbook', ['RESET_WORKBOOK']),
    ...mapMutations('subscription', ['SET_OPEN']),
    onUnlock() {
      this.SET_OPEN({
        isOpen: true,
        action: metaEventCustomActions.ON_LOCKED_CLICKED,
      })
    },
    onDone() {
      window.location.reload()
    },
    onSwiper(swiper) {
      console.log(swiper)
    },
    hasFreeContent(data) {
      return (
        data.some((i) => i.free) || data.some((i) => i.model === 'workbook')
      )
    },
    isWorkbook(name) {
      return name === 'workbook'
    },
    isThoughtRecordComplete(workbooks) {
      const thoughtRecord = workbooks.find(
        (i) => i.name === workbookType.THOUGHT_RECORD
      )
      return thoughtRecord && thoughtRecord.complete
    },
    hasThoughtChainEffectCompleted(workbooks) {
      const w = workbooks.find(
        (i) => i.name === workbookType.THOUGHT_CHAIN_EFFECT
      )
      return w && w.complete
    },
    isThoughtRecordReview(item) {
      return workbookType.THOUGHT_RECORD_REVIEW === item.name
    },
    isThoughtChainEffect(item) {
      return workbookType.THOUGHT_CHAIN_EFFECT === item.name
    },
    isBadThoughtsDestroyer(item) {
      return workbookType.BAD_THOUGHT_DESTROYER === item.name
    },
    workbookLink(item, workbooks) {
      if (
        (item.name === workbookType.THOUGHT_RECORD_REVIEW &&
          !this.isThoughtRecordComplete(workbooks)) ||
        (item.name === workbookType.THOUGHT_CHAIN_EFFECT &&
          !this.isThoughtRecordComplete(workbooks)) ||
        (item.name === workbookType.BAD_THOUGHT_DESTROYER &&
          !this.hasThoughtChainEffectCompleted(workbooks))
      )
        return ''
      if (item.complete) {
        return { name: 'ProfileWorkbook', params: { id: item.id } }
      } else {
        if (!item.free) {
          return ''
        } else {
          this.RESET_WORKBOOK()
          return { name: 'Workbook', params: { id: item.id } }
        }
      }
    },
  },
}
</script>

<style lang="scss">
.no-data-found {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: rgba(#fff, 0.5);
}

.grid {
  display: grid;
}
</style>

import axios from 'axios'
import { getWebpHead } from '../utils/helpers'

const prefix = '/meditation-sleep'

export const getAllMeditationSleep = async (params) => {
  return await axios.get(`${prefix}`, { params, headers: getWebpHead() })
}

export const getMeditationSleep = async (id) => {
  return await axios.get(`${prefix}/${id}`, { headers: getWebpHead() })
}

export const getAllMeditationSleepCourse = async (params) => {
  return await axios.get(`${prefix}-course`, { params, headers: getWebpHead() })
}

export const getMeditationSleepCourse = async (id) => {
  return await axios.get(`${prefix}-course/${id}`, { headers: getWebpHead() })
}

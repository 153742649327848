import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import DataRemoval from '../views/DataRemoval.vue'
import ConfirmRemove from '../views/ConfirmRemove.vue'
import Registration from '../views/Registration.vue'
import Welcome from '../views/Welcome.vue'
import ComingSoon from '../views/ComingSoon.vue'
import VideoPlayer from '../layouts/VideoPlayer.vue'
import Settings from '../pages/Settings.vue'
import Subscription from '../components/Settings/Subscription.vue'
import ChangePassword from '../components/Settings/ChangePassword.vue'
import Account from '../components/Settings/Account.vue'
import HelpAndSupport from '../components/Settings/HelpAndSupport.vue'
import PrivacyAndPolicy from '../components/Settings/PrivacyAndPolicy.vue'
import TermsAndConditions from '../components/Settings/TermsAndConditions.vue'
import Collection from '../layouts/Collection.vue'
import Meditation from '../views/Meditation.vue'
import Sleep from '../views/Sleep.vue'
import Therapy from '../views/Therapy.vue'
import Sounds from '../views/Sounds.vue'
import Achievements from '../components/Profile/Achievements.vue'
import Notes from '../components/Profile/Notes.vue'
import Note from '../components/Profile/Note.vue'
import Library from '../components/Profile/Library.vue'
import Dashboard from '../components/Profile/Dashboard.vue'
import Checkout from '../components/checkout/Checkout.vue'
import CheckoutWhite from '../components/checkout-white/CheckoutWhite.vue'
import PrivacyAndPolicyGlobal from '../components/PrivacyAndPolicyGlobal.vue'
import TermsAndConditionsGLobal from '../components/TermsAndConditionsGlobal.vue'
import { loadFacebookPixel, initFacebook } from '@/utils/facebookConfig'

import { userAuthByTmpToken } from '../services/user.service'
import store from '../store'
import { notification } from 'ant-design-vue'

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
    meta: {
      changeMainPage: true,
    },
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/personal-plan',
    name: 'PersonalPlan',
    component: () => import('@/views/PersonalPlanAll.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration,
  },
  {
    path: '/data-removal',
    name: 'DataRemoval',
    component: DataRemoval,
  },
  {
    path: '/confirm-remove/:code',
    name: 'ConfirmRemove',
    component: ConfirmRemove,
  },
  {
    path: '/coming-soon/:key?',
    name: 'ComingSoonPage',
    component: ComingSoon,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/settings/subscription',
    name: 'Subscription',
    component: Subscription,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/authorization',
    name: 'Authorization',
    beforeEnter: async (to, from, next) => {
      if (to.query.email) {
        const data = await userAuthByTmpToken({
          email: to.query.email,
          token: to.query.token,
        })
        store.commit('SET_TOKEN', data.token)
        next('/quiz-results')
      } else {
        notification.error({
          message: 'Something went wrong!',
        })
        next('/registration')
      }
    },
  },
  {
    path: '/subscription/:checkoutId?',
    name: 'SubscriptionPage',
    component: () => import('../views/Subscription.vue'),
    beforeEnter: (to, from, next) => {
      if (
        Object.keys(store.state.quiz.quizData).length === 0 &&
        to.query.dynamic
      ) {
        next('/')
      } else {
        next()
      }
    },
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/settings/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/settings/account',
    name: 'Account',
    component: Account,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/settings/help-support',
    name: 'HelpAndSupport',
    component: HelpAndSupport,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyAndPolicy',
    component: PrivacyAndPolicy,
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import('@/views/Faq.vue'),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/terms-of-service',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/collection/:id',
    name: 'Collection',
    component: Collection,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/meditation',
    name: 'Meditation',
    component: Meditation,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/sleep',
    name: 'Sleep',
    component: Sleep,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/therapy',
    name: 'Therapy',
    component: Therapy,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/sounds',
    name: 'Sounds',
    component: Sounds,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/quiz',
    name: 'Quiz',
    component: () => import('../views/Quiz.vue'),
  },
  {
    path: '/vsl/:slug',
    name: 'Vsl',
    component: () => import('../views/Vsl.vue'),
  },
  {
    path: '/lead-quiz/:slug',
    name: 'LeadsQuiz',
    component: () => import('../views/Quiz.vue'),
  },
  {
    path: '/single-quiz/:slug',
    name: 'SingleQuiz',
    component: () => import('../views/Quiz.vue'),
  },
  {
    path: '/user-quiz/:slug',
    name: 'UserQuiz',
    component: () => import('../views/Quiz.vue'),
  },
  {
    path: '/thank-you',
    name: 'ThankYou',
    component: () => import('../views/ThankYou.vue'),
  },
  {
    path: '/lead-quiz-result',
    name: 'LeadQuizResult',
    component: () => import('../views/QuizResults.vue'),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/lead-dynamic-result/:slug',
    name: 'LeadDynamicResult',
    component: () => import('../views/QuizDynamicResults.vue'),
    meta: {
      authRequired: false,
    },
  },

  {
    path: '/quiz-results',
    name: 'QuizResults',
    component: () => import('../views/QuizResults.vue'),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/quiz-dynamic-results/:slug',
    name: 'QuizDynamicResults',
    component: () => import('../views/QuizDynamicResults.vue'),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/search-result',
    name: 'SearchResult',
    component: () => import('../views/SearchResults.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/profile',
    component: () => import('@/layouts/MainLayout.vue'),
    name: 'Profile',
    children: [
      {
        path: 'notes/create',
        name: 'NoteCreate',
        component: () => import('@/components/Profile/NoteCreate.vue'),
      },
      {
        path: 'notes/:id',
        name: 'Note',
        component: Note,
      },
    ],
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/layouts/ProfileLayout.vue'),
    redirect: '/profile/dashboard',
    meta: {
      authRequired: true,
    },
    children: [
      {
        name: 'Dashboard',
        path: 'dashboard',
        component: Dashboard,
      },
      {
        name: 'Library',
        path: 'library',
        component: Library,
      },
      {
        path: 'achievements',
        name: 'Achievements',
        component: Achievements,
      },
      {
        path: 'notes',
        name: 'Notes',
        component: Notes,
      },
      {
        path: 'worksheets',
        name: 'Worksheets',
        component: () => import('@/components/Profile/Worksheets.vue'),
      },
    ],
  },
  {
    path: '/workbooks',
    name: 'Workbooks',
    component: () => import('@/layouts/MainLayout.vue'),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: 'workbook/:id',
        name: 'Workbook',
        component: () => import('@/views/Workbook.vue'),
        meta: {
          authRequired: true,
        },
      },
      {
        path: 'workbook/:id/result',
        name: 'WorkbookResult',
        component: () => import('@/views/WorkbookResults.vue'),
        meta: {
          authRequired: true,
        },
      },
      {
        path: '/profile/workbook/:id',
        name: 'ProfileWorkbook',
        component: () =>
          import('@/components/Profile/ProfileWorkbookResult.vue'),
        meta: {
          authRequired: true,
        },
      },
    ],
  },
  {
    path: '/workbook/:id',
    name: 'WorkbookSingle',
    redirect: (to) => {
      return { path: `/workbooks/workbook/${to.params.id}` }
    },
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/create-account',
    name: 'CreateAccount',
    component: () => import('@/views/CreateAccount.vue'),
  },
  {
    path: '/create-email-account',
    name: 'CreateEmailAccount',
    component: () => import('@/views/CreateEmailAccount.vue'),
  },
  {
    path: '/success-transaction',
    name: 'SuccessTransaction',
    component: () => import('@/views/SuccessTransaction.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/ForgotPassword.vue'),
  },
  {
    path: '/password/reset',
    name: 'ResetPassword',
    component: () => import('@/views/ResetPassword.vue'),
  },
  {
    path: '/cancel-transaction',
    name: 'CancelTransaction',
    component: () => import('@/views/CancelTransaction.vue'),
  },
  {
    path: '/lp/:lp',
    name: 'CheckoutLP',
    component: () => import('@/views/CheckoutLP.vue'),
  },
  {
    path: '/checkout/:checkoutId',
    name: 'CheckoutUser',
    component: () => import('@/views/CheckoutUser.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/lead-checkout/:checkoutId',
    name: 'LeadCheckout',
    component: () => import('@/views/LeadCheckout.vue'),
  },
  {
    path: '/promo/:checkoutId',
    name: 'PromoCheckout',
    component: () => import('@/views/CheckoutPromo.vue'),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/:section/:id',
    name: 'VideoPlayer',
    component: VideoPlayer,
    meta: {
      authRequired: true,
    },
  },
  // {
  //   path: '/checkout',
  //   name: 'Checkout',
  //   component: Checkout,
  // },
  // {
  //   path: '/checkout-white',
  //   name: 'CheckoutWhite',
  //   component: CheckoutWhite,
  // },
  {
    path: '/privacy-policy',
    name: 'PrivacyAndPolicyGlobal',
    component: PrivacyAndPolicyGlobal,
  },
  {
    path: '/terms-conditions',
    name: 'TermsAndConditionsGLobal',
    component: TermsAndConditionsGLobal,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const loggedIn = localStorage.getItem('t')

  if (to.meta.authRequired && !loggedIn) {
    return next('/login')
  }

  const pages = [
    'VideoPlayer',
    'ProfileWorkbook',
    'WorkbookResult',
    'Workbook',
    'Workbooks',
    'Dashboard',
    'Worksheets',
    'Notes',
    'Achievements',
    'Library',
    'Note',
    'NoteCreate',
    'Profile',
    'SearchResult',
    'Sounds',
    'Therapy',
    'Sleep',
    'Meditation',
    'Collection',
    'HelpAndSupport',
    'Account',
    'ChangePassword',
    'Subscription',
    'Settings',
    'ComingSoonPage',
    'PersonalPlan',
    'Home',
  ]

  // if (pages.includes(to.name)) {
  //   await store
  //     .dispatch('fetchUser')
  //     .then((res) => {
  //       if (!res.gender && !res.goal) {
  //         return next('/quiz')
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //     })
  // }

  // redirect to home page if logged in and trying to join to landing page
  if (to.meta.changeMainPage && loggedIn) {
    let q = '?'
    Object.entries(to.query).forEach(([key, v]) => {
      q += key + '=' + v + '&'
    })
    return next('/home' + q.slice(0, -1))
  }

  next()
})

router.afterEach((to, from) => {
  loadFacebookPixel()
  fbq('track', 'PageView')
})

export default router
